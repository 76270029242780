import { Component } from '@angular/core';

@Component({
  selector: 'app-visualizar-solicitacoes',
  templateUrl: './visualizar-solicitacoes.component.html',
  styleUrls: ['./visualizar-solicitacoes.component.css']
})
export class VisualizarSolicitacoesComponent {

}
