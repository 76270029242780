interface inputsDocuments {
    produto_documentos: string;
  }

  class inputsDocuments {
    constructor() {
        this.produto_documentos;
    }
  }

  export {
    inputsDocuments
  };