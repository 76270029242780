import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticatorService } from 'src/app/services/authenticator.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class EditInvoicePackingService {

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) {}

   apiUrl: any;

   moveRequest(documentId:number,aprovOrReprov:string,justificativa?:any) {
    this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

    const requestData = {
      url: this.apiUrl,
      method: 'POST'
    };

    
    const body = {
      name: "DTS_SAVE_DATA_IN_THE_FIELD",
      constraints: [ {
        _field: 'documentId', 
        _initialValue: documentId, 
        _finalValue: documentId, 
        _type: 1
      },{
        _field: 'decisao_portal', 
        _initialValue: aprovOrReprov, 
        _finalValue: aprovOrReprov, 
        _type: 1
      }
    ]
    };


    if(justificativa != ''){
      body.constraints.push({
        _field: "observacao_portal", 
        _initialValue: justificativa, 
        _finalValue: justificativa, 
        _type: 1
      })
    }

    const httpOptions = this.authenticator.connect(requestData)
    
    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: response.content.values , 
            totalHits: response.content.values.length 
          });
        }
      );
    });
  }
}
