<div [ngClass]="{'po-wrapper': authService.isLoggedSecond()}">
    <app-header *ngIf="authService.isLoggedSecond()"></app-header> 
      
    <po-menu 
        [p-collapsed]="true" 
        p-short-logo="./favicon.ico" 
        [p-menus]="menus" 
        *ngIf="authService.isLoggedSecond()" 
        [p-filter]="false"
        [p-automatic-toggle]="true"
        >
        <div 
            *p-menu-header-template 
            style="display: flex;align-items: center;justify-content: center;margin-top: 15px;margin-bottom: 15px;"
        >
            <img 
                src="https://hi-mix.com.br/wp-content/uploads/2021/03/Logo-Blue.svg" 
                alt="logo Hi-Mix"  
                class="logo"
                style="width: 150px;height: auto;"
            >
        </div>
    </po-menu>
      
    <po-page-default>
        <div style="margin-bottom: 85px;">
            <router-outlet></router-outlet>
        </div>
    </po-page-default>
</div>

