import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticatorService } from 'src/app/services/authenticator.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) { }

  apiUrl: any;
  async getDatas(documentId:number) {
    debugger
  
      this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
    
  
      const requestData = {
        url: this.apiUrl,
        method: 'POST'
      };
  
      const body = {
        name: "DTS_PAIXFILHO_ITENS_ORDEM_VENDA",
        constraints:  [{
          _field: 'documentId', 
          _initialValue: documentId, 
          _finalValue: documentId, 
          _type: 1
        }]
      };
     
      const httpOptions = this.authenticator.connect(requestData)
      
      return new Promise((resolvePromise) => {
        this.http.post(requestData.url, body, httpOptions).subscribe(
          (response: any) => {
            resolvePromise({
              response: this.addColumnStatus(response.content.values),
              totalHits: response.content.values.length
            });
          }
        );
      });
    }

    
    private addColumnStatus(valores:[]){
      valores.map((item:any) => {
        item['status'] = 'onApproval'
      })
      return  valores
    }

  moveRequest(documentId:number,aprovOrReprov:string,oReproved?:any,justificativa?:any) {
    this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

    const requestData = {
      url: this.apiUrl,
      method: 'POST'
    };

    
    const body = {
      name: "DTS_SAVE_DATA_IN_THE_FIELD",
      constraints: [ {
        _field: 'documentId', 
        _initialValue: documentId, 
        _finalValue: documentId, 
        _type: 1
      },{
        _field: 'move_valida_draft', 
        _initialValue: aprovOrReprov, 
        _finalValue: aprovOrReprov, 
        _type: 1
      },{
        _field: 'move_declara_prod', 
        _initialValue: ' ', 
        _finalValue: ' ', 
        _type: 1
      }
    ]
    };

    if(oReproved.length > 0){
      body.constraints.push({
        _field: "reprov_draft", 
        _initialValue: JSON.stringify(oReproved), 
        _finalValue: JSON.stringify(oReproved), 
        _type: 1
      })
    }

    if(justificativa != ''){
      body.constraints.push({
        _field: "justificativa_draft", 
        _initialValue: justificativa, 
        _finalValue: justificativa, 
        _type: 1
      })
    }

    const httpOptions = this.authenticator.connect(requestData)
    
    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: response.content.values , 
            totalHits: response.content.values.length 
          });
        }
      );
    });
  }
}
