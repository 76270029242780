<po-widget>
    
    <app-loading [showLoading]="isLoading"></app-loading>

    <div class="po-row">
        <div class="po-col-12 po-md-6">
          <span class="po-icon po-icon-arrow-left blue cursor-pointer " routerLink="/consultar">
            {{voltar}}
            
          </span>
        </div>
      </div>

    <po-stepper #stepper >
      <po-step p-label="{{textPropostaComercial}}" >
        <po-widget
            [p-primary-label]="this.gridItemsListAlternativa.lenght > 0 ? nextLabelWidget : saveLabelWidget"
            (p-primary-action)="this.gridItemsListAlternativa.lenght > 0 ? stepper.next() : save()"
        >
            <div  class="po-row">
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-6">
                  <po-input
                    p-label="{{clienteText}}"
                    p-disabled="true"
                    [(ngModel)]="cliente"
                    
                  >
                  
                  </po-input>
                </div>
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-6">
                  <po-input
                    p-label="{{contatoText}}"
                    p-disabled="true"
                    [(ngModel)]="contato"
                  >
                  </po-input>
                </div>
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-6" >
                  <po-input
                    p-label="RFQ"
                    p-disabled="true"
                    [(ngModel)]="proposta"
                  >
                  </po-input>
                </div>
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-6" >
                  <po-input
                    p-label="{{revisaoText}}"
                    p-disabled="true"
                    [(ngModel)]="revisao"
                  >
                  </po-input>
                </div>
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-6" >
                  <po-input
                    p-label="{{dataText}}"
                    p-disabled="true"
                    [(ngModel)]="data"
                    >
                </po-input>
            </div>
            <div class="po-xl-4 po-lg-4 po-md-4 po-sm-6" >
                <po-input
                    p-label="{{nomeProjetoText}}"
                    p-disabled="true"
                    [(ngModel)]="nomeProjeto"
                  >
                  </po-input>
                </div>
              </div>

            <po-divider></po-divider>
            
            <div #top class="po-xl-12 po-lg-12 po-md-12 po-sm-12" style="margin-bottom: 15px;">
                <p>{{textPrezados}}</p>
                <p>
                    {{textProposta}}
                </p>
            </div>
            <po-widget
                p-title="1.0 {{textObjetoProposta}}"
                
            >
                <p>1.1 - {{textCotacao}} {{tipo_negocio}}</p>
            </po-widget>
            <po-widget p-title="1.1 {{textPreco}}" >
                <po-table style="width: 100%;" 
                    [p-hide-detail]="false"
                    [p-selectable]="true"
                    [p-single-select]="false" 
                    [p-auto-collapse]="true"
                    [p-hide-text-overflow]="true"
                    [p-striped]="true"
                    [p-hide-columns-manager]="true"
                    [p-columns]="gridColumnsPrecoVenda" 
                    [p-items]="gridValuesPrecoVenda" 
                    [p-sort]="true"
                    (p-selected)="selectLine($event)"
                    (p-unselected)="decreaseLine($event)"
                    (p-all-unselected)="decreaseLineAll($event)"
                    (p-all-selected)="selectLineAll($event)"
                >
                </po-table>
                

                <div style="margin-top: 20px;margin-bottom: 35px;">
                    <po-info
                        class="po-md-4 po-mb-sm-2 po-mb-md-2 po-lb-lg-2"
                        p-label="{{textQuantidadeTotal}}"
                        [p-value]="prcVendaQtdTotal"
                        [p-orientation]="orientation"
                    >
                    </po-info>


                    <po-info
                        class="po-md-4 po-mb-sm-2 po-mb-md-2 po-lb-lg-2"
                        p-label="{{textTotalSelecionado}}"
                        [p-orientation]="orientation"
                        p-value="{{ total | currency: this.idioma_proposta == 'pt' ? 'BRL' : 'USD'  }}"
                    >
                    </po-info>
                </div>

                <div class="po-row">
                    <po-button 
                        class="po-md-4" 
                        p-icon="po-icon po-icon-ok" 
                        (p-click)="approveItems('toApprove')" 
                        p-label="{{textBtnApprove}}"> 
                    </po-button>
                    <po-button 
                        class="po-md-4" 
                        p-icon="po-icon po-icon-warning" 
                        (p-click)="approveItems('review')" 
                        p-label="{{textBtnRevisar}}"> 
                    </po-button>
                    <po-button 
                        class="po-md-4" 
                        p-icon="po-icon po-icon-close" 
                        (p-click)="approveItems('disapproved')" 
                        [p-danger]="true" 
                        p-label="{{textBtnDisapprove}}"> 
                    </po-button>
                  </div>
                

                
                <po-divider></po-divider>
                
                <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                    <ul>
                        <li>
                            <p>{{textPrecosIndicados}}</p>
                        </li>
                        <li>
                            <p>{{textPrecoComposicao}}</p>
                        </li>
                    </ul>
                </div> 
            </po-widget>
            <ng-container *ngFor="let item of this.itens; let i = index">
                <po-widget
                    p-title="1.{{i + 2}} {{textInvestimentoInicial}} - {{item[1]}} - {{item[2]}} - {{item[5]}}"
                    *ngIf="item[3]"
                    >
                    <po-table
                        [p-hide-columns-manager]="true"
                        [p-columns]="gridColumnsInvestimento" 
                        [p-items]="item[0]" 
                        [p-sort]="true"
                    >
                    </po-table>

                    <div style="margin-top: 20px;margin-bottom: 35px;">
                      
                        <po-info
                            class="po-md-4 po-mb-sm-2 po-mb-md-2 po-lb-lg-2"
                            p-label="{{textPrecoTotal}}"
                            [p-orientation]="orientation"
                            p-value="{{ item[4] | currency: this.idioma_proposta == 'pt' ? 'BRL' : 'USD' }}"
                        >
                        </po-info>
                    </div>

                </po-widget>
            </ng-container >
            <po-widget
                p-title="2.0 {{textCenarioProposta}}"    
            >
                <po-table 
                    style="width: 100%;" 
                    [p-hide-columns-manager]="true"
                    [p-columns]="gridColumnsApontamento" 
                    [p-items]="gridValuesCondComercial" 
                    [p-sort]="true"
                >
                </po-table>

            </po-widget>

            <po-widget
                p-title="2.2 {{textCondicoesTecnica}}"    
            >
                <po-table style="width: 100%;" 
                    [p-hide-columns-manager]="true"
                    [p-columns]="gridColumnsApontamento" 
                    [p-items]="gridValuesCondTecninca" 
                    [p-sort]="true"
                >
                </po-table>
            </po-widget>

            <po-widget
                p-title="2.3 {{textTestesAplicados}}"
            >
                <po-table 
                    style="width: 100%;" 
                    [p-hide-columns-manager]="true"
                    [p-columns]="gridColumnsApontamento" 
                    [p-items]="gridValuesCondAplicados" 
                    [p-sort]="true"
                >
                </po-table>
            </po-widget>

            <po-widget
                p-title="2.4 {{textEmbalagem}}"
            >
                <po-table 
                    style="width: 100%;" 
                    [p-hide-columns-manager]="true"
                    [p-columns]="gridColumnsApontamento" 
                    [p-items]="gridValuesCondEmbalagem" 
                    [p-sort]="true"
                >
                </po-table>
            </po-widget>
            
           <po-widget
            p-title="2.5 {{textInfosComplementares}}"
           >
            <po-table style="width: 100%;" 
                [p-hide-columns-manager]="true"
                [p-columns]="gridColumnsApontamento" 
                [p-items]="gridValuesCondComplementares" 
                [p-sort]="true"
            >
            </po-table>
           </po-widget>

           <po-widget
            p-title="3.0 {{textInfosAdicionais}}"
           >
            <po-table style="width: 100%;" 
                [p-hide-columns-manager]="true"
                [p-columns]="gridColumnsInfoAdicionais" 
                [p-items]="gridValuesInfoAdicionais" 
                [p-sort]="true"
            >
            </po-table>
           </po-widget>
           
            <po-divider></po-divider>

            
            <div class="po-col po-text-center">
                <p>
                    <b>
                        {{textAdvice}}
                    </b>
                </p>
            </div>
            

            <po-divider></po-divider>


            
            <div class="po-col po-text-center" style="margin-bottom: 15px;">
                <p>{{textSubtitle}}</p>
            </div>              
            <div class="po-row">
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>BOM - bill of material</p>
                </div>
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>MOQ - minimum order quantity</p>
                </div>
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>PPB - processo produtivo básico</p>
                </div>
            </div>
            <div class="po-row">
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>FCT - functional test</p>
                </div>
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>NCM - nomenclatura comum do mercosul</p>
                </div>
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>RMA - return material authorization</p>
                </div>
            </div>
            <div class="po-row">
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>ICT - in-circuit test</p>
                </div>
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>NRE - non-recurring engineering</p>
                </div>
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>NA - Não aplicado / aplicável</p>
                </div>
            </div>
            <div class="po-row">
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>MDA - manufacture detect analisys</p>
                </div>
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>PCB - printed circuit board</p>
                </div>
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>E&O - Excess and Obsolescence (accounting)</p>
                </div>
            </div>

            <po-divider></po-divider>

            <div class="po-row">
                <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                    <p>{{textSemMais}}</p>
                </div>
            </div>
            <div class="po-row" style="margin-top:15px;margin-bottom: 15px;">
                <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                    <p>{{textAtenciosamente}}</p>
                </div>
            </div>
            <div class="po-row">
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>{{textRepresentante}}: <b><u>{{this.pm_responsavel}}</u></b></p>
                </div>
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>{{textOrcamentistaRespos}}: <b><u>{{this.nome_orcamentista}}</u></b></p>
                </div>
                <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
                    <p>{{textGestorResp}}: <b><u>{{this.nome_gestor}}</u></b></p>
                </div>
            </div>
           
            <po-divider></po-divider>
           
            <div  class="po-row">
                <div class="po-xl-3 po-lg-3 po-md-3 po-sm-6">
                  <po-select
                    p-label="{{btnConcluir}}"
                    [p-options]="yesOrNot"
                    (p-change)="aprovadoOrReprovado($event)"
                    p-required="true"
                    [p-show-required]="true" 
                  >
                  </po-select>
                  <span 
                        *ngIf="this.showError"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                       {{msgError}}
                    </span>
                </div>
              </div>

              <div  class="po-row" *ngIf="isReprovado">
                <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                  <po-textarea
                    p-label="Justificar Reprovação"
                    [p-required]="true"
                    [p-show-required]="true" 
                  >
                  </po-textarea>
                </div>
              </div>

        </po-widget>
      </po-step>
  
      <po-step p-label="{{textTitleAprovAltern}}" *ngIf="this.gridItemsListAlternativa.lenght > 0">
        <po-widget
          class="po-md-12"
          [p-primary-label]="previousLabelWidget"
          (p-primary-action)="stepper.previous()"

          [p-secondary-label]="saveLabelWidget"
          (p-secondary-action)="save()"
        >
        <po-table 
            style="width: 100%;" 
            [p-hide-columns-manager]="true"
            [p-selectable]="true"
            [p-single-select]="false" 
            [p-columns]="gridColumnsListAlternativa" 
            [p-items]="gridItemsListAlternativa"
            [p-sort]="true"
            [p-striped]="true"
            [p-hide-text-overflow]="true"
            (p-selected)="selectListAlternativa($event)"
            (p-unselected)="decreaseLine($event)"
            (p-all-unselected)="decreaseLineAllAlternativa($event)"
            (p-all-selected)="selectLineAllAlternativa($event)"
        >
        </po-table>

        <div class="po-row">
            <po-button 
                class="po-md-6" 
                p-icon="po-icon po-icon-close" 
                (p-click)="approveItems('disapprovedAlternativos')" 
                [p-danger]="true" 
                p-label="{{textBtnDisapprove}}"> </po-button>
            <po-button class="po-md-6" p-icon="po-icon po-icon-ok" (p-click)="approveItems('toApproveAlternativos')" p-label="{{textBtnApprove}}"> </po-button>
          </div>
        
        </po-widget>
      </po-step>
  
    </po-stepper>
    
    <po-modal p-title="{{textModal}}" p-size="auto">
        <form>
            <po-table
                p-container="shadow"
                [p-hide-columns-manager]="true"
                [p-sort]="true"
                [p-columns]="gridModalConsolidacao" 
                [p-items]="griValuesModalConsolidacao" 
            >
            
            </po-table>
      
            <po-divider></po-divider>
            <div class="po-row" *ngIf="isReproved">
                <po-select 
                    class="po-md-6"
                    name="revisarRFQ"
                    p-label="{{titleReprovRFQ}}"
                    [p-options]="yesOrNot"
                    >
                </po-select>
            </div>

            <div class="po-row" *ngIf="isRevisao">
                <po-select 
                    class="po-md-6"
                    name="revisarRFQ"
                    p-label="{{titleRevisaoRFQ}}"
                    [p-options]="yesOrNot"
                >
                </po-select>

                <div>
                    <h3>Solicitação da Revisão da RFQ</h3>

                    <po-checkbox-group
                        class="po-md-12"
                        name="checkboxGroup"
                        [p-columns]="3"
                        [p-options]="optionsRevisaoModal"    
                        (p-change)="showOrNotDetails($event)"    
                    >
                    </po-checkbox-group>

                    <po-textarea class="po-md-12" name="orderDetail" p-label="{{campoJustificativa}}:" [p-rows]=8 *ngIf="isDetail"></po-textarea>
                </div>
            </div>
          
            

        </form>
      
        <po-modal-footer [p-disabled-align]="false">
          <po-button p-danger="true" (p-click)="poModal.close();" p-label="{{textBtnCancelar}}"> </po-button>
          <po-button p-kind="primary" (p-click)="saveAndMoveData()" p-label="{{textBtnFinalizar}}"> </po-button>
        </po-modal-footer>
      </po-modal>
      
</po-widget>
