<po-widget class="m-b-1">
  <div class="po-row">
    <div class="po-col-12 po-md-6">
      <span class="po-icon po-icon-arrow-left blue cursor-pointer " routerLink="/consultardocumentos">
        Voltar
      </span>
    </div>
  </div>

    
    
  
    </po-widget>
    
    <po-widget>
      <po-table style="width: 100%;" 
      [p-columns]="gridColumns" 
      [p-items]="gridItems"
      [p-sort]="true"
      [p-hide-columns-manager]="true"
      
      >
    </po-table>
   
    </po-widget>
    
  