<po-widget class="m-b-1">
    <div class="po-row">
      <div class="po-col-12 po-md-6">
        <span class="">
          
        </span>
      </div>
      <div class="po-col-12 po-md-6 po-text-right">
        <span class="m-r-1 blue cursor-pointer">
          Limpar Filtros
        </span>
      </div>
    </div>
    
    
    
      <div  class="po-row">
        <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
          <po-multiselect
            [p-sort]="true" 
            [p-hide-search]='false' 
            [p-hide-select-all]='true' 
            
            p-label="Nº Solicitação"
            
          >
          </po-multiselect>
        </div>
        <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
          <po-multiselect
            [p-sort]="true" 
            [p-hide-search]='false' 
            [p-hide-select-all]='true' 
            
            p-label="Status"
            
          >
          </po-multiselect>
        </div>
    
      </div>
    </po-widget>
    <po-table style="width: 100%;" 
        [p-columns]="gridColumns" 
        [p-items]="gridItems"
        [p-sort]="true"
        [p-hide-columns-manager]="true"
    >
    </po-table>
