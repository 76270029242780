import { Injectable } from '@angular/core';
import { BehaviorSubject,Observable  } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SharedService {
  isindicProdSubject = new BehaviorSubject<boolean>(false);
  isindicProd$: Observable<boolean> = this.isindicProdSubject.asObservable();

  isNacionalSubject = new BehaviorSubject<boolean>(false);
  isNacional$: Observable<boolean> = this.isNacionalSubject.asObservable();

  isReqCriSubject = new BehaviorSubject<boolean>(false);
  isReqCri$: Observable<boolean> = this.isReqCriSubject.asObservable();
  
  
  isIsencaoImpostSubject = new BehaviorSubject<boolean>(false);
  isIsencaoImpost$: Observable<boolean> = this.isIsencaoImpostSubject.asObservable();
  
  isCodSapSubject = new BehaviorSubject<boolean>(false);
  isCodSap$: Observable<boolean> = this.isCodSapSubject.asObservable();

  isProdCorrentSubject = new BehaviorSubject<boolean>(false);
  isProdCorrent$: Observable<boolean> = this.isProdCorrentSubject.asObservable();

  isCodProdSubject = new BehaviorSubject<boolean>(false);
  isCodProd$: Observable<boolean> = this.isCodProdSubject.asObservable();

  isViewRequestSubject = new BehaviorSubject<boolean>(false);
  isViewRequest$: Observable<boolean> = this.isViewRequestSubject.asObservable();

  private dadosCampoSubject = new BehaviorSubject<string>(''); 
  dadosCampo$ = this.dadosCampoSubject.asObservable(); 

  tipoVendaSubject = new BehaviorSubject<string>('');
  tipoVenda$ = this.tipoVendaSubject.asObservable();

  codClienteSubject = new BehaviorSubject<string>('');
  codCliente$ = this.codClienteSubject.asObservable();

  linesProdSubject = new BehaviorSubject<number>(0);
  
  linesNegociacaoSubject = new BehaviorSubject<number>(0);
  lineNegociacao$ = this.linesNegociacaoSubject.asObservable();
  
  private linesDocumentSubject = new BehaviorSubject<any>([])
  linesDocument$ = this.linesDocumentSubject.asObservable(); 
  
  curentProductSubject = new BehaviorSubject<any>([]);
  curentProduct$: Observable<boolean> = this.curentProductSubject.asObservable();
  
  productsRevisaoSubject = new BehaviorSubject<any>([]);
  productsRevisao$: Observable<boolean> = this.productsRevisaoSubject.asObservable();
  
  dadosPropostas = new BehaviorSubject<any>([]);
  dadosPropostas$: Observable<boolean> = this.dadosPropostas.asObservable();

  dadosNegociacao = new BehaviorSubject<any>([]);
  dadosNegociacao$: Observable<boolean> = this.dadosNegociacao.asObservable();

  dadosFiles = new BehaviorSubject<any>([]);
  dadosFiles$: Observable<boolean> = this.dadosFiles.asObservable();

  dadosForm = new BehaviorSubject<any>([]);
  dadosForm$: Observable<boolean> = this.dadosForm.asObservable();

  dadosFormNegociacao = new BehaviorSubject<any>([]);
  dadosFormNegociacao$: Observable<boolean> = this.dadosFormNegociacao.asObservable();

  solicitacoesUserSubject = new BehaviorSubject<any>([]);
  solicitacoesUser$: Observable<boolean> = this.solicitacoesUserSubject.asObservable();
  
  atualizarDadosCampo(dadosCampo$: string) {
    this.dadosCampoSubject.next(dadosCampo$);
  }

  insertDocuments(line$: any) {
    this.linesDocumentSubject.next([...this.linesDocumentSubject.value,line$]);
  }

  insertSolicitacoes(line$: any) {
    this.solicitacoesUserSubject.next([...this.solicitacoesUserSubject.value,line$]);
  }
 
  updateDadosFormProposta(dados: any) {
    this.dadosForm.next([...this.dadosForm.value,dados]);
  }

  get formDadosProposta(): any {
    return this.dadosForm.value
  }
 
  updateCodCliente(dados: any) {
    this.codClienteSubject.next(dados);
  }

  get getCodCliente(): any {
    return this.codClienteSubject.value
  }
 
  updateDadosFormNegociacao(dados: any) {
    this.dadosFormNegociacao.next([dados]);
  }

  get formDadosNegociacao(): any {
    return this.dadosFormNegociacao.value
  }

  get linesDocumentValue(): any {
    return this.linesProdSubject.value;
  }
 
  lineProd() {
    let index = this.linesProdSubject.value;
    let soma = index + 1
    this.linesProdSubject.next(soma);
  }

   dadosProposta(values:[],campos:[]){
    this.dadosPropostas.next([...this.dadosPropostas.value,values,campos]);
  }

  negociacao(values:[],campos:[]){
    this.dadosNegociacao.next([values,campos]);
  }

  curentRevisao(values:[],campos:[]) {
    this.productsRevisaoSubject.next([values,campos]);
  }

  curentProduct(dados: any) {
    this.curentProductSubject.next([...this.curentProductSubject.value,dados]);
  }

  get linesProposta(): number {
    return this.linesProdSubject.value;
  }

   postDadosFiles(values:[{}]){
    this.dadosFiles.next(values);
  }

  get files(): number {
    return this.dadosFiles.value;
  }
 
  lineNegociacao() {
    let index = this.linesNegociacaoSubject.value;
    let soma = index + 1
    this.linesNegociacaoSubject.next(soma);
  }

   
  view(isView: boolean) {
    this.isViewRequestSubject.next(isView);
  }

  get getIsView(): boolean {
    return this.isViewRequestSubject.value;
  }

  get getLinesNegociacao(): number {
    return this.linesNegociacaoSubject.value;
  }
 
 
  get getProducts(): string {
    return this.curentProductSubject.value;
  }

  get getProductRevisao(): string {
    return this.productsRevisaoSubject.value;
  }



}
