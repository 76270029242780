export default {
  textPropostaComercial: {
    'values': {
      'pt': 'Proposta Comercial',
      'en': 'Commercial Proposal'
    }
  },

  textTitleAprovAltern: {
    'values': {
      'pt': "Aprovação de Alternativos",
      'en': 'Approval of Alternatives'
    }
  },

  cliente: {
    'values': {
      'pt': 'Cliente',
      'en': 'Client'
    }
  },

  contato: {
    'values': {
      'pt': 'Contato',
      'en': 'Contact'
    }
  },


  revisao: {
    'values': {
      'pt': 'Revisão',
      'en': 'Review'
    }
  },

  data: {
    'values': {
      'pt': 'Data',
      'en': 'Date'
    }
  },

  nomeProjeto: {
    'values': {
      'pt': 'Nome do Projeto',
      'en': 'Project'
    }
  },
  
  textPrezados: {
    'values': {
      'pt': 'Prezado cliente,',
      'en': 'Dear customer,'
    }
  },

  textProposta: {
    'values': {
      'pt': 'Temos a satisfação de apresentar nossa proposta comercial abaixo e nos colocamos a sua inteira disposição para esclarecimentos.',
      'en': 'We are pleased to present our commercial proposal below and we are at your complete disposal for clarification.'
    }
  },

  textObjetoProposta: {
    'values': {
      'pt': 'Objeto da Proposta',
      'en': 'Object of the proposal'
    }
  },

  textCotacao: {
    'values': {
      'pt': 'A cotação é caracterizada como:',
      'en': 'Quotation is characterized as:'
    }
  },

  textPreco : {
    'values': {
      'pt': 'Preços de Venda',
      'en': 'Sales prices'
    }
  },

  textPrecosIndicados : {
    'values': {
      'pt': 'Os preços indicados são em Reais com ICMS e PIS/COFINS de 9,25% inclusos. IPI a incluir.',
      'en': 'The prices are in Dollars with ICMS and PIS/COFINS(9,25%) included. IPI to be included.'
    }
  },

  textPrecoComposicao: {
    'values': {
      'pt': 'Os preços sofrem composição de reajuste. Parcela sob variação positiva do IGPM, revisada anualmente no mês de março, e parcela sob variação cambial.',
      'en': 'Composition of the price. IGPM parcel: it will be reviewed annually in March. PTAX parcel: it will be adjusted according to exchange rate.'
    }
  },

  textInvestimentoInicial: {
    'values': {
      'pt': 'Investimentos iniciais para pordução (NRE)',
      'en': 'Initial investments for production (NRE)'
    }
  },

  textCenarioProposta: {
    'values': {
      'pt': 'Cenário da proposta',
      'en': 'Proposal scenario'
    }
  },

  textCondicoesTecnica: {
    'values': {
      'pt': 'Condições técnicas',
      'en': 'Technical conditions'
    }
  },

  textTestesAplicados: {
    'values': {
      'pt': 'Testes aplicados',
      'en': 'Applied tests'
    }
  },

  textEmbalagem: {
    'values': {
      'pt': 'Embalagem',
      'en': 'Packaging'
    }
  },

  textInfosComplementares: {
    'values': {
      'pt': 'Informações Complementares',
      'en': 'Complementary information'
    }
  },

  textInfosAdicionais: {
    'values': {
      'pt': 'Informações adicionais',
      'en': 'Additional information'
    }
  },

  textAdvice: {
    'values': {
      'pt': 'A Hi-mix Eletrônicos reserva-se o direito de revisar e alterar essa proposta comercial a qualquer tempo mediante a apresentação posterior de informações complementares por parte do cliente. Reserva-se também o direito de revisar esse documento para ajustar itens meramente estimados pela Hi-mix devido a ausência de informações de responsabilidade do cliente, ou quando as informações do mesmo não contemplem a realidade.',
      'en': "Hi-Mix Eletrônicos reserves the right to review and amend this commercial proposal at any time upon further presentation of complementary information, by the client. It also reserves the right to review this document to adapt items merely estimated by Hi-Mix due to lack of information under the client's liability, or when its information does not reflect reality."
    }
  },

  campoJustificativa: {
    'values': {
      'pt': 'Justificativa',
      'en': "Justification"
    }
  },

  textSubtitle: {
    'values': {
      'pt': 'Legenda com algumas siglas ou termos utilizados nesse documento',
      'en': "Subtitle"
    }
  },

  textSemMais: {
    'values': {
      'pt': 'Sem mais para o momento',
      'en': "Nothing else to be discussed, I remain at your services for further explanation, as required."
    }
  },

  textAtenciosamente: {
    'values': {
      'pt': 'Atenciosamente,',
      'en': "Best regards,"
    }
  },

  textRepresentante: {
    'values': {
      'pt': 'Representante',
      'en': "Responsible Estimator"
    }
  },
  textOrcamentistaRespos: {
    'values': {
      'pt': 'Orçamentista Responsável',
      'en': "Responsible Estimator"
    }
  },
  textModal: {
    'values': {
      'pt': 'Consolidação',
      'en': "Consolidation"
    }
  },

  textGestorResp: {
    'values': {
      'pt': 'Gestor(a) Responsável',
      'en': "Responsible Manager"
    }
  },

  textBtnApprove: {
    'values': {
      'pt': 'Aprovar Itens',
      'en': "Approve Items"
    }
  },

  textBtnRevisar: {
    'values': {
      'pt': 'Revisar Itens',
      'en': "Review Items"
    }
  },
  textBtnDisapprove: {
    'values': {
      'pt': 'Reprovar Itens',
      'en': "Disapprove Items"
    }
  },
 
  textBtnAvancar: {
    'values': {
      'pt': 'Avançar',
      'en': "Advance"
    }
  },

  textBtnVoltar: {
    'values': {
      'pt': 'Voltar',
      'en': "Return"
    }
  },

  textBtnSalvar: {
    'values': {
      'pt': 'Concluir',
      'en': "Save"
    }
  },

  textBtnFinalizar: {
    'values': {
      'pt': 'Finalizar RFQ',
      'en': "Finalize RFQ"
    }
  },

  textBtnCancelar: {
    'values': {
      'pt': 'Cancelar',
      'en': "Cancel"
    }
  },

  textgridValuesInfoAdicionais: {
    'values': {
      'pt': "Os preços são válidos considerando os volumes indicados e em caso de alteração os preços poderão ser revistos.",
      'en': "The prices are valid for the indicated volumes, and in case of change, the prices can be revised."
    }
  },

  titleReprovar:{
    'values': {
      'pt': "Reprovar",
      'en': "Disapprove"
    }
  },

  titleRevisaoRFQ:{
    'values': {
      'pt': "Deseja Abrir uma Revisão desta RFQ, imediatamente?",
      'en': "Do you want to immediately open a review of this RFQ?"
    }
  },

  titleReprovRFQ:{
    'values': {
      'pt': "Para a proxima revisão, deseja excluir os produtos que foram reprovados nesta proposta?",
      'en': "Do you want to immediately open a review of this RFQ?"
    }
  },

  btnAprovar:{
    'values': {
      'pt': "Aprovação NRE e condições comerciais",
      'en': "Approval NRE and Commercial Conditions"
    }
  },

  textQuantidadeTotal:{
    'values': {
      'pt': "Quantidade Total Selecionada",
      'en': "Total Quantity Selected"
    }
  },

  textTotalSelecionado:{
    'values': {
      'pt': "Valor Total Selecionado",
      'en': "Total Value Selected"
    }
  },

  textPrecoTotal:{
    'values': {
      'pt': "Valor Preço Total",
      'en': "Total Price Value"
    }
  },

  msgError:{
    'values': {
      'pt': "Campo obrigatório!",
      'en': "Mandatory field!"
    }
  },

  tableTexts:{
    'description': {
      'pt': "Descrição",
      'en': "Description"
    },
    'descriptionProduc': {
      'pt': "Descrição",
      'en': "Product Description"
    },'qtd': {
      'pt': "Qtde.",
      'en': "Qtty."
    },'precUnit': {
      'pt': "Preço Unitário",
      'en': "Unit Cost"
    },'cambio': {
      'pt': "Câmbio",
      'en': "PTAX"
    },'precTotal': {
      'pt': "Preço Total",
      'en': "Total Cost"
    },'icms': {
      'pt': "ICMS",
      'en': "ICMS included"
    }, 'ipi': {
      'pt': "IPI",
      'en': "IPI to be included"
    },'delivery': {
      'pt': "Praz. de Entreg. (sem.)",
      'en': "Delivery terms (weeks)"
    },'pricNet': {
      'pt': "Preço Net",
      'en': "Net Cost"
    },'tipProd': {
      'pt': "Tipo de Produção",
      'en': "Type of Production"
    },'obs': {
      'pt': "Observação",
      'en': "Comments"
    },'appoints': {
      'pt': "Apontamento",
      'en': "Appointments"
    },'aprovando': {
      'pt': "Aprovando",
      'en': "Approving"
    },'aprovado': {
      'pt': "Aprovado",
      'en': "Approved"
    },'reprovador': {
      'pt': "Reprovado",
      'en': "Disapproved"
    },'revisar': {
      'pt': "Revisão",
      'en': "Review"
    },'fabril': {
      'pt': "Site fabril:",
      'en': "Manufacturer site:"
    },'periodocontrato': {
      'pt': "Período de contrato:",
      'en': "Contractual period:"
    },'prazoPagamento': {
      'pt': "Prazo de pagamento:",
      'en': "Payment term:"
    },'condEntrega': {
      'pt': "Condição de entrega:",
      'en': "Delivery conditions:"
    },'cenarioTribut': {
      'pt': "Cenário de tributação válido como:",
      'en': "Taxation scenario valid as:"
    },'estatadoFedera': {
      'pt': "Estado da federação considerado para faturamento:",
      'en': "State of the federation considered for invoicing:"
    },'paisDestino': {
      'pt': "País destino drawback:",
      'en': "Drawback addressee country:"
    },'ppb': {
      'pt': "PPB (Processo Produtivo Básico):",
      'en': "PPB (Basic Productive Process):"
    },'portaria': {
      'pt': "Portaria 950:",
      'en': "Concierge 950:"
    },'clienteIPI': {
      'pt': "Cliente tem isenção de IPI e/ou PIS/COFINS:",
      'en': "Client with IPI exemption:"
    },'garantiaFabric': {
      'pt': "Garantia para defeito de fabricação:",
      'en': "Warranty for manufacture defect:"
    },'amortizaInves': {
      'pt': "Amortização de investimentos específicos:",
      'en': "Amortization of specific investments:"
    },'consideradoItens': {
      'pt': "Considerado itens alternativos:",
      'en': "Alternative items considered:"
    },'itensSujeto': {
      'pt': "Itens sujeitos a compra mínima (MOQ):",
      'en': "	Parts with MOQ (Minimum Order Quantity):"
    },'cambioBase': {
      'pt': "Câmbio base:",
      'en': "Base Exchange rate:"
    },'ptaxFaturamento': {
      'pt': "PTAX faturamento:",
      'en': "PTAX invoicing:"
    },'validadeProposta': {
      'pt': "Validade da proposta:",
      'en': "Proposal validity:"
    },"embalagem":{
      'pt': "Embalagem unitária:",
      'en': 'Unit Packaging:'
    },"embalagemColetiva":{
      'pt': "Embalagem coletiva:",
      'en': 'Collective Packaging:'
    },"armazenamento":{
      'pt': "Armazenamento em pallet:",
      'en': 'Pallet Storage:'
    },"etiquetas":{
      'pt': "Etiquetas:",
      'en': 'Labels:'
    },"itensCotados":{
      "pt": "Itens cotados em Broker:",
      'en': 'Parts quoted in Broker:'
    },"relacaoItens":{
      "pt": "Relação de itens fornecidos pelo cliente:",
      'en': 'List of items supplied by client:'
    },"relacaoItensNOrcados":{
      "pt": "Relação de itens que não devem ser orçados:",
      'en': 'List of items that should not be quoted:'
    },"itensNegociadoCliForne":{
      "pt": "Itens negociados entre cliente e fornecedor:",
      'en': 'Parts negotiated between client and supplier:'
    },"firmware":{
      "pt": "Gravação de firmware:",
      "en":"Firmware engraving:"
    },"responsTestes":{
      "pt": "Responsável pelos testes:",
      "en":"Person in charge of tests:"
    },"testesMda":{
      "pt": "Teste MDA:",
      "en":"MDA Test:"
    },"testeIct":{
      "pt": "Teste ICT:",
      "en":"ICT Test:"
    },"testeFCT":{
      "pt": "Teste FCT:",
      "en":"FCT Test:"
    },"testeBurn":{
      "pt": "Teste burn-in:",
      "en":"Burn-in Test:"
    },"testeRun":{
      "pt": "Teste run-in:",
      "en":"Run-in Test:"
    },"jigaTeste":{
      "pt": "Jigas de teste:",
      "en":"Test Jigs:"
    },"vidaUtil":{
      "pt": "Vida útil de equipamentos de testes e toolings:",
      "en":"Test equipment useful life:"
    },"especifSoldabili":{
      "pt": "Especificações de soldabilidade",
      "en":"Welding specifications:"
    },"classificIPC":{
        "pt": "Classificação de montagem conforme norma IPC	",
        "en":"Assembly according to IPC standards:"
    },"bom":{
        "pt": "BOM (Bill of Material)	",
        "en":"BOM (Bill of Material):"
    },"arquivoGeber":{
        "pt": "Arquivo Gerber",
        "en":"Gerber file:"
    },"amostraFotos":{
        "pt": "Amostras e fotos do produto:",
        "en":"Product samples and photos:	"
    },"instrucoesMontagem":{
        "pt": "Instruções de montagem",
        "en":"Assembly instructions:	"
    },"inspecao":{
        "pt": "Inspeção automática (AOI/SPI)",
        "en":"Automatic Inspection (AOI/SPI)	"
    },"necessidadeTreinamento":{
        "pt": "Necessidade de Treinamento Específico",
        "en":"Need for specific training:	"
    },"requisitoAmbiental":{
        "pt": "Requisito Ambiental",
        "en":"Environmental requirement:	"
    },"qualityPlan":{
        "pt": "Quality Plan",
        "en":"Quality Plan	"
    },"pfmea":{
        "pt": "PFMEA e FLUXOGRAMA",
        "en":"PFMEA e FLOWCHART	"
    },"ppap":{
        "pt": "PPAP",
        "en":"Production Part Approval Process"
    },"criterioRastreabilidade":{
        "pt": "Critério de Rastreabilidade",
        "en":"Traceability criterion:	"
    },"certificacoes":{
        "pt": "Certificações",
        "en":"Certificates"
    },"tipoVerniz":{
        "pt": "Tipo de verniz aplicado",
        "en":"Type of varnish applied:"
    },"planoRma":{
        "pt": "Plano de RMA",
        "en":"RMA Plan:"
    },"criterioCosmeticos":{
        "pt": "Critérios cosméticos",
        "en":"Cosmetic criteria:"
    },"caracteristicas14949":{
        "pt": "Características especiais de material (IATF 16949)",
        "en":"Special Features material (ISO TS):	"
    },"caracteristicas13485":{
        "pt": "Características especiais de material (ISO 13485)",
        "en":"Special Features material (ISO 13485):	"
    },"acuracia":{
        "pt": "Acurácia do orçamento",
        "en":"Quotation Accuracy"
    },"naoConsideraEstoque":{
        "pt": "Não considera estoque de produto acabado. Os produtos serão faturados logo após o término da manufatura.",
        "en":"Is not considered stock of finished product. Products are billed shortly after the end of manufacture."
    },"propostaContemplaBom":{
        "pt": "Proposta contempla itens alternativos aos solicitados na BOM. É imprescindível a homologação de 100% dos alternativos para que a proposta tenha validade. O prazo de entrega do produto começará a contar a partir do momento em que a lista de materiais homologados estiver de acordo com a proposta comercial. Estes itens estão identificados na BOM anexa à proposta comercial.",
        "en":"Proposal contemplates alternative items to those requested in BOM. It is essential to approve 100% of the alternatives for the proposal to be valid. The deadline of the product will start counting from the moment the list of approved materials is in accordance with the commercial proposal. These items are identified in the BOM attached to the commercial proposal."
    },"propostaContemplaMOQ":{
        "pt": "Proposta contempla componentes que exigem MOQ (mínimo para compra) e MQP (multiplos de embalagem). Caso não haja continuidade de produção ou passados 90 dias sem demanda, trataremos o excesso como obsoleto, cabendo a contratante o ressarcimento à Hi-Mix desses componentes em excesso (itens identificados na lista de materiais que segue anexa a proposta Comercial).",
        "en":"Proposal includes components that require MOQ (minimum for purchase) and MQP (multiples of packaging). If there is no continuity of production or after 90 days without demand, we will treat the excess as obsolete, and the contractor will be required to reimburse Hi-Mix for these excess components (items identified in the bill of materials that follows the Commercial proposal)."
    },"cenarioPrecoVenda":{
        "pt": "O cenário de Preço de Venda indicado nesta Proposta Comercial que contempla PPB (Processo Produtivo Básico- Lei da Informática) depende da Aprovação da Portaria de PPB pelo MCTIC (Ministério da Ciência, Tecnologia, Inovações e Comunicações), o qual, desde sua ultima alteração, NÃO possui prazo determinado para ocorrer. Conforme nova regulamentação deste órgão, vigente desde o dia 01/abr/2020, deixou de existir o “PPB Provisório”, sendo obrigatório aguardarmos todas as análises do MCTIC para que possamos praticar os faturamentos com o referido benefício. Até que ocorra a aprovação da portaria de habilitação ao PPB do vosso produto, pedimos que considerem os preços apresentados sem a observância do benefício de PPB.",
        "en":"The Sale Price scenario indicated in this Commercial Proposal that includes PPB (Basic Production Process - Information Technology Law) depends on the Approval of the PPB Ordinance"
    },"jigaFixture":{
        "pt": "Jigas, Fixtures e equipamentos de testes deverão ser fornecidos pelo cliente.",
        "en":"Jigas, Fixtures and Testing Equipment must be provided by the customer."
    },"nreCitadas":{
        "pt": "As NRE's citadas nas tabelas 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, não estão inclusas no preço dos produtos. Serão cobradas separadamente.",
        "en":"The NRE's mentioned in tables 1.2, 1.3, 1.4, 1.5, 1.6, are not included in the price of the products. They will be charged separately. The investiments in table 1.5 must be purchased by Marelli directly from the manufacturer."
    },"aliquotasImpostos":{
        "pt": "As alíquotas de impostos citados nesta proposta estão de acordo com a legislação fiscal vigente. O preço e demais condições comerciais poderão sofrer alterações caso haja qualquer alteração na legislação municipal, estadual ou federal que impactem nos benefícios, isenções e suspensões aplicadas a Hi-mix.",
        "en":"The tax rates cited in this proposal are subject to existing tax legislation. The price and another commercial conditions may change if there is any changes in municipal, state or federal legislation that impact the benefits, exemptions and suspensions applied to Hi-mix."
    },"itensConstantes":{
        "pt": "Os itens constantes nesta proposta são planejados e produzidos sob encomenda, consequentemente os pedidos colocados serão classificados como NÃO canceláveis e NÃO retornáveis (NC/NR).",
        "en":"The products contained in this proposal are planned and produced to order, consequently the orders placed will be classified as NC/NR (NON-cancelable and NON-returnable)."
    },"industriasComponentes":{
        "pt": "As indústrias de componentes eletrônicos não estão conseguindo satisfazer a demanda. Isso está ocasionando uma escassez generalizada e aumentos de preços de materiais eletrônicos (ALOCAÇÃO). Como esta ALOCAÇÃO da cadeia de suprimentos está em evolução, o Comprador ao aprovar esta proposta comercial, reconhece que seu impacto no desempenho da HI-MIX ELETRONICOS não pode ser razoavelmente apurado na presente data. Assim, o Comprador concorda que a HI-MIX ELETRÔNICOS terá o direito de revisar os termos e condições desta Oferta, incluindo prazos de entrega, volumes e/ou preços, desde que esses ajustes sejam devidamente justificados. A HI-MIX Eletrônicos cobrará do Comprador todos os custos de ágios sobre compra, taxas de urgência ou custos de frete especial e sob nenhuma circunstância será responsável perante o Comprador pela revogação ou atrasos no cumprimento de suas obrigações, na medida em que estas aconteçam em decorrência da ALOCAÇÃO na Cadeia de Suprimentos.",
        "en": 'Electronic component industries are failing to meet demand. This is causing widespread shortages and price increases for materials electronics (ALLOCATION). As this supply chain ALLOCATION is evolving, the Buyer, in approving this commercial proposal, acknowledges that its impact on the performance of HI-MIX ELETRONICOS cannot be reasonably determined on the present date. Accordingly, the Buyer agrees that HI-MIX ELECTRONICS shall have the right to revise the terms and conditions of this Offer, including delivery times, volumes and/or prices, provided that these "adjustments are duly justified. HI-MIX Electronics will charge the Buyer all PPV costs (purchase price variance), rush fees or special freight charges and under no circumstances shall be liable to" Buyer for the revocation or delays in fulfilling its obligations, insofar as they occur as a result of the ALLOCATION in the Supply Chain.'
    },"garantiaManufatura":{
        "pt": "A garantia de manufatura da HI-MIX não se aplicará a nenhum defeito ou vício no produto, aparente ou oculto, que tenha origem em qualquer desenho, projeto, especificação, processo, teste ou outro procedimento, ajuste ou modificação fornecido e/ou exigido por escrito pela CONTRATANTE.",
        "en":"HI-MIX's warranty of manufacture will not apply to any apparent or hidden defect or defect in the product arising from any design, design, specification, process, test or other procedure, adjustment or modification provided and / or required in writing by the CONTRACTING PARTY."
    },"pedidoCompra":{
        "pt": "O Pedido de Compra colocado pelo cliente indica o aceite na íntegra de todas as condições comerciais e demais informações estabelecidas neste orçamento.",
        "en":"The purchase order placed by the customer indicates the acceptance in full of all commercial conditions and other information established in this budget."
    },"aprovacao": {
        'pt': "Aprovação",
        'en': 'Approval'
    },"modal_rev_quantidade":{
      'pt': 'Revisão de qualidade',
      'en': 'Quality Review'
    },"modal_rev_prazo_entreg":{
      'pt': 'Revisão do prazo de entrega',
      'en': 'Review of the Delivery Deadline'
    },"modal_rev_nre":{
      'pt': 'Revisão de NRE',
      'en': 'NRE Review'
    },"modal_rev_projeto":{
      'pt': 'Revisão do projeto',
      'en': 'Project Review'
    },"modal_rev_preco":{
      'pt': 'Revisão de preço',
      'en': 'Price review'
    },"modal_rev_exportacao":{
      'pt': 'Exportação',
      'en': 'Export'
    },"modal_rev_geral_preco":{
      'pt': 'Revisão geral de preço',
      'en': 'General price review'
    },"modal_rev_inclu_novos_prod":{
      'pt': 'Inclusão de novos produto',
      'en': 'Inclusion of new product”'
    },"modal_rev_process_export":{
      'pt': 'Revisão do processo de exportação',
      'en': 'Review of the export process'
    },"modal_rev_condi_entrega":{
      'pt': 'Revisão condição de entrega',
      'en': 'Review of delivery condition'
    },"modal_rev_inclu_novos_cenarios":{
      'pt': 'Inclusão de novos cenário',
      'en': 'Inclusion of new scenario'
    },"modal_rev_alternativos":{
      'pt': 'Revisão de alternativos',
      'en': 'Review of alternatives'
    },"modal_rev_prazo_pgto":{
      'pt': 'Revisão do prazo de pagamento',
      'en': 'Review of the payment term'
    },"modal_rev_processo":{
      'pt': 'Revisão de processo',
      'en': 'Process review”'
    },"modal_rev_outros":{
      'pt': 'Outros',
      'en': 'Others'
    }
  }
 


};
