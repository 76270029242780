import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ConsultarComponent} from './pages/proposta-comercial/consultar/consultar.component'
import {HomeComponent} from './pages/default-system/home/home.component'
import {NovaSolicitacaoComponent} from './pages/proposta-comercial/nova-solicitacao/nova-solicitacao.component'
import {AprovacaoComponent} from './pages/proposta-comercial/aprovacao/aprovacao.component'
import {RegisterComponent} from './pages/default-system/register/register.component'
import {LoginComponent} from './pages/default-system/login/login.component'
import {SecondAuthenticationComponent} from './pages/default-system/second-authentication/second-authentication.component'
import {ChangePasswordComponent} from './pages/default-system/change-password/change-password.component'
import { AutorizadoGuard } from './_gaurd/autorizado.guard';
import { NaoAutorizadoGuard } from './_gaurd/usuario-nao-autenticado.guard';
import { SegundaAutenticacaoGuard } from './_gaurd/segunda-autenticacao.guard';
import { ConsultaDocumentosComponent } from './pages/certificado-origem/consulta-documentos/consulta-documentos.component'
import { VisualizarSolicitacoesComponent } from './pages/visualizar-solicitacoes/visualizar-solicitacoes.component';
import { ListDocumentsComponent } from './pages/certificado-origem/list-documents/list-documents.component';
import { DocumentsComponent } from './pages/certificado-origem/documents/documents.component'
import { ListInvoicePackingComponent } from './pages/comex/list-invoice-packing/list-invoice-packing.component'
import { EditInvoicePackingComponent } from './pages/comex/edit-invoice-packing/edit-invoice-packing.component'

const routes: Routes = [
  { path: 'login',            component: LoginComponent},
  { path: 'authenticator',    component: SecondAuthenticationComponent ,         canActivate: [AutorizadoGuard]},
  { path:'register',          component:RegisterComponent,canActivate: [NaoAutorizadoGuard]},
  { path:'changepassword/:id',component:ChangePasswordComponent,canActivate: [NaoAutorizadoGuard]},
  { path: '',             component: HomeComponent,canActivate: [SegundaAutenticacaoGuard]},
  
  { path:'visualizardocumentos/:id',          component:VisualizarSolicitacoesComponent,},
  { path:'consultar',         component:ConsultarComponent,canActivate: [SegundaAutenticacaoGuard]},
  { path:'consultainvoice',         component:ListInvoicePackingComponent,canActivate: [SegundaAutenticacaoGuard]},
  { path:'aprovainvoice/:id',         component:EditInvoicePackingComponent,canActivate: [SegundaAutenticacaoGuard]},
  { path:'consultardocumentos',         component:ConsultaDocumentosComponent,canActivate: [SegundaAutenticacaoGuard]},
  { path:'listdocumentos',         component:ListDocumentsComponent,canActivate: [SegundaAutenticacaoGuard]},
  { path:'novasolicitacao',   component:NovaSolicitacaoComponent,canActivate: [SegundaAutenticacaoGuard]},
  { path:'viewrequest/:id',   component:NovaSolicitacaoComponent,canActivate: [SegundaAutenticacaoGuard]},
  { path:'aprovacao/:id',   component:AprovacaoComponent,canActivate: [SegundaAutenticacaoGuard]},
  { path:'documents/:id',          component:DocumentsComponent,},
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
