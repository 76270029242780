import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { AuthenticatorService } from './authenticator.service';

@Injectable({
    providedIn: 'root'
  })

export class UtilsService {

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) { }

    formatarCnpj(numero: string) {
        const cnpjLimpo = numero.replace(/\D/g, '');
      
        // Aplicar a máscara
        const parte1 = cnpjLimpo.substring(0, 2);
        const parte2 = cnpjLimpo.substring(2, 5);
        const parte3 = cnpjLimpo.substring(5, 8);
        const parte4 = cnpjLimpo.substring(8, 12);
        const parte5 = cnpjLimpo.substring(12, 14);
      
        return `${parte1}.${parte2}.${parte3}/${parte4}-${parte5}`;
    }

    formatDate(date:string){
        let formatDate = ''


        if (date.indexOf('/') != -1) {
            let splitDate = date.split('/')
            formatDate  = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0];
          }

        return formatDate
    }

    convertFile(file : any) : Observable<string> {
      const result = new ReplaySubject<string>(1);
      const reader: any = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => result.next(reader.result.split(',')[1]);
      return result;
    }

    consultarCNPJ(cnpj: string): Promise<any> {
      const script = document.createElement('script');
      script.src = `https://www.receitaws.com.br/v1/cnpj/${cnpj}?callback=processarResposta`;
    
      return new Promise((resolve, reject) => {
        (window as any)['processarResposta'] = (data: any) => {
          delete (window as any)['processarResposta']; // Remove a função após ser executada
          document.body.removeChild(script); // Remove o elemento <script> criado
    
          if (data.status === 'OK') {
            resolve({
              response: data
            }); // Resolve a promise com os dados da resposta
          } else {
            console.log('Erro ao consultar CNPJ:', data.message);
            reject(data); // Rejeita a promise em caso de erro
          }
        };
    
        // Adiciona o elemento <script> ao <body> para fazer a requisição JSONP
        document.body.appendChild(script);
      });
    }

    isCorrectPassword(password: String,confirmPassword: String){
      if(password == confirmPassword){
        return true
      }
  
      return false
    }

    async isRegisteredCustomer(email: String) {
      const requestData = {
        url: `${environment.url}/api/public/ecm/dataset/datasets`,
        method: 'POST'
      };
    
      const body = {
        name: "DTS_FIND_EMAIL",
        constraints: [
          {
            _field: "EMAIL", 
            _initialValue: email, 
            _finalValue: email, 
            _type: 1,
          }
        ]
      };

      const httpOptions = this.authenticator.connect(requestData);
  
  
      const response: any = await this.http.post(requestData.url, body, httpOptions).toPromise()
      
      if(response.content.values.length > 0){
        const {CNPJ,NOMEEMPRESA} = response.content.values[0]
        return {
          retorno:true,
          CNPJ,
          NOMEEMPRESA
        }
      }
  
      return {retorno:false}
    } 

    async isExistingEmail(email: String) {
      const requestData = {
        url: `${environment.url}/api/public/ecm/dataset/datasets`,
        method: 'POST'
      };
    
      const body = {
        name: "DTS_FRM_USUARIOS",
        constraints: [
          {
            _field: "email_user", 
            _initialValue: email, 
            _finalValue: email, 
            _type: 1
          }
        ]
      };
    
      const httpOptions = this.authenticator.connect(requestData);
  
  
      const response: any = await this.http.post(requestData.url, body, httpOptions).toPromise()
      
      if(response.content.values.length > 0){
        
        return {
          response:true,
          id:response.content.values[0].documentid
        }
      }
  
      return {
        response:false,
        id:0
      }
    } 
  } 
    