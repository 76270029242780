interface inputsProposta {
    item_prod_proposta: number;
    produto_corrente?: string;
    cod_produto?: string;
    ncm?: number;
    mod_pn_prod: string;
    apli_prod: string;
    func_prod: string;
    moeda_target_price?: string;
    target_price?: string;

    qtde_consumo1: string;
    periodo_consumo1: string;
    tipo_producao1: string;
    program_entrega1: string;
    prazo_entreg_consumo1: string;

    qtde_consumo2?: string;
    periodo_consumo2?: string;
    tipo_producao2?: string;
    program_entrega2?: string;
    prazo_entreg_consumo2?: string;

    qtde_consumo3?: string;
    periodo_consumo3?: string;
    tipo_producao3?: string;
    program_entrega3?: string;
    prazo_entreg_consumo3?: string;

    qtde_consumo4?: string;
    periodo_consumo4?: string;
    tipo_producao4?: string;
    program_entrega4?: string;
    prazo_entreg_consumo4?: string;

    simi_prod: string;
    indic_prod?: string;
    aceit_altern: string;
    finalidade_prod: string;
    possui_ppb?: string;
    isencao_impost?: string;
    req_cri_espec?: string;
    sumazi_prod: string;
    obs_isencao_impost?: string;
    espec_solda: string;
    ipca: string;
    certif_exter?: string;
    verniz_resi: string;
    equi_fw_teste: string;
    cobra_nre: string;
    jigas_cliente: string;
    obs_obj_proposta?: string;
    sumazi_prod_qual?: string;
  }

  class inputsProposta {
    constructor() {
      this.item_prod_proposta
      this.produto_corrente
      this.cod_produto;
      this.ncm;
      this.mod_pn_prod;
      this.apli_prod;
      this.func_prod;
      this.moeda_target_price;
      this.target_price;
      this.qtde_consumo1;
      this.periodo_consumo1;
      this.tipo_producao1;
      this.program_entrega1;
      this.prazo_entreg_consumo1;
  
      this.qtde_consumo2;
      this.periodo_consumo2;
      this.tipo_producao2;
      this.program_entrega2;
      this.prazo_entreg_consumo2;
  
      this.qtde_consumo3;
      this.periodo_consumo3;
      this.tipo_producao3;
      this.program_entrega3;
      this.prazo_entreg_consumo3;
  
      this.qtde_consumo4;
      this.periodo_consumo4;
      this.tipo_producao4;
      this.program_entrega4;
      this.prazo_entreg_consumo4;
  
      this.simi_prod;
      this.indic_prod;
      this.aceit_altern;
      this.finalidade_prod;
      this.possui_ppb;
      this.isencao_impost;
      this.req_cri_espec;
      this.sumazi_prod;
      this.obs_isencao_impost;
      this.espec_solda;
      this.ipca;
      this.certif_exter;
      this.verniz_resi;
      this.equi_fw_teste;
      this.cobra_nre;
      this.jigas_cliente;
      this.obs_obj_proposta;
      this.sumazi_prod_qual;
    }
  }

  export {
    inputsProposta
  };