import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import {environment} from '../../environments/environment.prod'
import * as OAuth from 'oauth-1.0a';
import { enc, HmacSHA1 } from 'crypto-js'

@Injectable({
  providedIn: 'root'
})
export class AuthenticatorService {

  constructor(
    
  ) { }

  public connect(requestData:any) {
    
    const oauth = new OAuth({
      consumer: {
        key: environment.consumerKey,
        secret: environment.consumerSecret,
      },
      signature_method: 'HMAC-SHA1',
      hash_function(base_string: string, key: any) {
        return HmacSHA1(base_string, key).toString(enc.Base64);

      }
    });


    const token = {
      key: environment.tokenKey,
      secret: environment.tokenSecret
    };

    const headers:any = oauth.toHeader(oauth.authorize(requestData, token));
    const httpHeaders = new HttpHeaders(headers);

    const httpOptions = {
      headers: httpHeaders
    };

    return httpOptions
  }
}
