interface inputsOpenProcess {
    cliente_corrente: string;
    cod_sap?: string;
    cnpj: string;
    nome_empresa: string;
    pais: string;
    cep: number;
    estado: string;
    cidade: string;
    segmento: string;
    nome_solic: string;
    email_solic: string;
    ddi_solic?: string;
    tel_solic?: string;
    cel_solic?: string;
    nome_tec?: string;
    ddi_tec?: string;
    tel_tec?: string;
    cel_tec?: string;
    email_tec?: string;

    nome_projeto: string;
    tipo_venda: string;
    idioma_proposta: string;
    moeda: string;
    neg_prev_forn: string;
    tipo_negocio: string;

    pais_fatura: string;
    uf_fatura_br?: string;
    uf_fatura?: string;
    autoriza_divulg: string;
    period_contrato: string;
    outro_periodo?: string;
    prazo_pgto: string;
    outro_prazo_pgto?: string;
    condi_entrega?: string;
    obs_cenario?: string;
    obs_neg_prev_fornc?: string;
    
    prazo_pgto_fornec: string;
    moeda_invo_bom: string;
    valor_invo_bom: number;
    incoterm: string;
    pais_origem_fornec: string;
    qtde_envio?: string;
    peso_envio?: number;
    cubagem_envio?: number;
    tratativa_import: string;
    qual?: string;
    obs_consolid_fornec_indicado?: string;

    pais_destino: string;
    estado_destino: string;
    incoterm_exportacao: string;
    certif_origem: string;
    seguro_exportacao: string;
    obs_exportacao?: string;
    linkAnexos?: string;
    obsDocumentos?: string;
  }

interface MontagemPropostaContent { 
  numProposta: string; 
  rfq_revisao: string; 
  documentid: number; 
  nome_projeto: string; 
  nome_empresa: string; 
  cod_sap: string; 
}

interface IMyData {
  cnpj: any;
  numProposta: string;
  documentid: number;
  nome_empresa: string;
  nome_projeto: string;
  rfq_revisao: number;
}

interface ComboOptions {
  clientes: ComboOptions[];
  paises: ComboOptions[];
  ddi: ComboOptions[];
  clientesModal: ComboOptions[];
  estados: ComboOptions[];
}

interface ComboOption {
  value: string;
  label: string;
}

interface ClienteContent {
  CODIGOCLIENTE: string;
  PESQUISA: string;
  NOMEEMPRESA: string;
}

interface PaisContent {
  descricao: string;
  pesquisa: string;
  codPais: string;
}

interface DdiPaisContent {
  DDI: string;
  PESQUISA: string;
}

interface EstadosContent {
  descricaoRegioes: string;
  pesquisa: string;
  codRegiao: string;
}

interface IGetClient{ 
  cnpj: string; 
  isRepresentante:boolean; 
  email:string
}

class ComboOptions {
  rfq_revisao!: string;
  
  
  constructor() {
    this.clientes;
    this.paises;
    this.ddi;
    this.clientesModal;
    this.estados;
  }
}

const tpVenda = [
  {value:"nacional", label:"Nacional"},
  {value:"exportacao", label:"Exportação"}
]

const optionsRevisaoModal = [
  { value:'modal_rev_quantidade', label:'Revisão de qualidade'},
  { value:'modal_rev_prazo_entreg',label: 'Revisão do prazo de entrega'},
  { value:'modal_rev_nre',label: 'Revisão de NRE'},
  { value:'modal_rev_projeto',label: 'Revisão do projeto'},
  { value:'modal_rev_preco',label: 'Revisão de preço'},
  { value:'modal_rev_exportacao',label: 'Exportação'},
  { value:'modal_rev_geral_preco',label: 'Revisão geral de preço'},
  { value:'modal_rev_inclu_novos_prod',label: 'Inclusão de novos produto'},
  { value:'modal_rev_process_export',label: 'Revisão do processo de exportação'},
  { value:'modal_rev_condi_entrega',label: 'Revisão condição de entrega'},
  { value:'modal_rev_inclu_novos_cenarios',label: 'Inclusão de novos cenário'},
  { value:'modal_rev_alternativos',label: 'Revisão de alternativos'},
  { value:'modal_rev_prazo_pgto',label: 'Revisão do prazo de pagamento'},
  { value:'modal_rev_processo',label: 'Revisão de processo'},
  { value:'modal_rev_outros',label: 'Outros'}
]

const yesOrNot = [
  { value: 'sim', label: 'Sim' },
  { value: 'nao', label: 'Não' }
];

const optionsRevisao = [
  { value: 'nova', label: 'Nova RFQ' },
  { value: 'revisao', label: 'Revisão de RFQ' }
];

const segmento = [
  { value:"agri_prec", label:"Agrícola de precisão"},
  { value:"auto_ind", label:"Automação Industrial"},
  { value:"auto_banc", label:"Automação Bancária"},
  { value:"automotivo", label:"Automotivo"},
  { value:"consumer", label:"Consumer"},
  { value:"control_seg", label:"Controle e Segurança"},
  { value:"def_aero", label:"Defesa e Aeroespacial"},
  { value:"elet_embarc", label:"Eletrônica Embarcada (GPS,outros)"},
  { value:"energia", label:"Energia"},
  { value:"instrumentacao", label:"Instrumentação"},
  { value:"med_hospi", label:"Médico e Hospitalar"},
  { value:"mobile", label:"Mobile"},
  { value:"power", label:"Power"},
  { value:"telecom", label:"Telecom"},
  { value:"ti", label:"TI"},
  { value:"auto_comer", label:"Automação comercial"},
  { value:"iot", label:"IOT"}
]


const optionsMotivoRevisao = [
  { value: '01', label: 'Inclusão de Produtos' },
  { value: '02', label: 'Engenharia Industrial' },
  { value: '03', label: 'Engenharia de Produto' },
  { value: '04', label: 'Procurement' },
  { value: '05', label: 'Orçamentos' },
  { value: '06', label: 'Diretoria' }
];

const idioma = [
  {value:"pt",label:"Português"},
  {value:"en",label:"Inglês"}
]

const moeda = [
  {value:"BRL",label:"BRL (R$)"},
  {value:"USD",label:"USD ($)"}
]

const tipoNegocio = [
  {value:"full_turn_key",label:"Full Turn key"},
  {value:"ckd",label:"CKD em turn key"},
  {value:"skd",label:"SKD"},
  {value:"consum_final_turn_key",label:"Consumidor Final em Turn Key"},
  {value:"consum_final_consig",label:"Consumidor Final consignado"}
]

const periodContrato = [
  {value:"12_meses",label:"12 Meses"},
  {value:"24_meses",label:"24 Meses"},
  {value:"definir",label:"A Definir"},
  {value:"outros",label:"Outros"}
]

const incotermoptions = [
  {value:"exw",label:"EXW"},
  {value:"fca",label:"FCA"},
  {value:"fas",label:"FAZ"},
  {value:"fob",label:"FOB"},
  {value:"cfr",label:"CFR"},
  {value:"cif",label:"CIF"},
  {value:"cpt",label:"CPT"},
  {value:"cip",label:"CIP"},
  {value:"dap",label:"DAP"},
  {value:"dpu",label:"DPU"},
  {value:"ddp",label:"DDP"}
]

const tratativaImport =[
  {value:"nao_ha",label:"Não há"},
  {value:"regis_inmetro",label:"Registro no Inmetro"},
  {value:"ipem",label:"Ipem"},
  {value:"licen_import_prev",label:"Licenças de importação prévia ao embarque"},
  {value:"tratat_carga_perig",label:"Tratativa de carga perigosa"},
  {value:"outra",label:"Outra"},
]

const seguroExportacao =[
  {value:"cliente",label:"Cliente"},
  {value:"hi_mix",label:"Hi-Mix"}
]

const prazoPgto = [
  {value:"40_60",label:"40% Antecipados e 60% na Entrega"},
  {value:"a_vista",label:"À Vista"},
  {value:"30_dias",label:"30 Dias"},
  {value:"ptp_business",label:"Pay to Pay Business Model",visible: true},
  {value:"outros",label:"Outros"}
]

const condiEntrega =[
  {value:"cif",label:"CIF"},
  {value:"fob",label:"FOB - Pato Branco/PR"}
]

  class inputsOpenProcess {
    constructor() {
        this.cliente_corrente;
        this.cod_sap;
        this.cnpj;
        this.nome_empresa;
        this.pais;
        this.cep;
        this.estado;
        this.cidade;
        this.segmento;
        this.nome_solic;
        this.email_solic;
        this.ddi_solic;
        this.tel_solic;
        this.cel_solic;
        this.nome_tec;
        this.ddi_tec;
        this.tel_tec;
        this.cel_tec;
        this.email_tec;
        this.nome_projeto;
        this.tipo_venda;
        this.idioma_proposta;
        this.moeda;
        this.neg_prev_forn;
        this.tipo_negocio;
        this.pais_fatura;
        this.uf_fatura_br;
        this.uf_fatura;
        this.autoriza_divulg;
        this.period_contrato;
        this.outro_periodo;
        this.prazo_pgto;
        this.outro_prazo_pgto;
        this.condi_entrega;
        this.obs_cenario;
        this.obs_neg_prev_fornc;
        this.prazo_pgto_fornec;
        this.moeda_invo_bom;
        this.valor_invo_bom;
        this.incoterm;
        this.pais_origem_fornec;
        this.qtde_envio;
        this.peso_envio;
        this.cubagem_envio;
        this.tratativa_import;
        this.qual;
        this.obs_consolid_fornec_indicado;
        this.pais_destino;
        this.estado_destino;
        this.incoterm_exportacao;
        this.certif_origem;
        this.seguro_exportacao;
        this.obs_exportacao;
        this.linkAnexos;
        this.obsDocumentos;
    }
  }

  export {
    inputsOpenProcess,
    MontagemPropostaContent,
    ComboOptions,
    EstadosContent,
    DdiPaisContent,
    PaisContent,
    ClienteContent,
    ComboOption,
    IMyData,
    IGetClient,
    tpVenda,
    optionsRevisaoModal,
    yesOrNot,
    optionsRevisao,
    optionsMotivoRevisao,
    segmento,
    idioma,
    moeda,
    tipoNegocio,
    periodContrato,
    incotermoptions,
    tratativaImport,
    seguroExportacao,
    prazoPgto,
    condiEntrega
  };