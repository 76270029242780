import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticatorService } from 'src/app/services/authenticator.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ConsultaDocumentosService {

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) { }

  apiUrl: any;

  async getDatas(filters?:any) {
    debugger
  
      this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
    
  
      const requestData = {
        url: this.apiUrl,
        method: 'POST'
      };
  
      const body = {
        name: "DTS_SOLICITACOES_CERT_ORIGEM",
        constraints:[{
          _field: "portal", 
          _initialValue: 'client', 
          _finalValue: 'client', 
          _type: 1
        }]
      };
  
      const httpOptions = this.authenticator.connect(requestData)
      
      return new Promise((resolvePromise) => {
        this.http.post(requestData.url, body, httpOptions).subscribe(
          (response: any) => {
            resolvePromise({
              response: this.addColumnStatus(response.content.values),
              totalHits: response.content.values.length
            });
          }
        );
      });
    }

    private addColumnStatus(valores:[]){
      valores.map((item:any) => {
        item['DATA'] = item.DATA == null ? '' : item.DATA.replaceAll('-','/')
        if(item.STATUS == 'aprovado_comex'){
          item['NUM_ATIV'] = 0 
          item['status'] = 'disabled'
        }else if(item.NUM_ATIV == 51){
          item['status'] = 'disabled'
        }else{
          item['status'] = 'onApproval'
        } 
      })
      return  valores
    }
}
