import { Component, ViewChild,ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import {AprovacaoService} from './aprovacao.service'
import { forkJoin } from 'rxjs';
import { SharedService } from '../../../shared/shared.service'
import translation from '../../../services/translation';
import { PoDialogService, PoInfoOrientation, PoNotificationService, PoTableColumn, PoTableComponent, PoToasterOrientation,PoModalComponent } from '@po-ui/ng-components';
import {  
  yesOrNot, 
  IMapColumnsAponta
} from './aprovacao.interface'

@Component({
  selector: 'app-aprovacao',
  templateUrl: './aprovacao.component.html',
  styleUrls: ['./aprovacao.component.css']
})
export class AprovacaoComponent {
  @ViewChild(PoModalComponent, { static: true }) poModal!: PoModalComponent;
  @ViewChild(PoTableComponent, { static: true }) poTable!: PoTableComponent;
  @ViewChild('top') topElement!: ElementRef;
  gridColumnsInvestimento:Array<PoTableColumn> = []
  gridColumnsPrecoVenda:Array<PoTableColumn> = []
  gridColumnsApontamento:Array<PoTableColumn> = []
  gridColumnsInfoAdicionais:Array<PoTableColumn> = []
  gridModalConsolidacao:Array<PoTableColumn> = []
  gridValuesCondComercial:any = []
  gridValuesCondEmbalagem:any = []
  gridValuesCondComplementares:any = []
  gridValuesCondAplicados:any = []
  gridValuesCondTecninca:any = []
  gridValuesInfoAdicionais: any = []
  itensSelectedListAlternativa: any = []
  gridColumnsListAlternativa:Array<PoTableColumn> = []
  yesOrNot = yesOrNot
  optionsRevisaoModal:any = []
  
  

  orientation = PoInfoOrientation.Horizontal;
  
  isDetail: boolean  = false;
  isLoading: boolean  = false;
  isReprovado!: boolean;
  showError: boolean = false;
  isRevisao: boolean = false;
  isReproved: boolean = false;
  proposta: string = ""
  revisao: string = ""
  cliente: string = ""
  contato: string = ""
  data: string = ""
  idioma_proposta!: string
  nomeProjeto: string = ""
  itensProposta: string = ""
  jsonProposta: string = ""
  ncm: string = ""
  tables: string = ""
  count:number = 2
  prcVendaQtdTotal:any = 0
  prcVendaVlrTotal:any = 0
  total: number = 0;
  
  itensListaTecnica :any
  nomeProduto!:string
  tipo_negocio!:string

  nome_gestor!:string
  pm_responsavel!:string
  nome_orcamentista!:string
  totalExpanded = 0;

  gridItemsListTecnica: any = [];
  gridItemsListAlternativa:any = []
  griValuesModalConsolidacao:any = []
  gridValuesPrecoVenda:any = []
  itens :any= []
  itensCabecalhoProposta :any= []

   // tradução dos componentes da página
   textPrezados!: string
   textProposta!: string
   textObjetoProposta!: string
   textCotacao!: string
   textPreco!: string
   textPrecosIndicados!: string
   textPrecoComposicao!: string
   textInvestimentoInicial!: string
   textCenarioProposta!: string
   textCondicoesTecnica!: string
   textTestesAplicados!: string
   textEmbalagem!: string
   textInfosComplementares!: string
   textInfosAdicionais!: string
   clienteText!: string
   contatoText!: string
   revisaoText!: string
   dataText!: string
   nomeProjetoText!: string
   textAdvice!: string
   textSubtitle!: string
   textSemMais!: string
   textOrcamentistaRespos!: string
   textGestorResp!: string
   textAtenciosamente!: string
   textRepresentante!: string
   textPropostaComercial!: string
   textBtnApprove!: string
   textBtnDisapprove!: string
   nextLabelWidget!: string
   previousLabelWidget!: string
   saveLabelWidget!: string 
   titleReprovar!: string 
   textTitleAprovAltern!: string 
   voltar!: string 
   btnConcluir!: string 
   msgError!: string 
   textBtnRevisar!: string 
   textModal!: string 
   titleRevisaoRFQ!: string 
   titleReprovRFQ!: string 
   textBtnFinalizar!: string 
   textBtnCancelar!: string 
   textQuantidadeTotal!: string 
   textTotalSelecionado!: string 
   textPrecoTotal!: string 
   campoJustificativa!: string 
   emailSolicitante!: string 
   numProcessoInput!: number 
   documentid!: number 


  constructor(
    private activatedRoute : ActivatedRoute,
    private service : AprovacaoService,
    private poDialog: PoDialogService,
    public notification: PoNotificationService,
    private router: Router,
    private sharedService : SharedService
     ) {}

  async ngOnInit(): Promise<void>{
    
    this.activatedRoute.paramMap.subscribe(params => {
       let id = params.get('id')!;
       [this.proposta, this.revisao] = id.split('-');
       
    });

    let proposta = this.service.cabecalhoProposta(this.proposta,this.revisao,'1')
    let form = this.service.dadosFormulario(this.proposta,this.revisao)
    let tecnica = this.service.listaTecnica(this.proposta,this.revisao)
    let alternativo = this.service.listaAlternativos(this.proposta,this.revisao)

    forkJoin([form,proposta]).subscribe(([dadosForm,dadosProposta] : any) => {
      let {
          nome_empresa,
          nome_solic,
          nome_projeto,
          emailSolicitante,
          data_abertura,
          itensProposta,
          jsonProposta,
          tipo_negocio,
          pm_responsavel,
          nome_orcamentista,
          nome_gestor,
          idioma_proposta,
          numProcessoInput, 
          documentid
        } = dadosForm.response[0]
      this.itensCabecalhoProposta = dadosProposta.response
 
      this.emailSolicitante = emailSolicitante
      this.cliente = nome_empresa
      this.contato = nome_solic
      this.nomeProjeto = nome_projeto
      this.data = data_abertura
      this.itensProposta = JSON.parse(itensProposta)
      this.jsonProposta = jsonProposta
      this.tipo_negocio = this.descricaoTPnegocio(tipo_negocio)
      this.numProcessoInput = numProcessoInput
      this.documentid = documentid
      this.nome_gestor = nome_gestor
      this.pm_responsavel = pm_responsavel
      this.nome_orcamentista = nome_orcamentista
      this.idioma_proposta = idioma_proposta
      this.setLanguage(idioma_proposta)
  })


    proposta = this.service.cabecalhoProposta(this.proposta,this.revisao,'0')

    forkJoin([proposta, tecnica, form, alternativo]).subscribe(([responseProposta, responseTecnica, propFormulario, alternativo]: any) => {


      const itensProposta = this.itensProposta;
      const jsonProposta = JSON.parse(this.jsonProposta).proposta;

      const listaTecnica = responseTecnica.response.map((item: {
        MAKTX1: any;
        MAKTX: string; ORIGMAT: string;
      }): any => {
        item.ORIGMAT = item.ORIGMAT == 'CLIENTE' ? 
        this.idioma_proposta == 'en'  ? 'Homologated' :'Homologado' 
          : 
          this.idioma_proposta == 'en'  ? 'Alternative' :'Alternativo';
        item.MAKTX = item.MAKTX == '' ? item.MAKTX1 : item.MAKTX;
        return item;
      });
      this.gridItemsListTecnica = listaTecnica;
      this.itensListaTecnica = [...new Set(listaTecnica.map((val: { ZITEM: any; }) => val.ZITEM)),];

      const listaAlternativos = alternativo.response.map((item: {
        MAKTX1: any;
        MAKTX: string; ORIGMAT: string;
      }): any => {
        item.ORIGMAT = item.ORIGMAT == 'CLIENTE' ? 
        this.idioma_proposta == 'en'  ? 'Homologated' :'Homologado'
        : 
        this.idioma_proposta == 'en'  ? 'Alternative' :'Alternativo';
        return item;
      });

      if(listaAlternativos.some((item:any) => item.ORIGMAT !=  'Homologado')){
        this.gridItemsListAlternativa = listaAlternativos
      }

      this.gridValuesCondComercial.map((item: {
        APONTA: string;
      }): any => {
        item.APONTA = responseProposta.response.map((itemProposta: { [x: string]: any; }): any => itemProposta[item.APONTA])[0];
        return item;
      });


      this.gridValuesCondAplicados.map((item: IMapColumnsAponta) => {
        item.APONTA = jsonProposta[item.APONTA];
        return item;
      });

      this.gridValuesCondEmbalagem.map((item: IMapColumnsAponta) => {
        item.APONTA = jsonProposta[item.APONTA];
        return item;
      });

      this.gridValuesCondTecninca.map((item: IMapColumnsAponta) => {
        item.APONTA = jsonProposta[item.APONTA];
        return item;
      });

      this.gridValuesCondComplementares.map((item: IMapColumnsAponta) => {
        item.APONTA = jsonProposta[item.APONTA];
        return item;
      });

      
      for (let proposta of itensProposta) {
        let { ID_CENARIO, ZITEM }: any = proposta;


        //Consulta Dataset DTS_ITEM_PROPOSTA
        let itensProposta = this.service.itensProposta(ZITEM, ID_CENARIO, this.proposta, this.revisao,this.idioma_proposta);
        //Consulta Dataset DTS_NRE_ITEM_PROPOSTA
        let nreItensProposta = this.service.nreItensProposta(ZITEM, ID_CENARIO, this.proposta, this.revisao);

        forkJoin([itensProposta, nreItensProposta]).toPromise().then(([proposta, nres]: any) => {
          proposta.response[0]['CENARIO'] = ID_CENARIO
          proposta.response[0]['INDEX'] = this.itens.length
          proposta.response[0]['ITSUM'] = this.gridItemsListTecnica.some((val: any) => {
            return val.MATNRRFQ === proposta.response[0].MATNRRFQ && val.ITSUM;
          });
          
          proposta.response[0]['detail'] = this.gridItemsListTecnica.filter((val: any) => val.MATNRRFQ == proposta.response[0].MATNRRFQ);
          this.gridValuesPrecoVenda.push(proposta.response[0]);
          let sum = nres.response.reduce((total:any, item:any) => total + parseFloat(item.PRUNI || '0'), 0);
          this.itens.push([
            nres.response, 
            proposta.response[0].MATNRRFQ, 
            proposta.response[0].TPPRD,
            nres.visible,
            sum,
            proposta.response[0].QUANT
          ]);
          this.ncm += proposta.response[0].STEUC != '' ? proposta.response[0].STEUC + ', ':'';
          if(nres.visible) this.tables += `1.${this.count++} , `;
          this.prcVendaVlrTotal += parseInt(proposta.response[0].ORVALUE);
          this.gridValuesInfoAdicionais[6].DESCRICAO = this.idioma_proposta == 'en'  ? `The NRE's mentioned in tables  ${this.tables} are not included in the price of the products. They will be charged separately. The investiments in table 1.5 must be purchased by Marelli directly from the manufacturer.` : `As NRE's citadas nas tabelas ${this.tables} não estão inclusas no preço dos produtos. Serão cobradas separadamente.`;
          
          if(this.ncm){
            if(this.gridValuesInfoAdicionais.length == 12){
              this.gridValuesInfoAdicionais.push({
                ID:"3.13",
                DESCRICAO: '',
              })
            }
            
            this.gridValuesInfoAdicionais[12].DESCRICAO = this.idioma_proposta == 'en'  ? `We offer NCM tax classification ${this.ncm} If you have a different rating that best identifies your product, please inform.` : `Ofertamos a classificação fiscal NCM ${this.ncm} caso possua uma classificação diferente que melhor identifique o seu produto, favor informar.`;
          }
          
        });
      }


    })   


    
}

onExpandDetail() {
  this.totalExpanded += 1;
}

    dadosItemListaTecnica(zitem:string){
      return this.gridItemsListTecnica.filter((val: { ZITEM: string; }) => val.ZITEM == zitem)
    }    

    dadosItemListaTecnicaDescricao(zitem:string){
      return this.gridItemsListTecnica.find((val: { ZITEM: string; }) => val.ZITEM == zitem).MATNRRFQ
    }    

    descricaoTPnegocio(tipoNegocio:string){
      const options:any = {
        full_turn_key:"Full Turn key",
        ckd:"CKD em turn key",
        remessa_indus:"Remessa para Industr. (Consignado)",
        skd:"SKD",
        consum_final_turn_key:"Consumidor Final em Turn Key",
        consum_final_consig:"Consumidor Final consignado",
      }
      return options[tipoNegocio]
    }

  canActiveNextStep(form: NgForm) {
    return form.valid;
  }

  save(){
    this.isRevisao = false
    this.isReproved = false

    if(this.gridValuesPrecoVenda.some((item:any) => item.STATUS == "review")) this.isRevisao = true
    if(this.gridValuesPrecoVenda.some((item:any) => item.STATUS == "disapproved")) this.isReproved = true
    


    if(this.isReprovado == undefined){
      const msg = this.idioma_proposta == 'en'  ? "To complete, the field ‘Approval NRE and commercial conditions’ must be filled in." : 'Para concluir deve ser preenchido o campo "Aprovação NRE e condições comerciais"';
      this.notification.error({ 
        message: msg,
        duration: 3000,
        orientation: PoToasterOrientation.Top
      });
      this.showError = true
      return
    }

    this.showError = false

    if(this.gridValuesPrecoVenda.some((item:any) => item.STATUS == "onApproval")){
      const msg = this.idioma_proposta == 'en'  ? "To proceed, all items must be Approved or Disapproved." : 'Para avançar todos os itens deve ser Aprovados ou Reprovados.';
      this.notification.error({ 
        message: msg,
        duration: 3000,
        orientation: PoToasterOrientation.Top
      });
      this.scrollTop()
      return
    }

    this.griValuesModalConsolidacao = []
    this.gridValuesPrecoVenda.forEach((item:any) => {
      this.griValuesModalConsolidacao.push({
        'MATNRRFQ':item.MATNRRFQ,
        'TPPRD':item.TPPRD,
        'QUANT':item.QUANT,
        'STATUS':item.STATUS,
        'NREAPROVA':!this.isReprovado ? 'aprovado' : 'reprovado'
      })
    })
    
    this.poModal.open();
  }

  scrollTop(){
    this.topElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  aprovadoOrReprovado(value: string){
      this.isReprovado = value == 'sim' ? false : true
  }

  selectListAlternativa(row: any) {
    this.itensSelectedListAlternativa.push(row)
  }
  selectLine(row: any) {
    if (row.ORVALUE) {
      this.total += parseInt(row.PRTTL);
      this.prcVendaQtdTotal += parseInt(row.QUANT);
      this.poTable.expand(row.INDEX)
      let isSumarizado = false


      this.gridValuesPrecoVenda.forEach((val: any) => {
        if (val.detail.some((item: any) => item.ITSUM == true)) {
          isSumarizado = true
          val.$selected = true;
          val.detail.forEach((details:any) =>{
            details['$selected'] = true
          })
        



          if(row.INDEX != val.INDEX){
            this.total += parseInt(val.PRTTL);
            this.prcVendaQtdTotal += parseInt(val.QUANT);
          }
          
        }
      });

      if(isSumarizado){
        const msg = this.idioma_proposta == "pt" ? `Os itens restantes foram selecionados porque pertencem a um grupo.` : `The remaining items were selected because they belong to a group.` 
        this.notification.warning({ 
          message: msg,
          duration: 3000,
          orientation: PoToasterOrientation.Top
        });
      }
   
    }
  }

  decreaseLine(row: any) {
    if (row.ORVALUE) {
      this.total -= parseInt(row.PRTTL);
      this.prcVendaQtdTotal -= parseInt(row.QUANT);
      let isSumarizado = false


      this.gridValuesPrecoVenda.forEach((val: any) => {
        if (val.detail.some((item: any) => item.ITSUM == true)) {
          isSumarizado = false
          val.$selected = false;
          val.detail.forEach((details:any) =>{
                details['$selected'] = false
        })

          if(row.INDEX != val.INDEX){
            this.total -= parseInt(val.PRTTL);
            this.prcVendaQtdTotal -= parseInt(val.QUANT);
          }
        }
      });

      if(isSumarizado){
        const msg = this.idioma_proposta == "pt" ? `Os itens restantes foram desmarcado pois pertencem a um grupo.` : `The remaining items were unchecked because they belong to a group.` 
        this.notification.warning({ 
          message: msg,
          duration: 3000,
          orientation: PoToasterOrientation.Top
        });
      }
    }
  }

  decreaseLineAll(row: any) {
    this.total = 0
    this.prcVendaQtdTotal = 0

    this.gridValuesPrecoVenda.forEach((val : any) => {

      if (val.detail) {
        val.detail.forEach((detailItem : any) => {
          detailItem.$selected = false;
        });
      }
    });
    
  }

  selectLineAllAlternativa(row: any){
    row.forEach((itens:any) =>{ 
      this.itensSelectedListAlternativa.push(itens)
    })
    
  }

  decreaseLineAllAlternativa(row: any){
    this.itensSelectedListAlternativa = []
  }

  selectLineAll(row: any) {
    this.total = 0
    this.prcVendaQtdTotal = 0

    this.gridValuesPrecoVenda.forEach((val : any) => {
      this.total += parseInt(val.PRTTL);
      this.prcVendaQtdTotal += parseInt(val.QUANT);
      val.$selected = true
      
      if (val.detail) {
        val.detail.forEach((detailItem : any) => {
          detailItem.$selected = true;
        });
      }
    });
  }

  approveItems(value: string) {
    let selectedItems: any;
    
    const itensNaoSelecionados:any = []
    if(value.indexOf('Alternativos') != -1){
      selectedItems = this.itensSelectedListAlternativa
      this.itensSelectedListAlternativa = []
    }else{
      selectedItems = this.poTable.getSelectedRows()
    }

    const msg = this.idioma_proposta == "pt" ? `Para ${value == 'disapproved' ? 'reprovar': 'aprovar'}, é necessário selecionar os itens desejados.` : `To ${value == 'disapproved' ? 'disapprove': 'approve'}, it is necessary to select the desired items.` 

    if(selectedItems.length == 0){
      this.notification.error({ 
        message: msg,
        duration: 3000,
        orientation: PoToasterOrientation.Top
      });
      return
    }


    if (value == 'disapproved') {
      
      const msg = this.idioma_proposta == "pt" ? `Deseja reprovar 
        ${selectedItems.length > 1 ? 'os' : 'o'}  
        ${selectedItems.length > 1 ? 'itens' : 'item'}  
          <strong>
            ${selectedItems.map((val:any) => {
              return val.MATNRRFQ + ' '
            }).toString()}
          </strong> ?` : 
          `Do you want to disapprove 
          ${selectedItems.length > 1 ? 'the' : 'the'}  
          ${selectedItems.length > 1 ? 'items' : 'item'}  
          <strong>
            ${selectedItems.map((val:any) => {
              return val.MATNRRFQ + ' '
            }).toString()}
          </strong> ?`
      this.poDialog.confirm({
        title: this.titleReprovar,
        message: msg,
        confirm: () => {
          selectedItems.map((val:any) => {
            val.STATUS = 'disapproved'
          })

          
          this.poTable.unselectRows()
          this.prcVendaQtdTotal = 0
          this.total = 0;
        },
        cancel: () => {}
      });
    }else if(value == 'disapprovedAlternativos'){
      selectedItems.map((val:any) => {
        val.STATUS = 'disapproved'
        val.$selected = false;
      })
    }else if(value == 'toApproveAlternativos'){
      selectedItems.map((val:any) => {
        val.STATUS = 'approved';
        val.$selected = false;
      });
    }else if(value == "review"){
      selectedItems.map((val:any) => {
        val.STATUS = value;
      });

      this.poTable.unselectRows()
      this.prcVendaQtdTotal = 0
      this.total = 0;
    }else{
      selectedItems.forEach((item:any) => {
        if(item.$selected){
            item.detail.forEach((detail:any) =>{
                if(!detail.$selected){
                    if(!itensNaoSelecionados.includes(detail.MFRPN)) itensNaoSelecionados.push(detail.MFRPN)
                }
            })
        }
      })
  
      if(itensNaoSelecionados.length > 0){
        const message = this.idioma_proposta == "pt" ? 
          itensNaoSelecionados.length == 1   ? 
            `O produto ${itensNaoSelecionados} não pode ser aprovado sem a aprovação da lista técnica` 
            : 
            `Os produtos ${itensNaoSelecionados} não podem ser aprovados sem a aprovação das listas técnicas.` 
            : 
            itensNaoSelecionados.length == 1   ? 
            `The product ${itensNaoSelecionados} cannot be approved without the approval of the technical list.` 
            : 
            `The products ${itensNaoSelecionados} cannot be approved without the approval of the technical lists.`
  
        this.notification.error({ 
          message,
          duration: 3000,
          orientation: PoToasterOrientation.Top
        });
        return
      }
      
      selectedItems.map((val:any) => {
        val.STATUS = 'approved';
      });

      this.poTable.unselectRows()
      this.prcVendaQtdTotal = 0
      this.total = 0;
    }
  }

  setLanguage(idioma:string) {
    this.clienteText =  (translation.cliente.values as any)[`${idioma}`];
    this.contatoText =  (translation.contato.values as any)[`${idioma}`];
    this.revisaoText =  (translation.revisao.values as any)[`${idioma}`];
    this.dataText =  (translation.data.values as any)[`${idioma}`];
    this.nomeProjetoText =  (translation.nomeProjeto.values as any)[`${idioma}`];
    this.voltar =  (translation.textBtnVoltar.values as any)[`${idioma}`];
    this.btnConcluir =  (translation.btnAprovar.values as any)[`${idioma}`];
    this.msgError =  (translation.msgError.values as any)[`${idioma}`];
    this.textBtnRevisar =  (translation.textBtnRevisar.values as any)[`${idioma}`];
    this.textModal =  (translation.textModal.values as any)[`${idioma}`];
    this.titleRevisaoRFQ =  (translation.titleRevisaoRFQ.values as any)[`${idioma}`];
    this.titleReprovRFQ =  (translation.titleReprovRFQ.values as any)[`${idioma}`];
    this.textBtnFinalizar =  (translation.textBtnFinalizar.values as any)[`${idioma}`];
    this.textBtnCancelar =  (translation.textBtnCancelar.values as any)[`${idioma}`];
    this.textQuantidadeTotal =  (translation.textQuantidadeTotal.values as any)[`${idioma}`];
    this.textTotalSelecionado =  (translation.textTotalSelecionado.values as any)[`${idioma}`];
    this.textPrecoTotal =  (translation.textPrecoTotal.values as any)[`${idioma}`];
    this.campoJustificativa =  (translation.campoJustificativa.values as any)[`${idioma}`];


    this.textTitleAprovAltern = (translation.textTitleAprovAltern.values as any)[`${idioma}`];
    this.textProposta = (translation.textProposta.values as any)[`${idioma}`];
    this.textObjetoProposta = (translation.textObjetoProposta.values as any)[`${idioma}`];
    this.textCotacao = (translation.textCotacao.values as any)[`${idioma}`];
    this.textPreco = (translation.textPreco.values as any)[`${idioma}`];
    this.textPrecosIndicados = (translation.textPrecosIndicados.values as any)[`${idioma}`];
    this.textPrecoComposicao = (translation.textPrecoComposicao.values as any)[`${idioma}`];
    this.textInvestimentoInicial = (translation.textInvestimentoInicial.values as any)[`${idioma}`];
    this.textCenarioProposta = (translation.textCenarioProposta.values as any)[`${idioma}`];
    this.textCondicoesTecnica = (translation.textCondicoesTecnica.values as any)[`${idioma}`];
    this.textTestesAplicados = (translation.textTestesAplicados.values as any)[`${idioma}`];
    this.textEmbalagem = (translation.textEmbalagem.values as any)[`${idioma}`];
    this.textInfosComplementares = (translation.textInfosComplementares.values as any)[`${idioma}`];
    this.textInfosAdicionais = (translation.textInfosAdicionais.values as any)[`${idioma}`];
    
    this.textAdvice = (translation.textAdvice.values as any)[`${idioma}`];
    this.textSubtitle = (translation.textSubtitle.values as any)[`${idioma}`];
    this.textSemMais = (translation.textSemMais.values as any)[`${idioma}`];
    this.textAtenciosamente = (translation.textAtenciosamente.values as any)[`${idioma}`];
    this.textOrcamentistaRespos = (translation.textOrcamentistaRespos.values as any)[`${idioma}`];
    this.textGestorResp = (translation.textGestorResp.values as any)[`${idioma}`];
    this.textRepresentante = (translation.textRepresentante.values as any)[`${idioma}`];
    this.textPropostaComercial = (translation.textPropostaComercial.values as any)[`${idioma}`];
    this.textBtnApprove = (translation.textBtnApprove.values as any)[`${idioma}`];
    this.textBtnDisapprove = (translation.textBtnDisapprove.values as any)[`${idioma}`];
    this.nextLabelWidget = (translation.textBtnAvancar.values as any)[`${idioma}`];
    this.previousLabelWidget = (translation.textBtnVoltar.values as any)[`${idioma}`];
    this.saveLabelWidget = (translation.textBtnSalvar.values as any)[`${idioma}`];
    this.titleReprovar = (translation.titleReprovar.values as any)[`${idioma}`];
    
    this.gridColumnsPrecoVenda = [ 
      {
        property: 'STATUS',
        type: 'label',
        labels: [
          { value: 'onApproval', color: 'color-08', label: `${(translation.tableTexts.aprovando as any)[`${idioma}`]}` },
          { value: 'approved', color: 'color-11', label: `${(translation.tableTexts.aprovado as any)[`${idioma}`]}` },
          { value: 'disapproved', color: 'color-07', label: `${(translation.tableTexts.reprovador as any)[`${idioma}`]}` },
          { value: 'review', color: 'rgba(232,117,8, 0.8)', label: `${(translation.tableTexts.revisar as any)[`${idioma}`]}` }
        ]
      },
      { label: 'Item', property: 'ITEM',sortable: true },
      { label: 'NCM', property: 'STEUC'},
      { label: `${(translation.tableTexts.descriptionProduc as any)[`${idioma}`]}`, property: 'MATNRRFQ' },
      { label: `${(translation.tableTexts.qtd as any)[`${idioma}`]}`, property: 'QUANT' },
      { label: `${(translation.tableTexts.precUnit as any)[`${idioma}`]}`, property: 'PRUNIT', type: 'currency', format: `${idioma == 'en' ? 'USD': 'BRL'}`,},
      { label: 'IGPM (%)', property: 'IGPMP',type: 'number' },
      { label: 'IGPM', property: 'IGPMVLR',type: 'currency', format: `${idioma == 'en' ? 'USD': 'BRL'}`, },
      { label: `${(translation.tableTexts.cambio as any)[`${idioma}`]} (%)`, property: 'CAMBP',type: 'number'},
      { label: `${(translation.tableTexts.cambio as any)[`${idioma}`]}`, property: 'CAMBVLR',type: 'currency', format: `${idioma == 'en' ? 'USD': 'BRL'}`, },
      { label: `${(translation.tableTexts.precTotal as any)[`${idioma}`]}`, property: 'PRTTL',type: 'currency', format: `${idioma == 'en' ? 'USD': 'BRL'}`, },
      { label: `${(translation.tableTexts.icms as any)[`${idioma}`]}`, property: 'ICMSP',type: 'number'},
      { label: `${(translation.tableTexts.ipi as any)[`${idioma}`]}`, property: 'IPIP',type: 'number'},
      { label: `${(translation.tableTexts.delivery as any)[`${idioma}`]}`, property: 'PRZENT' },
      { label: `${(translation.tableTexts.pricNet as any)[`${idioma}`]}`, type: 'currency', format: `${idioma == 'en' ? 'USD': 'BRL'}`, property: 'PRNET' },
      { label: `${(translation.tableTexts.tipProd as any)[`${idioma}`]}`, property: 'TPPRD' },
      { label: `${(translation.tableTexts.obs as any)[`${idioma}`]}`, property: 'OBSER' },
      { property: 'detail', label: 'Details', type: 'detail', detail:  {
        columns: [
          { label: 'Reference Code', property: 'REFCOD'},
          { label: 'PartNumber	', property: 'MFRPN'},
          { label: 'Status', property: `ORIGMAT`},
          { label: `${(translation.tableTexts.description as any)[`${idioma}`]}`, property: 'MAKTX'},
          { label: 'Manufacturer', property: 'MFRNR'}
        ]
      ,
        typeHeader: 'top'
      } },
      { label: 'Index', property: 'INDEX',visible:false },
      { label: 'Cenario', property: 'CENARIO',visible:false },
      { label: 'ITEMOK', property: 'ITEMOK',visible:false },
      { label: 'Sumarizado', property: 'ITSUM',type:'boolean' },
    ]

    this.gridColumnsInvestimento = [
      { label: `Item`, property: 'ITEM'},
      { label: `${(translation.tableTexts.description as any)[`${idioma}`]}`, property: 'NRE_ITEM'},
      { label: `${(translation.tableTexts.qtd as any)[`${idioma}`]}`, property: 'QUANT'},
      { label: `${(translation.tableTexts.precUnit as any)[`${idioma}`]}`, property: 'PRUNI', type: 'currency', format: `${idioma == 'en' ? 'USD': 'BRL'}`},
      { label: `${(translation.tableTexts.precTotal as any)[`${idioma}`]}`, property: 'PRTTL', type: 'currency', format: `${idioma == 'en' ? 'USD': 'BRL'}`},
      { label: `${(translation.tableTexts.obs as any)[`${idioma}`]}`, property: 'OBS'},
    ]

    this.gridColumnsApontamento = [
      { label: 'Item', property: 'ID',width: "9%"},
      { label: `${(translation.tableTexts.description as any)[`${idioma}`]}`, property: 'DESCRICAO',width: "50%"},
      { label: `${(translation.tableTexts.appoints as any)[`${idioma}`]}`, property: 'APONTA'},
    ]

    this.gridColumnsInfoAdicionais = [
      { label: 'Item', property: 'ID'},
      { label: `${(translation.tableTexts.description as any)[`${idioma}`]}`, property: 'DESCRICAO'}
    ]

    this.gridValuesCondComercial =  [{
          "ID":"2.1.1",
          "DESCRICAO": `${(translation.tableTexts.fabril as any)[`${idioma}`]}`,
          "APONTA":"STFBL_DESCR"
      },{
          "ID":"2.1.2",
          "DESCRICAO": `${(translation.tableTexts.periodocontrato as any)[`${idioma}`]}`,
          "APONTA":"PCONTR"
      },{
          "ID":"2.1.3",
          "DESCRICAO": `${(translation.tableTexts.prazoPagamento as any)[`${idioma}`]}`,
          "APONTA":"PRZPG"
      },{
          "ID":"2.1.4",
          "DESCRICAO": `${(translation.tableTexts.condEntrega as any)[`${idioma}`]}`,
          "APONTA":"CDENTR"
      },{
          "ID":"2.1.5",
          "DESCRICAO": `${(translation.tableTexts.cenarioTribut as any)[`${idioma}`]}`,
          "APONTA":"CTRIBV_DESCR"
      },{
          "ID":"2.1.6",
          "DESCRICAO": `${(translation.tableTexts.estatadoFedera as any)[`${idioma}`]}`,
          "APONTA":"EFFAT"
      },{
          "ID":"2.1.7",
          "DESCRICAO": `${(translation.tableTexts.paisDestino as any)[`${idioma}`]}`,
          "APONTA":"PSDRAW"
      },{
          "ID":"2.1.8",
          "DESCRICAO": `${(translation.tableTexts.ppb as any)[`${idioma}`]}`,
          "APONTA":"PPB_DESCR"
      },{
          "ID":"2.1.9",
          "DESCRICAO": `${(translation.tableTexts.portaria as any)[`${idioma}`]}`,
          "APONTA":"PORT_DESCR"
      },{
          "ID":"2.1.10",
          "DESCRICAO": `${(translation.tableTexts.clienteIPI as any)[`${idioma}`]}`,
          "APONTA":"CISEN_DESCR"
      },{
          "ID":"2.1.11",
          "DESCRICAO": `${(translation.tableTexts.garantiaFabric as any)[`${idioma}`]}`,
          "APONTA":"GDEFFAB_DESCR"
      },{
          "ID":"2.1.12",
          "DESCRICAO": `${(translation.tableTexts.amortizaInves as any)[`${idioma}`]}`,
          "APONTA":"AMOR_DESCR"
      },{
          "ID":"2.1.13",
          "DESCRICAO": `${(translation.tableTexts.consideradoItens as any)[`${idioma}`]}`,
          "APONTA":"CALTER_DESCR"
      },{
          "ID":"2.1.14",
          "DESCRICAO": `${(translation.tableTexts.itensSujeto as any)[`${idioma}`]}`,
          "APONTA":"IMOQ_DESCR"
      },{
          "ID":"2.1.15",
          "DESCRICAO": `${(translation.tableTexts.cambioBase as any)[`${idioma}`]}`,
          "APONTA":"UKURS"
      },{
          "ID":"2.1.16",
          "DESCRICAO": `${(translation.tableTexts.ptaxFaturamento as any)[`${idioma}`]}`,
          "APONTA":"PTAXF"
      },{
          "ID":"2.1.17",
          "DESCRICAO": `${(translation.tableTexts.validadeProposta as any)[`${idioma}`]}`,
          "APONTA":"VALPR_DESCR"
      }]

    this.gridValuesCondEmbalagem =  [{
      "ID":"2.4.1",
      "DESCRICAO": `${(translation.tableTexts.embalagem as any)[`${idioma}`]}`,
      "APONTA":"EMBUNIT_DESCR"
    },{
      "ID":"2.4.2",
      "DESCRICAO": `${(translation.tableTexts.embalagemColetiva as any)[`${idioma}`]}`,
      "APONTA":"EMBCOL_DESCR"
    },{
      "ID":"2.4.3",
      "DESCRICAO": `${(translation.tableTexts.armazenamento as any)[`${idioma}`]}`,
      "APONTA":"PALLET_DESCR"
    },{
      "ID":"2.4.4",
      "DESCRICAO": `${(translation.tableTexts.etiquetas as any)[`${idioma}`]}`,
      "APONTA":"ETIQ_DESCR"
    }]

    this.gridValuesCondComplementares =  [{
      "ID":"2.5.1",
      "DESCRICAO":`${(translation.tableTexts.itensCotados as any)[`${idioma}`]}`,
      "APONTA":"ICBRO"
    },{
      "ID":"2.5.2",
      "DESCRICAO":`${(translation.tableTexts.relacaoItens as any)[`${idioma}`]}`,
      "APONTA":"IFORCL"
    },{
      "ID":"2.5.3",
      "DESCRICAO":`${(translation.tableTexts.relacaoItensNOrcados as any)[`${idioma}`]}`,
      "APONTA":"INORC"
    },{
      "ID":"2.5.4",
      "DESCRICAO": `${(translation.tableTexts.itensNegociadoCliForne as any)[`${idioma}`]}`,
      "APONTA":"INEGCF"
    }]

    this.gridValuesCondAplicados =  [{
      "ID":"2.3.1",
      "DESCRICAO": `${(translation.tableTexts.firmware as any)[`${idioma}`]}`,
      "APONTA":"FIRMWARE_DESCR"
    },{
      "ID":"2.3.2",
      "DESCRICAO": `${(translation.tableTexts.responsTestes as any)[`${idioma}`]}`,
      "APONTA":"RTESTE_DESCR"
    },{
      "ID":"2.3.3",
      "DESCRICAO": `${(translation.tableTexts.testesMda as any)[`${idioma}`]}`,
      "APONTA":"TMDA_DESCR"
    },{
      "ID":"2.3.4",
      "DESCRICAO": `${(translation.tableTexts.testeIct as any)[`${idioma}`]}`,
      "APONTA":"TICT_DESCR"
    },{
      "ID":"2.3.5",
      "DESCRICAO": `${(translation.tableTexts.testeFCT as any)[`${idioma}`]}`,
      "APONTA":"TFCT_DESCR"
    },{
      "ID":"2.3.6",
      "DESCRICAO": `${(translation.tableTexts.testeBurn as any)[`${idioma}`]}`,
      "APONTA":"TBURN_DESCR"
    },{
      "ID":"2.3.7",
      "DESCRICAO": `${(translation.tableTexts.testeRun as any)[`${idioma}`]}`,
      "APONTA":"TRUN_DESCR"
    },{
      "ID":"2.3.8",
      "DESCRICAO": `${(translation.tableTexts.jigaTeste as any)[`${idioma}`]}`,
      "APONTA":"TJIGAS_DESCR"
    },{
      "ID":"2.3.9",
      "DESCRICAO": `${(translation.tableTexts.vidaUtil as any)[`${idioma}`]}`,
      "APONTA":"VIDAU_DESCR"
    }]

    this.gridValuesCondTecninca =  [{
      "ID":"2.2.1",
      "DESCRICAO": "ACNP",
      "APONTA":"ACNP"
  },{
      "ID":"2.2.2",
      "DESCRICAO": `${(translation.tableTexts.especifSoldabili as any)[`${idioma}`]}`,
      "APONTA":"SOLDA_DESCR"
  },{
      "ID":"2.2.3",
      "DESCRICAO": `${(translation.tableTexts.classificIPC as any)[`${idioma}`]}`,
      "APONTA":"NIPC_DESCR"
  },{
      "ID":"2.2.4",
      "DESCRICAO": `${(translation.tableTexts.bom as any)[`${idioma}`]}`,
      "APONTA":"FBOM_DESCR"
  },{
      "ID":"2.2.5",
      "DESCRICAO": `${(translation.tableTexts.arquivoGeber as any)[`${idioma}`]}`,
      "APONTA":"GERBER_DESCR"
  },{
      "ID":"2.2.6",
      "DESCRICAO": `${(translation.tableTexts.amostraFotos as any)[`${idioma}`]}`,
      "APONTA":"AMOS_DESCR"
  },{
      "ID":"2.2.7",
      "DESCRICAO": `${(translation.tableTexts.instrucoesMontagem as any)[`${idioma}`]}`,
      "APONTA":"IT_DESCR"
  },{
      "ID":"2.2.8",
      "DESCRICAO": `${(translation.tableTexts.inspecao as any)[`${idioma}`]}`,
      "APONTA":"INSP_DESCR"
  },{
      "ID":"2.2.9",
      "DESCRICAO": `${(translation.tableTexts.necessidadeTreinamento as any)[`${idioma}`]}`,
      "APONTA":"TREINA_DESCR"
  },{
      "ID":"2.2.10",
      "DESCRICAO": `${(translation.tableTexts.requisitoAmbiental as any)[`${idioma}`]}`,
      "APONTA":"RAMBI_DESCR"
  },{
      "ID":"2.2.11",
      "DESCRICAO": `${(translation.tableTexts.qualityPlan as any)[`${idioma}`]}`,
      "APONTA":"QPLAN_DESCR"
  },{
      "ID":"2.2.12",
      "DESCRICAO":  `${(translation.tableTexts.pfmea as any)[`${idioma}`]}`,
      "APONTA":"PFMEA_DESCR"
  },{
      "ID":"2.2.13",
      "DESCRICAO": `${(translation.tableTexts.ppap as any)[`${idioma}`]}`,
      "APONTA":"PPAP_DESCR"
  },{
      "ID":"2.2.14",
      "DESCRICAO": `${(translation.tableTexts.criterioRastreabilidade as any)[`${idioma}`]}`,
      "APONTA":"CRASTR_DESCR"
  },{
      "ID":"2.2.15",
      "DESCRICAO": `${(translation.tableTexts.certificacoes as any)[`${idioma}`]}`,
      "APONTA":"CERTI_DESCR"
  },{
      "ID":"2.2.16",
      "DESCRICAO": `${(translation.tableTexts.tipoVerniz as any)[`${idioma}`]}`,
      "APONTA":"TVERNIZ_DESCR"
  },{
    "ID":"2.2.17",
    "DESCRICAO": `${(translation.tableTexts.planoRma as any)[`${idioma}`]}`,
    "APONTA":"RMA_DESCR"
  },{
    "ID":"2.2.18",
    "DESCRICAO": `${(translation.tableTexts.criterioCosmeticos as any)[`${idioma}`]}`,
    "APONTA":"COSMET_DESCR"
  },{
    "ID":"2.2.19",
    "DESCRICAO": `${(translation.tableTexts.caracteristicas14949 as any)[`${idioma}`]}`,
    "APONTA":"IATF_DESCR"
  },{
    "ID":"2.2.20",
    "DESCRICAO": `${(translation.tableTexts.caracteristicas13485 as any)[`${idioma}`]}`,
    "APONTA":"ISO_DESCR"
  },{
    "ID":"2.2.21",
    "DESCRICAO": `${(translation.tableTexts.acuracia as any)[`${idioma}`]}`,
    "APONTA":"AORC_DESCR"
  }]

  
  this.gridColumnsInfoAdicionais=  [
    { label:"Item", property: 'ID'},
    { label:"Descricao", property: 'DESCRICAO' }
]

  this.gridValuesInfoAdicionais = [{
      ID:"3.1",
      DESCRICAO: `${(translation.textgridValuesInfoAdicionais.values as any)[`${idioma}`]}`,
    },{
      ID:"3.2",
      DESCRICAO: `${(translation.tableTexts.naoConsideraEstoque as any)[`${idioma}`]}`,
    },{
      ID:"3.3",
      DESCRICAO: `${(translation.tableTexts.propostaContemplaBom as any)[`${idioma}`]}`,
    },{
      ID:"3.4",
      DESCRICAO: `${(translation.tableTexts.propostaContemplaMOQ as any)[`${idioma}`]}`,
    },{
      ID:"3.5",
      DESCRICAO: `${(translation.tableTexts.cenarioPrecoVenda as any)[`${idioma}`]}`,
    },{
      ID:"3.6",
      DESCRICAO: `${(translation.tableTexts.jigaFixture as any)[`${idioma}`]}`,
    },{
      ID:"3.7",
      DESCRICAO: `${(translation.tableTexts.nreCitadas as any)[`${idioma}`]}`,
    },{
      ID:"3.8",
      DESCRICAO: `${(translation.tableTexts.aliquotasImpostos as any)[`${idioma}`]}`,
    },{
      ID:"3.9",
      DESCRICAO: `${(translation.tableTexts.itensConstantes as any)[`${idioma}`]}`,
    },{
      ID:"3.10",
      DESCRICAO: `${(translation.tableTexts.industriasComponentes as any)[`${idioma}`]}`,
    },{
      ID:"3.11",
      DESCRICAO: `${(translation.tableTexts.garantiaManufatura as any)[`${idioma}`]}`,
    },{
      ID:"3.12",
      DESCRICAO: `${(translation.tableTexts.pedidoCompra as any)[`${idioma}`]}`,
    }]

     

  this.gridColumnsListAlternativa = [
    {
      property: 'STATUS',
      type: 'label',
      labels: [
        { value: 'onApproval', color: 'color-08', label: `${(translation.tableTexts.aprovando as any)[`${idioma}`]}` },
        { value: 'approved', color: 'color-11', label: `${(translation.tableTexts.aprovado as any)[`${idioma}`]}` },
        { value: 'disapproved', color: 'color-07', label: `${(translation.tableTexts.reprovador as any)[`${idioma}`]}` }
      ]
    },
    { label: 'Reference Code', property: 'REFCOD'},
    { label: 'Origem', property: `ORIGMAT`},
    { label: `${(translation.tableTexts.description as any)[`${idioma}`]}`, property: 'MAKTX'},
    { label: 'PartNumber	', property: 'MFRPN'},
    { label: 'Manufacturer', property: 'MFRNR'}
  ] 

  this.gridModalConsolidacao = [
    { label: `${(translation.tableTexts.description as any)[`${idioma}`]}`, property: 'MATNRRFQ'},
    { label: `${(translation.tableTexts.tipProd as any)[`${idioma}`]}`, property: `TPPRD`},
    { label: `${(translation.tableTexts.qtd as any)[`${idioma}`]}`, property: 'QUANT'},
    {
      property: 'STATUS',
      label:`${(translation.tableTexts.aprovacao as any)[`${idioma}`]}`,
      type: 'label',
      labels: [
        { value: 'approved', label: `${(translation.tableTexts.aprovado as any)[`${idioma}`]}`,icon: 'po-icon-ok' },
        { value: 'disapproved', label: `${(translation.tableTexts.reprovador as any)[`${idioma}`]}`,icon: 'po-icon-close' },
        { value: 'review', color: 'rgba(232,117,8, 0.8)', label: `${(translation.tableTexts.revisar as any)[`${idioma}`]}` }
      ]
    },
    { 
      property: 'NREAPROVA',
      label: 'NRE', 
      type: 'label', 
      labels: [
      { value: 'aprovado', label: `${(translation.tableTexts.aprovado as any)[`${idioma}`]}`,icon: 'po-icon-ok' },
      { value: 'reprovado', label: `${(translation.tableTexts.reprovador as any)[`${idioma}`]}`,icon: 'po-icon-close' }
    ]},
    { 
      label: 'BOM', 
      property: 'STATUS',
      type: 'label', 
      labels: [
      { value: 'approved', label: `${(translation.tableTexts.aprovado as any)[`${idioma}`]}`,icon: 'po-icon-ok' },
      { value: 'disapproved', label: `${(translation.tableTexts.reprovador as any)[`${idioma}`]}`,icon: 'po-icon-close' },
      { value: 'review', color: 'rgba(232,117,8, 0.8)', label: `${(translation.tableTexts.revisar as any)[`${idioma}`]}` }
    ]}
  ]

  this.optionsRevisaoModal = [
    { value:'modal_rev_quantidade', label:`${(translation.tableTexts.modal_rev_quantidade as any)[`${idioma}`]}`},
    { value:'modal_rev_prazo_entreg',label: `${(translation.tableTexts.modal_rev_prazo_entreg as any)[`${idioma}`]}`},
    { value:'modal_rev_nre',label: `${(translation.tableTexts.modal_rev_nre as any)[`${idioma}`]}`},
    { value:'modal_rev_projeto',label: `${(translation.tableTexts.modal_rev_projeto as any)[`${idioma}`]}`},
    { value:'modal_rev_preco',label: `${(translation.tableTexts.modal_rev_preco as any)[`${idioma}`]}`},
    { value:'modal_rev_exportacao',label: `${(translation.tableTexts.modal_rev_exportacao as any)[`${idioma}`]}`},
    { value:'modal_rev_geral_preco',label: `${(translation.tableTexts.modal_rev_geral_preco as any)[`${idioma}`]}`},
    { value:'modal_rev_inclu_novos_prod',label: `${(translation.tableTexts.modal_rev_inclu_novos_prod as any)[`${idioma}`]}`},
    { value:'modal_rev_process_export',label: `${(translation.tableTexts.modal_rev_process_export as any)[`${idioma}`]}`},
    { value:'modal_rev_condi_entrega',label: `${(translation.tableTexts.modal_rev_condi_entrega as any)[`${idioma}`]}`},
    { value:'modal_rev_inclu_novos_cenarios',label: `${(translation.tableTexts.modal_rev_inclu_novos_cenarios as any)[`${idioma}`]}`},
    { value:'modal_rev_alternativos',label: `${(translation.tableTexts.modal_rev_alternativos as any)[`${idioma}`]}`},
    { value:'modal_rev_prazo_pgto',label: `${(translation.tableTexts.modal_rev_prazo_pgto as any)[`${idioma}`]}`},
    { value:'modal_rev_processo',label: `${(translation.tableTexts.modal_rev_processo as any)[`${idioma}`]}`},
    { value:'modal_rev_outros',label: `${(translation.tableTexts.modal_rev_outros as any)[`${idioma}`]}`}
  ]
}

buildJsonIntegra(){
  const oIntegra:any = {
    "RFQID": this.proposta,  
    "RFQREV": this.revisao,
    "APRVC": "", 
    "Item": []
   }

   
  if(!this.isReprovado){
    if(this.gridValuesPrecoVenda.some((item:any) => item.STATUS == "approved")){
      oIntegra["APRVC"] = 'A'
    }else if(this.gridValuesPrecoVenda.some((item:any) => item.STATUS == "review")){
      oIntegra["APRVC"] = 'RE'
    }else{
      oIntegra["APRVC"] = 'R'
    }
  }else{
    oIntegra["APRVC"] = 'R'
  }

  let filteredData = this.gridValuesPrecoVenda.filter((item:any, index:any, self:any) =>
    index === self.findIndex((t:any) => (
      t.ITEM === item.ITEM && t.MATNRRFQ === item.MATNRRFQ
    ))
  )

filteredData.forEach((itens:any) => {
    let itemToAdd:any = {
      "ZITEM": "",
      "Lista": [],
      "Aprovados": [],
      "Reprovados": []
    }   
  
    itemToAdd["ZITEM"] = itens.ITEM
    const itensDetail = itens.detail.filter((item:any) => item.ZITEM == itens.ITEM)
    const cabecalhoProposta = this.itensCabecalhoProposta.filter((item:any) => item.ZITEM == itens.ITEM )
    
    itensDetail.forEach((details:any) => {
      let itemToAddLista:any = {}
      itemToAddLista["ITEMOK"]  = details.ITEMOK
      itemToAddLista["MFRPN"] = details.MFRPN

      if(itens.STATUS == "approved"){
          if(!itemToAdd['Aprovados'].includes((itens.CENARIO))){
            itemToAdd["Aprovados"].push(itens.CENARIO)
          }
      }else{
          if(!itemToAdd['Reprovados'].includes(itens.CENARIO)){
            itemToAdd["Reprovados"].push(itens.CENARIO)
          }
      }
        

      cabecalhoProposta.forEach((itemProposta:any) =>{
        const idOrigem = parseInt(itemProposta.ID_CENARIO_ORIGEM)
        const idCenario = parseInt(itemProposta.ID_CENARIO)
        if(idOrigem != 0){
         if(itemToAddLista[`APRPMCL${idOrigem}`] != 'A'){
          if(itens.STATUS == "approved"){
            itemToAddLista[`APRPMCL${idOrigem}`] = "A";
          }else if(itens.STATUS == "disapproved"){
            itemToAddLista[`APRPMCL${idOrigem}`] = "R";
          }else{
            itemToAddLista[`APRPMCL${idOrigem}`] = "";
          }
         }
        }else{
          if(idCenario == 1) {
            if(itens.STATUS == "approved" && details.BESTC1 == 'X'){
              itemToAddLista["APRPMCL1"] = "A";
            }else if(itens.STATUS == "disapproved" && details.BESTC1 == 'X'){
              itemToAddLista["APRPMCL1"] = "R";
            }else{
              itemToAddLista["APRPMCL1"] = "";
            }
          }else if(idCenario == 2) {
            if(itens.STATUS == "approved" && details.BESTC2 == 'X'){
              itemToAddLista["APRPMCL2"] = "A";
            }else if(itens.STATUS == "disapproved" && details.BESTC2 == 'X'){
              itemToAddLista["APRPMCL2"] = "R";
            }else{
              itemToAddLista["APRPMCL2"] = "";
            }
          }else if(idCenario == 3) {
            if(itens.STATUS == "approved" && details.BESTC3 == 'X'){
              itemToAddLista["APRPMCL3"] = "A";
            }else if(itens.STATUS == "disapproved" && details.BESTC3 == 'X'){
              itemToAddLista["APRPMCL3"] = "R";
            }else{
              itemToAddLista["APRPMCL3"] = "";
            }
          }else if(idCenario == 4) {
            if(itens.STATUS == "approved" && details.BESTC4 == 'X'){
              itemToAddLista["APRPMCL4"] = "A";
            }else if(itens.STATUS == "disapproved" && details.BESTC4 == 'X'){
              itemToAddLista["APRPMCL4"] = "R";
            }else{
              itemToAddLista["APRPMCL4"] = "";
            }
          }
        }
      })
   

      const itensAlternativos = this.gridItemsListAlternativa.filter((item:any) => item.ZITEM === itens.ITEM)

      itensAlternativos.forEach((alternativo:any) => {
        
        // Criar aprovação de status
        if(alternativo.STATUS == "approved"){
          if(alternativo.BESTAL1 == 'X' || alternativo.BESTAL2 == 'X' || alternativo.BESTAL3 == 'X' || alternativo.BESTAL4 == 'X'){
            itemToAddLista["APRALTCL"] = 'A'
          }
        }else{
          itemToAddLista["APRALTCL"] = 'R'
        }
      })
  
      itemToAdd.Lista.push(itemToAddLista)
      
    })
    oIntegra.Item.push(itemToAdd)
  })

  return this.completeJsonIntegra(oIntegra)
}

completeJsonIntegra(jsonIntegra : any){
  jsonIntegra.Item.forEach((item:any) => {
    item.Lista.forEach((lista:any) => {
        console.log(lista.hasOwnProperty('APRPMCL3'))
        if(!lista.hasOwnProperty('APRALTCL')){
            lista['APRALTCL'] = ''
        }
        
        if(!lista.hasOwnProperty('APRPMCL1')){
            lista['APRPMCL1'] = ''
        }
        
        if(!lista.hasOwnProperty('APRPMCL2')){
            lista['APRPMCL2'] = ''
        }
        
         if(!lista.hasOwnProperty('APRPMCL3')){
            lista['APRPMCL3'] = ''
        }

         if(!lista.hasOwnProperty('APRPMCL4')){
            lista['APRPMCL4'] = ''
        }
    })
})


  return jsonIntegra
}
 async saveAndMoveData(){
  const jsonIntegra = this.buildJsonIntegra()
  const {response}:any = await this.service.aprovaSolicitacao(jsonIntegra,this.documentid,this.emailSolicitante,jsonIntegra.APRVC)
  const {STATUS} = response
  
  if(STATUS != 'ERROR'){
    this.notification.success({ 
      message: `Sucesso`, 
      duration: 3000,
      orientation: PoToasterOrientation.Top
  });

  this.router.navigate(['consultar'])
  }else{
    this.notification.error({ 
      message: `${this.idioma_proposta == 'en' ? 'An error occurred. Please contact the IT team at Hi-mix. ': 'Ocorreu um Erro. Favor entrar em contato com a equipe de TI da Hi-mix' }`, 
      duration: 3000,
      orientation: PoToasterOrientation.Top 
    });
  }

 }

 showOrNotDetails(value: string){
  if(value == 'modal_rev_outros'){
    this.isDetail = true
  }else{
    this.isDetail = false
  }
 }

}
