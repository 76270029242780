import { Component } from '@angular/core';
import { EditInvoicePackingService } from './edit-invoice-packing.service'
import { PoNotificationService, PoToasterOrientation } from '@po-ui/ng-components';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit-invoice-packing',
  templateUrl: './edit-invoice-packing.component.html',
  styleUrls: ['./edit-invoice-packing.component.css']
})
export class EditInvoicePackingComponent {

  isReproved:boolean = true
  campoJustificativa: string =""
  documentId:number = 0

  constructor(
    public service: EditInvoicePackingService,  
    public notification: PoNotificationService,  
    private activatedRoute : ActivatedRoute,
    private router: Router
  ){}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.documentId = Number(params.get('id'));
    });

  }

  handleApprovedOrReproved(decision:string) {
    if(decision == 'approved'){
      this.isReproved = true
      this.campoJustificativa = ''
    }else{
      this.isReproved = false
    }
  }

  async handleSave(){
    if(!this.isReproved && this.campoJustificativa == ""){
      this.notification.error({ 
        message: `Campo de Justificativa deve ser preenchido.`, 
        duration: 4000,
        orientation: PoToasterOrientation.Top
      }); 
      return 
    }

    const isReproved = this.isReproved ? 'aprovado' : 'reprovado'
    const {response}:any = await this.service.moveRequest(
      this.documentId,
      isReproved,
      this.campoJustificativa
    )

    if(response[0].STATUS == 'ok'){
      this.notification.success({ 
        message: `Sucesso`, 
        duration: 4000,
        orientation: PoToasterOrientation.Top
      }); 
      this.router.navigate(['/consultainvoice'])
    }else{
      this.notification.warning({ 
        message: `Erro ao realizar a ${isReproved == 'aprovado' ? 'Aprovação' : 'Reprovação'}`, 
        duration: 4000,
        orientation: PoToasterOrientation.Top
      }); 
    }
    
  }
}
