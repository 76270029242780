import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentsService } from './documents.service';
import { PoNotificationService, PoTableColumn, PoTableComponent, PoToasterOrientation, PoUploadComponent } from '@po-ui/ng-components';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent {
  @ViewChild(PoTableComponent, { static: true }) poTable!: PoTableComponent;
  @ViewChild('upload') upload!: PoUploadComponent;

  constructor(
    private service: DocumentsService,
    private activatedRoute : ActivatedRoute,
    private route: Router,
    public notification: PoNotificationService,  
  ){}

  isEdit:boolean = true
  activity: number = 0;
  gridColumns:Array<PoTableColumn> = [ 
    { label: 'Nº Solicitação', property: 'numProcessoInput'},
    { label: 'Cliente', property: 'cliente',visible:false},
    {
      property: 'status',
      type: 'label',
      labels: [
        { 
          value: 'onApproval',
          color: 'color-08',
          label: `Em Aprovação` 
        },{ 
          value: 'approved', 
          color: 'color-11', 
          label: `Aprovado`,
          textColor:'white'
        },{ 
          value: 'disapproved', 
          color: 'color-07', 
          label: `Reprovado`,
          textColor:'white' 
        }
      ]
    },
    { label: 'País', property: 'pais_origem'},
    { label: 'Cod. Produto', property: 'cod_prod'},
    { label: 'Desc. Produto', property: 'descricao'},
    { label: 'Ov. Cliente', property: 'ov_cliente',visible:false},
    { label: 'Nº Ordem de Prod.', property: 'ord_prod',visible:false},
    { label: 'Data da Remessa', property: 'data_ov_prod',visible:false},
    { label: 'Url', property: 'urlDownload',visible:false},
    
    { 
      label: 'Visualizar Anexo', 
      property: 'isUrl',
      width:'5%',
      type: 'icon', 
      icons: [
        { 
          value: 'true', 
          icon: 'po-icon-eye', 
          tooltip: 'Visualizar Anexo',  
          action: this.opeAnexos.bind(this),
        }
      ]
    }
  ]

  isReprovado:boolean = false
  gridItems:any = []
  justificativa:string = ''
  documentId!:number

  async ngOnInit(): Promise<void> {
    this.activatedRoute.paramMap.subscribe(params => {
      let id = params.get('id');
      
      this.documentId = id ? Number(id) : 0; 
    });

    this.activatedRoute.queryParamMap.subscribe(params => {
      const editParam = params.get('edit');
      this.activity = Number(params.get('activity'));
      this.isEdit = editParam === 'true' ? true : false;

      this.gridColumns.forEach(item => {
        if(item.property == 'status' || item.property == 'nameFile' &&  this.activity != 84|| item.property == 'includeAnexo'  &&  this.activity != 84){
            item['visible'] = this.isEdit
        }
    })
    });


    this.service.getDatas(this.documentId).then(({response}:any) =>{
      response.forEach((value:any) => {
        

        let hasInfo = [value].some((item:any) => {
          let tableDeclaraProd = JSON.parse(item.tableDeclaraProd);
          return tableDeclaraProd.some((prod:any) => prod[value.ov_cliente] === value.cod_prod);
        });
  
        if(hasInfo) {
          this.gridItems.push(value); 
        }
      });
     
    })
  }

  

  approveOrReprovedItems(value: string) {
    let selectedItems: any;
    this.isReprovado = false
    selectedItems = this.poTable.getSelectedRows()

    if (value == 'toApprove') {

      selectedItems.map((val:any) => {
        val.status = 'approved'
      })

      this.poTable.unselectRows()
    }else{
      selectedItems.map((val:any) => {
        val.status = 'disapproved';
      });
      this.poTable.unselectRows()
    }

    this.gridItems.forEach((item:any) => {
      if(item.status == "disapproved")  this.isReprovado = true
    })
  }

  opeAnexos(row: any) {
    window.open(row?.urlDownload, '_blank');
      this.route.navigate
   
  }


  async validLines(){
    const aReproved: Array<any> = []
    const isOnApproval = this.gridItems.some((item:any) => item.status == "onApproval")
    const status = this.gridItems.some((item:any) => item.status == "disapproved") ? "reprovado" : "aprovado";
    
    if(isOnApproval){
      this.notification.error({ 
        message: `Para realizar o envio os itens devem ser aprovados ou reprovados.`, 
        duration: 4000,
        orientation: PoToasterOrientation.Top
    });
      return 
    }


    if(status == "reprovado"){      
      if(this.justificativa == ''){
        this.notification.error({ 
          message: `Campo de Justificativa deve ser preenchido.`, 
          duration: 4000,
          orientation: PoToasterOrientation.Top
        });  
        return 
      }else{
        this.gridItems.forEach((item:any) => {
          const itemReproved:any = {}
          itemReproved[item.ov_cliente] = item.cod_prod
          aReproved.push(itemReproved)
        });
      }
    }

    
    
    const {response}:any = await this.service.moveRequest(
      this.documentId,
      status,
      aReproved,
      this.justificativa
    )
    const {STATUS} = response[0]

    if(STATUS == 'ok'){
      this.notification.success({ 
        message: `Sucesso`, 
        duration: 3000,
        orientation: PoToasterOrientation.Top
      });
      this.route.navigate(['consultardocumentos'])
    }else{
      this.notification.error({ 
        message: 'Erro ao realizar a movimentação da solicitação', 
        duration: 3000,
        orientation: PoToasterOrientation.Top 
      });
    }

  }

}
