import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../../environments/environment.prod'
import {AuthenticatorService} from '../../../services/authenticator.service'

@Injectable({
  providedIn: 'root'
})
export class PropostaService {

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) { }

  apiUrl: any;

  getMateriasOptions(codCliente: string) {
  
    this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

    const requestData = {
      url: this.apiUrl,
      method: 'POST'
    };

    const body = {
      name: "DTS_MATERIAIS",
      constraints:  [{
        _field: 'CLIENTE', 
        _initialValue: codCliente, 
        _finalValue: codCliente, 
        _type: 1
      }]
    };
    
   
    const httpOptions = this.authenticator.connect(requestData)
    
    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: response.content.values
          });
        }
      );
    });
  }

  
}
