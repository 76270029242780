import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment.prod'
import {AuthenticatorService} from '../services/authenticator.service'
import { Router } from '@angular/router';
import * as moment from 'moment';
import { PoNotificationService, PoToasterOrientation } from '@po-ui/ng-components';
import {SharedService} from '../shared/shared.service'


@Injectable({
  providedIn: 'root'
})
export class AuthService{

  isAuth:Boolean = false

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService,
    private routherService: Router,
    public notification: PoNotificationService,
    public shared: SharedService,
  ) { }

  async login(user: string,password : string) {
    if(!this.isLoggedIn()){
      const requestData = {
        url: `${environment.url}/api/public/ecm/dataset/datasets`,
        method: 'POST'
      };
    
      const body = {
        name: "DTS_LOGIN",
        constraints: [
          {
            _field: "EMAIL", 
            _initialValue: user, 
            _finalValue: user, 
            _type: 1
          },{
            _field: "SENHA", 
            _initialValue: password, 
            _finalValue: password, 
            _type: 1
          }
        ]
      };
    
      const httpOptions = this.authenticator.connect(requestData);


      const response: any = await this.http.post(requestData.url, body, httpOptions).toPromise()
      
      if(response.content &&  Object.keys(response.content).length > 0 && response.content.values[0].STATUS == 'LOGIN EXECUTADO COM SUCESSO' ){
        const values = response.content.values
        
        this.routherService.navigate(["/authenticator"])
        this.setSession(values)
      }else{
        this.notification.warning({ 
          message: `Login não efetuado, verificar login e senha.`, 
          duration: 3000,
          orientation: PoToasterOrientation.Top
        });
      }
    }
  }

  async createAuthenticator() {
    const email: string = this.getUserData('EMAIL')

    const requestData = {
      url: `${environment.url}/api/public/ecm/dataset/datasets`,
      method: 'POST'
    };

    const body = {
      name: "DTS_CREATE_AUTENTICATOR",
      constraints: [
        {
          _field: "EMAIL", 
          _initialValue: email, 
          _finalValue: email, 
          _type: 1
        }
      ]
    };

    const httpOptions = this.authenticator.connect(requestData);

    const response: any = await this.http.post(requestData.url, body, httpOptions).toPromise()

    if(response.content.values[0].STATUS == '200' ){
      this.notification.warning({ 
        message: `Código enviado`, 
        duration: 3000,
        orientation: PoToasterOrientation.Top
      });
      
      return true
    }else{
      this.notification.warning({ 
        message: `Solicite o codigo novamente`, 
        duration: 3000,
        orientation: PoToasterOrientation.Top
      });
      return false
    }
  }

  async secondAuthenticator(codAutenticacao: string) {
    const requestData = {
      url: `${environment.url}/api/public/ecm/dataset/datasets`,
      method: 'POST'
    };
    
    const body = {
      name: "DTS_SEGUNDA_AUTENTICACAO",
      constraints: [
        {
          _field: "COD_AUTENTICACAO", 
          _initialValue: codAutenticacao, 
          _finalValue: codAutenticacao, 
          _type: 1
        }
      ]
    };
    
    const httpOptions = this.authenticator.connect(requestData);


    const response: any = await this.http.post(requestData.url, body, httpOptions).toPromise()
    
    if(response.content.values[0].STATUS == '200' ){
      return true
    }else{
      return false
    }
    
  }

  async markAsAuthenticated(codAutenticacao: string) {
    const requestData = {
      url: `${environment.url}/api/public/ecm/dataset/datasets`,
      method: 'POST'
    };
    
    const body = {
      name: "DTS_SEGUNDA_AUTENTICACAO",
      constraints: [
        {
          _field: "COD_AUTENTICACAO", 
          _initialValue: codAutenticacao, 
          _finalValue: codAutenticacao, 
          _type: 1
        },{
          _field: "IS_UPDATE", 
          _initialValue: true, 
          _finalValue: true, 
          _type: 1
        }
      ]
    };
    
    const httpOptions = this.authenticator.connect(requestData);


    const response: any = await this.http.post(requestData.url, body, httpOptions).toPromise()
    
    if(response.content.values[0].STATUS == '200' ){
      localStorage.setItem('hi-token', this.generateCod());
      return true
    }else{
      return false
    }
    
  }

  logout() {
    localStorage.clear();    
  }

  getUserData(key : string){
    const dados:any = localStorage.getItem('login');
    return key != undefined ? JSON.parse(dados)[0][key] : JSON.parse(dados)[0]
  }



  isRepresentante() {
    const dados:any = localStorage.getItem('login');
    const representante = JSON.parse(dados)[0].FUNCAO
    if(representante.includes('Representante')) return true
    return false
  }
  isLoggedIn(): Boolean {
    if(localStorage.getItem('login')){
      return true
    }
    return false
  }

  isLoggedSecond(): Boolean {
    if(localStorage.getItem('hi-token')){
      return true
    }
    return false
  }
  setSession(value: any) {
    localStorage.setItem('login', JSON.stringify(value));

    const expiresAt = moment().add(1, 'hour');
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));

  }

  checkSession() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at')!);
    const current_time = moment().valueOf();
  
    if (current_time > expiresAt) {
      localStorage.removeItem('login');
      localStorage.removeItem('expires_at');
      // this.routherService.navigate(['/login']);
    }
  }
  

  generateCod(){
    return Math.random().toString(36).substring(2, 6);
  }

  

}


