import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import {HomeService} from './home.service'

import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  cnpj!:string
  hasConsulta: boolean = false
  teste!:string

  constructor(
    private router: Router,
    private authService: AuthService,
    private service: HomeService,
    
  ) { }

  ngOnInit(): void {
    this.cnpj = this.authService.getUserData('CNPJ')

    

    this.service.isHaveRequest(this.cnpj).then(({ response }: any)=> {
      this.hasConsulta = response
      
    })
    this.teste='asd'
  }


  reedirect(path: string) {
    this.router.navigate([path])
  }



  }


