import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { AuthenticatorService } from '../../../services/authenticator.service';
import { HttpClient } from '@angular/common/http';
import {UtilsService} from '../../../services/utils.service'

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService,
    private utils : UtilsService
  ) { }

  apiUrl: any;

  async isHaveRequest(cnpj: string) {
    debugger
  
      this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
    
  
      const requestData = {
        url: this.apiUrl,
        method: 'POST'
      };
  
      const body = {
        name: "DTS_SOLICITACOES",
        constraints: [{
          _field: "CNPJ", 
          _initialValue: this.utils.formatarCnpj(cnpj), 
          _finalValue: this.utils.formatarCnpj(cnpj), 
          _type: 1
        }]
      };
      
     
      const httpOptions = this.authenticator.connect(requestData)
      
      return new Promise((resolvePromise) => {
        this.http.post(requestData.url, body, httpOptions).subscribe(
          (response: any) => {
            resolvePromise({
              response: response.content.values.length > 0 ? true : false
            });
          }
        );
      });
    }
}
