import { Component } from '@angular/core';
import { PoTableColumn } from '@po-ui/ng-components';
import { ListInvoicePackingService } from './list-invoice-packing.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-invoice-packing',
  templateUrl: './list-invoice-packing.component.html',
  styleUrls: ['./list-invoice-packing.component.css']
})
export class ListInvoicePackingComponent {

  
  constructor(
    private service: ListInvoicePackingService,
    private route: Router
  ) { }


  gridItems: any[] = [];


  gridColumns:Array<PoTableColumn> = [ 
    { label: 'Nº Processo', property: 'NUMPROCESS'},
    { label: 'Document Id', property: 'DOCUMENTID'},
    { 
      label: 'Validação Documentos', 
      property: 'status',
      type:'icon',
      icons: [
        { 
          value: 'onApproval', 
          icon: 'po-icon-eye', 
          tooltip: 'Aprovar Solicitação',  
          action: this.extras.bind(this),
        },{ 
          value: 'disabled', 
          icon: 'po-icon-eye', 
          tooltip: 'Visualizar Solicitação',  
          action: this.extras.bind(this),
        }
    ]
    },
  ]

    ngOnInit(): void {
    this.service.getDatas().then(({ response}: any)=> {
      this.gridItems = response
    })



  }


  

extras(row: any) {
  if(row.NUM_SEQ_ESTADO == 'Em Andamento'){
    this.route.navigate(["/aprovainvoice",`${row?.DOCUMENTID}`])
  }
}




}
