import { PoTableColumn } from "@po-ui/ng-components";

interface IitensProposta {
    ID_CENARIO: string,
    ZITEM: string
  }

  const gridColumnsInvestimento:Array<PoTableColumn> = [
    { label: 'Item', property: 'ITEM'},
    { label: 'Descrição', property: 'NRE_ITEM'},
    { label: 'Qtde.', property: 'QUANT'},
    { label: 'Preço Unitário', property: 'PRUNI'},
    { label: 'Preço Total', property: 'PRTTL'},
    { label: 'Observação', property: 'OBS'},
  ]

  
  const gridColumnsApontamento:Array<PoTableColumn> = [
    { label: 'Item', property: 'ID',width: "9%"},
    { label: 'Descrição', property: 'DESCRICAO',width: "50%"},
    { label: 'Apontamento', property: 'APONTA'},
  ]







  const yesOrNot:any = [
    { value: 'sim', label: 'Sim' },
    { value: 'nao', label: 'Não' }
  ];

  interface IMapColumnsAponta {
    APONTA: string;
  }

  export {
    IitensProposta,
    gridColumnsInvestimento,
    yesOrNot,
    IMapColumnsAponta,
    gridColumnsApontamento
  };