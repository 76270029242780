import { Component, OnInit, Input,EventEmitter,Output  } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {

  @Output('on-change-page')
  pageChangeEmitter = new EventEmitter();

  @Input('total-hits')
  totalHits = 0;

  @Input('total-grid')
  totalGrid = 0;

  @Input('current-page')
  currentPage = 1

  

  lastPage: number;
  
  numer: number | undefined;
  

  constructor() { 
    this.lastPage = 0;
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.lastPage = Math.ceil(this.totalHits / this.totalGrid);
  }

  goToPreviousPage() {
    this.pageChangeEmitter.emit({
      currentPage: this.currentPage - 1
    });
  }

  goToFirstPage() {
    if (this.currentPage === 1) {
      return;
    }

    this.pageChangeEmitter.emit({
      currentPage: 1
    });
  }

  goToNextPage() {
    debugger
    this.pageChangeEmitter.emit({
      currentPage: this.currentPage + 1
    });
    
  }

  goToLastPage() {
    if (this.currentPage === this.lastPage) {
      return;
    }

    this.pageChangeEmitter.emit({
      currentPage: this.lastPage
    });
  }

}
