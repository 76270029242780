import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {environment} from '../../../../environments/environment.prod'
import {AuthenticatorService} from '../../../services/authenticator.service'
import {ComboOption,IGetClient} from './nova-solicitacao.interface'
import { Constraint } from 'src/app/services/Interfaces';


@Injectable({
  providedIn: 'root'
})



export class NovaSolicitacaoService {

  

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) { }

  apiUrl: any;
  

 

  createrFolder(folderName : any){
    folderName = folderName.replaceAll(' ','')
    const principalFolder = '665'
    this.apiUrl = `${environment.url}/api/public/ecm/dataset/search?datasetId=DTS_CREATE_FOLDER&filterFields=ID_FOLDER,${principalFolder},DESCRIPTION,${folderName}`
                                  
    const requestData = {
        url: this.apiUrl,
        method: 'GET'
      };
  
      const httpOptions = this.authenticator.connect(requestData)
      
      return new Promise((resolvePromise) => {
        this.http.get(this.apiUrl, httpOptions).subscribe(
          (response: any) => {
            resolvePromise({
              response: response.content
            });
          }
        );
      });
  }

  getMateriasOptions(codCliente: string) {
  
    this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

    const requestData = {
      url: this.apiUrl,
      method: 'POST'
    };

    const body = {
      name: "DTS_MATERIAIS",
      constraints:  [{
        _field: 'CLIENTE', 
        _initialValue: codCliente, 
        _finalValue: codCliente, 
        _type: 1
      }]
    };
    
   
    const httpOptions = this.authenticator.connect(requestData)
    
    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: response.content.values
          });
        }
      );
    });
  }


  attachFiles(params: {},dadosFile:{}) {
    const {documentId} : any = params
    const {base64,name} : any = dadosFile
    const requestData = {
      url: `${environment.url}/api/public/ecm/dataset/datasets`,
      method: 'POST'
    };
  
    const body = {
      name: "DTS_ANEXAR_ARQUIVO",
      constraints: [
        {_field: "stringBase64Arquivo", _initialValue: base64, _finalValue: base64, _type: 1},
        {_field: "fileName", _initialValue: name, _finalValue: name, _type: 1},
        {_field: "parentDocumentId", _initialValue: documentId, _finalValue: documentId, _type: 1}
      ]
    };
  
    const httpOptions = this.authenticator.connect(requestData);
  
    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            codFile: response.content.values[0]['Resultado']
          });
        }
      );
    });
  }

  startProcess(params: {}) {
    params = JSON.stringify(params)
    const requestData = {
      url: `${environment.url}/api/public/ecm/dataset/datasets`,
      method: 'POST'
    };
  
    const body = {
      name: "DTS_START_SOLIC",
      constraints: [
        {
          _field: "JSON", 
          _initialValue: params, 
          _finalValue: params, 
          _type: 1
        }
      ]
    };
  
    const httpOptions = this.authenticator.connect(requestData);
  
    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: response.content.values
          });
        }
      );
    });
  }
  
    
    consultarCNPJ(cnpj: string): Promise<any> {
      const script = document.createElement('script');
      script.src = `https://www.receitaws.com.br/v1/cnpj/${cnpj}?callback=processarResposta`;
    
      return new Promise((resolve, reject) => {
        (window as any)['processarResposta'] = (data: any) => {
          delete (window as any)['processarResposta']; // Remove a função após ser executada
          document.body.removeChild(script); // Remove o elemento <script> criado
    
          if (data.status === 'OK') {
            resolve({
              response: data
            }); // Resolve a promise com os dados da resposta
          } else {
            console.log('Erro ao consultar CNPJ:', data.message);
            reject(data); // Rejeita a promise em caso de erro
          }
        };
    
        // Adiciona o elemento <script> ao <body> para fazer a requisição JSONP
        document.body.appendChild(script);
      });
    }

    consultaCEP(cep:string) {
      debugger
    
      this.apiUrl = `https://viacep.com.br/ws/${cep}/json/`;
    
        
        return new Promise((resolvePromise) => {
          this.http.get(this.apiUrl).subscribe(
            (response: any) => {
              resolvePromise({
                response: response
              });
            }
          );
        });
      }
      

    async getEstados(pais: String) {
      const requestData = {
        url: `${environment.url}/api/public/ecm/dataset/datasets`,
        method: 'POST'
      };

      const body = {
        name: "DTS_ESTADOS_BR",
        constraints: [
          {
            _field: "descricao", 
            _initialValue: pais, 
            _finalValue: pais, 
            _type: 1
          }
        ]
      }
    
      const httpOptions = this.authenticator.connect(requestData);
    
      return new Promise((resolvePromise) => {
        this.http.post(requestData.url, body, httpOptions).subscribe(
          (response: any) => {
            resolvePromise({
              response: response.content.values
            });
          }
        );
      });
    }
    
    async clienteSAP(funcao:number,email?:string) {
  
      this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

      const requestData = {
        url: this.apiUrl,
        method: 'POST'
      };
    
      const body:{ name: string; constraints: Constraint[] } = {
        name: funcao == 16 ? 'DTS_CLIENTES' : "DTS_FIND_EMAIL",
        constraints:  []
      };
      

      if(funcao != 16){
        body.constraints.push({
          _field: 'CODFUNCAO', 
          _initialValue: funcao, 
          _finalValue: funcao, 
          _type: 1
        })
      }

      if(funcao == 16){
        body.constraints.push({
          _field: 'sqlLimit', 
          _initialValue: 100, 
          _finalValue: 100, 
          _type: 1
        })
      }

      if(funcao == 17){
        body.constraints.push({
          _field: 'EMAIL', 
          _initialValue: email, 
          _finalValue: email, 
          _type: 1
        })
      }

      
      const httpOptions = this.authenticator.connect(requestData)
      
      return new Promise((resolvePromise) => {
        this.http.post(requestData.url, body, httpOptions).subscribe(
          (response: any) => {
            resolvePromise({
              response: response.content.values,
              totalHits: response.content.values.length
            });
          }
        );
      });
    }


    async paises() {
  
      this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

      const requestData = {
        url: this.apiUrl,
        method: 'POST'
      };
  
      const body:{ name: string; constraints: Constraint[] } = {
        name: "DTS_PAISES",
        constraints:  []
      };

      
     
      const httpOptions = this.authenticator.connect(requestData)
      
      return new Promise((resolvePromise) => {
        this.http.post(requestData.url, body, httpOptions).subscribe(
          (response: any) => {
            resolvePromise({
              response: response.content.values,
              totalHits: response.content.values.length
            });
          }
        );
      });
    }

    async formMontagemProposta(email:string) {
  
      this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

      const requestData = {
        url: this.apiUrl,
        method: 'POST'
      };
  
      const body:{ name: string; constraints: Constraint[] } = {
        name: "DTS_FRM_MONTAGEM_PROPOSTA_COMERCIAL",
        constraints:  [{
          _field: 'emailSolicitante', 
          _initialValue: email, 
          _finalValue: email, 
          _type: 1
        }]
      };

      const httpOptions = this.authenticator.connect(requestData)
      
      return new Promise((resolvePromise) => {
        this.http.post(requestData.url, body, httpOptions).subscribe(
          (response: any) => {
            resolvePromise({
              response: response.content.values,
              totalHits: response.content.values.length
            });
          }
        );
      });
    }

    async ddiPaises() {
  
      this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

      const requestData = {
        url: this.apiUrl,
        method: 'POST'
      };
  
      const body:{ name: string; constraints: Constraint[] } = {
        name: "DTS_DDI_PAISES",
        constraints:  []
      };

      
     
      const httpOptions = this.authenticator.connect(requestData)
      
      return new Promise((resolvePromise) => {
        this.http.post(requestData.url, body, httpOptions).subscribe(
          (response: any) => {
            resolvePromise({
              response: response.content.values,
              totalHits: response.content.values.length
            });
          }
        );
      });
    }
      

      getDataApi(datasets:string[],params:ComboOption) {
        console.log('getDataApi Time')
        console.time('Start')
        const apiUrlBase = `${environment.url}/api/public/ecm/dataset/search`;

        const requests = datasets.map(dataset => {
          let apiUrl = `${apiUrlBase}?datasetId=${dataset}`;

          if(params.value) apiUrl += `&filterFields=${params.label},${params.value}`

          const requestData = {
            url: apiUrl,
            method: 'GET'
          };
          const httpOptions = this.authenticator.connect(requestData);
      
          return this.http.get<any>(apiUrl, httpOptions)
                .toPromise()
                .then(result => ({ 
                  dataset, 
                  content: result!.content,
                  campos: result.content.length > 0 ? Object.keys(result!.content[0]) : '',
                }));
        });
      
        console.timeEnd('Fim')

        return Promise.all(requests);
      }   

      async getDataFormulario(idDocument: string) {
        const requestData = {
          url: `${environment.url}/api/public/ecm/dataset/datasets`,
          method: 'POST'
        };
  
        const body:{ name: string; constraints: Constraint[] } = {
          name: "DTS_MONTAGEM_PROPOSTA",
          constraints: [
            {
              _field: "documentId", 
              _initialValue: idDocument, 
              _finalValue: idDocument, 
              _type: 1
            }
          ]
        }
      
        const httpOptions = this.authenticator.connect(requestData);
      
        return new Promise((resolvePromise) => {
          this.http.post(requestData.url, body, httpOptions).subscribe(
            (response: any) => {
              resolvePromise({
                response: response.content.values[0]
              });
            }
          );
        });
      }

      async getCliente({cnpj,isRepresentante,email}: IGetClient) {
        const requestData = {
          url: `${environment.url}/api/public/ecm/dataset/datasets`,
          method: 'POST'
        };
  
        const body:{ name: string; constraints: Constraint[] } = {
          name: "DTS_CLIENTES",
          constraints: []
        }

        if(isRepresentante){
          body.constraints.push({
            _field: "EMAIL", 
            _initialValue: '%'+email+'%', 
            _finalValue: '%'+email+'%', 
            _type: 2,
            _likeSearch:true
          })
        }else{
          body.constraints.push({
            _field: "CNPJ", 
            _initialValue: cnpj, 
            _finalValue: cnpj, 
            _type: 1
          })
        }
       
        const httpOptions = this.authenticator.connect(requestData);
      
        return new Promise((resolvePromise) => {
          this.http.post(requestData.url, body, httpOptions).subscribe(
            (response: any) => {
              resolvePromise({
                response: response.content.values
              });
            }
          );
        });
      }

      async searchClientes(search:string,funcao:number,email:string) {
        const requestData = {
          url: `${environment.url}/api/public/ecm/dataset/datasets`,
          method: 'POST'
        };
  
        const body:{ name: string; constraints: Constraint[] } = {
          name: "DTS_CLIENTES",
          constraints:[]
        }

        
        body.constraints.push({
          _field: "PESQUISA", 
          _initialValue: '%'+search+'%', 
          _finalValue: '%'+search+'%', 
          _type: 2,
          _likeSearch:true
        },{
          _field: 'sqlLimit', 
          _initialValue: 100, 
          _finalValue: 100, 
          _type: 1
        },{  
          _field: 'CODFUNCAO', 
          _initialValue: funcao, 
          _finalValue: funcao, 
          _type: 1
        })

        if(funcao == 17){
          body.constraints.push({
            _field: 'EMAIL', 
            _initialValue: email, 
            _finalValue: email, 
            _type: 1
          })
        }
       
        const httpOptions = this.authenticator.connect(requestData);
      
        return new Promise((resolvePromise) => {
          this.http.post(requestData.url, body, httpOptions).subscribe(
            (response: any) => {
              resolvePromise({
                response: response.content.values
              });
            }
          );
        });
      }

      async dtsNumRFQ(crud:string,rfq?:number, rev?:string, reserva?:string, using?:string) {
  
        this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
  
        const requestData = {
          url: this.apiUrl,
          method: 'POST'
        };
    
        const body = {
          name: "DTS_CRUD_DOC_RFQ_REV",
          constraints:  [{
            _field: "CRUD", 
            _initialValue: crud, 
            _finalValue: crud, 
            _type: 1
          },{
            _field: "RFQ", 
            _initialValue: rfq, 
            _finalValue: rfq, 
            _type: 1
          },{
            _field: "REV", 
            _initialValue: rev, 
            _finalValue: rev, 
            _type: 1
          },{
            _field: "RESERVA", 
            _initialValue: reserva, 
            _finalValue: reserva, 
            _type: 1
          },{
            _field: "USING", 
            _initialValue: using, 
            _finalValue: using, 
            _type: 1
          }]
        };
  
        
       
        const httpOptions = this.authenticator.connect(requestData)
        
        return new Promise((resolvePromise) => {
          this.http.post(requestData.url, body, httpOptions).subscribe(
            (response: any) => {
              resolvePromise({
                response: response.content.values,
                totalHits: response.content.values.length
              });
            }
          );
        });
      }
}
