

<form  
    #formDir="ngForm" 
    name="form" 
    style="height: 100vh; width: 100vw; position: fixed; top: 0; left: 0;"
>
            
    <div class="img-background"></div>
    <fieldset class="corpo-form">
        <div class="content">
            <div>
                <img src="../../../assets/images/logo.png" alt="logo-hi-mix">
            </div>
            <h4>Olá, Seja bem-vindo!</h4>
            <p>Faça o login para acessar o portal do cliente</p>
                <po-email 
                    [(ngModel)]="usuario"
                    name="usuario" 
                    p-placeholder="Insira seu e-mail para acesso"
                ></po-email>
                
                <po-password  
                    [(ngModel)]="senhaUsuario"
                    name="senha" 
                    p-placeholder="Insira sua senha para acesso"
            ></po-password>
                <button class="botao-login" (click)="login()">Acessar</button>
            <a (click)="openModal()">Esqueceu sua senha?</a>
            <a routerLink="/register">Novo Registro</a>
        </div>
    </fieldset>

    <po-modal-password-recovery #passwordRecoveryModal (p-submit)="isExistingUser($event)" ></po-modal-password-recovery>
</form>
