import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-second-authentication',
  templateUrl: './second-authentication.component.html',
  styleUrls: ['./second-authentication.component.css']
})
export class SecondAuthenticationComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public route : Router
  ) { }

  autenticatorForm!: FormGroup
  isDisabled: boolean = false
  icon:string = 'po-icon po-icon-social-twitter'

  ngOnInit(): void {

    this.autenticatorForm = new FormGroup({
      codAutenticator: new FormControl('', [Validators.required]),
    });

    this.sendCodAuth()

  }

  async sendCodAuth(){
    await this.authService.createAuthenticator()
  }

  async validCode(){
    const codAutenticator = this.autenticatorForm.get('codAutenticator')?.value;
    
    const response = await this.authService.secondAuthenticator(codAutenticator)
    
    this.isDisabled = response

    if(response) this.sendSubmit()
     
  }

  async sendSubmit(){
    const codAutenticator = this.autenticatorForm.get('codAutenticator')?.value;
    await this.authService.markAsAuthenticated(codAutenticator)
    this.route.navigate(['/'])
  }

}
