interface inputsNegociacao {
    item_negoc_prev_fornc: number;
  }

  class inputsNegociacao {
    constructor() {
        this.item_negoc_prev_fornc;
    }
  }

  export {
    inputsNegociacao
  };