import { PoTableColumn } from "@po-ui/ng-components";

  const AbertosGridColumns:Array<PoTableColumn> = [
    { label: 'P.O.', property: 'contrato' },
    { label: 'Data de Entrega', property: 'datadeentrega', type: 'date' },
    { label: 'Safra', property: 'safra' },
    { label: 'CPF/CNPJ', property: 'cpfcnpj' }
  ];

  

  interface FilterOption {
    label: string;
    value: string;
  }

  interface GridItem {
  

    areaSolicitante?: string;
    requisicaoEmail?: string;
    validarDespesasArea?: string;
    validarDespesasNome?: string;
    horarioRetorno?: string;
    aprovGestorData?: string;
    idAtividade?: string;
    nomeSolicitante?: string;
    cpfFuncionario?: string;
    jsonPastas?: string;
    painelUltimaValidacao?: string;
    regiao?: string;
    card_index_id?: string;
    companyid?: string;
    validacaoFinanceiro?: string;
    validacaoFinanceiroHidden?: string;
    active: true,
    dataIda?: string;
    tableid?: string;
    codigoAprovador?: string;
    documentid?: string;
    id?: string;
    dataPrevistaPagamento?: string;
    reembolsoAgencia?: string;
    requisicaoArea?: string;
    tipoAprovador?: string;
    tipoMoeda?: string;
    financeiroHistoricJustific?: string;
    reembolsoBanco?: string;
    totalAprovadores?: string;
    centroCusto?: string;
    despesasProjeto?: string;
    requisicaoEmpresa?: string;
    aprovGestorNome?: string;
    valorTotalNaMoeda?: string;
    idSolicitacao?: string;
    totalMeiaDiarias?: string;
    adiantamento?: string;
    campoDescritor?: string;
    version: 1000,
    kmDadosViagem?: string;
    reembolsoNroConta?: string;
    projeto?: string;
    tipoViagem?: string;
    listaAprovadores?: string;
    valorTotalDiaria?: string;
    valorAReceberOuPagar?: string;
    card_index_version?: string;
    gestorObsJustificativa?: string;
    parent_id?: string;
    requisicaoCargo?: string;
    despesasViagem?: string;
    transporte?: string;
    valorCotacao?: string;
    codigoCentroCusto?: string;
    jsonTitulo?: string;
    projetox?: string;
    parcela?: string;
    responsavelProjeto?: string;
    codigoProjeto?: string;
    gestorAprovacaoHidden?: string;
    reembolsoTipoConta?: string;
    moedaEstrangeira?: string;
    dataRetorno?: string;
    cardid?: string;
    gestorAprovacao?: string;
    despesasProjetoHidden?: string;
    anexarAnexosObservacao?: string;
    valorTotalDespesas?: string;
    adiantamentoHidden?: string;
    aprovadorCentroCusto?: string;
    idPortalExterno?: string;
    aprovGestorArea?: string;
    validarDespesasData?: string;
    horarioIda?: string;
    requisicaoNome?: string;
    valorRecebido?: string;
    codigoEmpresa?: string;
    dataSolicitacao?: string;
    observacoes?: string;
    justificativaViagem?: string;
    totalJaAprovado?: string;
    valorTotalReembolso?: string;
    moedaEstrangeiraHidden?: string;
    numeroTitulo?: string;
    despesasViagemHidden?: string;
    totalDiarias?: string;
    matriculaFuncionario?: string;
  }

  interface FilterValues {
    RFQ_REVISAO?: string[];
    NUMPROPOSTA?: string[];
    ID?: string[];
    NOME_EMPRESA?: string[];
    NUM_SEQ_ESTADO?: string[];
    aprovGestorData?: string[];
    idAtividade?: string[];
  }

  class FilterValues {
    constructor() {
      this.RFQ_REVISAO = [];
      this.NUMPROPOSTA = [];
      this.ID = [];
      this.NOME_EMPRESA = [];
      this.NUM_SEQ_ESTADO = [];
      this.aprovGestorData = [];
      this.idAtividade = [];
    }
  }

  
  export {
    AbertosGridColumns,
    GridItem,
    FilterOption,
    FilterValues
  };