<po-widget>

    <div class="po-row">
        <div class="po-col-12 po-md-6">
          <span class="po-icon po-icon-arrow-left blue cursor-pointer " routerLink="/consultardocumentos">
            Voltar
          </span>
        </div>
      </div>

<po-table 
    [p-auto-collapse]="true"
    [p-columns]="gridColumns" 
    [p-hide-columns-manager]="true"
    [p-items]="gridItems"
    [p-selectable]="isEdit"
    [p-sort]="true"
    [p-striped]="true"
>
</po-table>

    <div class="po-row" *ngIf="isEdit">
        <po-button 
            class="po-md-6" 
            p-icon="po-icon po-icon-close" 
            (p-click)="approveOrReprovedItems('disapproved')" 
            [p-danger]="true" 
            p-label="Reprovar"> 
        </po-button>
        <po-button 
            class="po-md-6" 
            p-icon="po-icon po-icon-ok" 
            (p-click)="approveOrReprovedItems('toApprove')" 
            p-label="Aprovar"> 
        </po-button>
  </div>
  
  <div class="po-row" *ngIf="isReprovado" style="margin-top: 15px;">
    <po-textarea
        class="po-md-12" 
        p-label="Justificar Reprovação"
        [p-required]="true"
        [p-show-required]="true" 
        [(ngModel)]="justificativa"
    ></po-textarea>
  </div>

  <div class="po-row" style="margin-top: 15px;display: flex;justify-content: end;" *ngIf="isEdit"> 
    <po-button 
        class="po-md-1"     
        p-label="Enviar" 
        (p-click)="validLines()"></po-button>
  </div>
</po-widget>
    
