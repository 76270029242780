import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment.prod'
import { AuthenticatorService } from '../../../services/authenticator.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) { }

  apiUrl: any;

  registerUser(values: {}) {
    const requestData = {
      url: `${environment.url}/api/public/ecm/dataset/datasets`,
      method: 'POST'
    };
    const fields = Object.keys(values)
    const value: { [key: string]: any } = values
  
    // primeiroAcesso
    const body = {
      name: "DTS_CREATEUSER",
      constraints: [] as Array<{ _field: string; _initialValue: any; _finalValue: any; _type: number; }>
    };

    body.constraints.push({
      _field: "URL", 
      _initialValue: window.location.origin, 
      _finalValue: window.location.origin, 
      _type: 1
    })

    
    fields.forEach((val):any => {
      body.constraints.push({
        _field: val, 
        _initialValue: value[val], 
        _finalValue: value[val], 
        _type: 1
      })
    })
  
    const httpOptions = this.authenticator.connect(requestData);
  
    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: response.content.values[0]['STATUS'] == "CADASTRADO REALIZADO"
          });
        }
      );
    });
  }
}
