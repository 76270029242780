export default {
    cliente_corrente: {
        'sim': [
            {'isCodSapSubject': true},
            {'isProdCorrentSubject': true}
        ], 
        'nao': [
          {'isCodSapSubject': false},
          {'isProdCorrentSubject': false}
        ]
      },

      produto_corrente: {
        'sim': [{
          'isCodProdSubject': true,
        }], 
        'nao': [{
          'isCodProdSubject': false,
        }]
    },

    simi_prod: {
        'sim': [{
          'isindicProdSubject': true,
        }],
        'nao': [{
          'isindicProdSubject': false,
        }]
      },

    segmento: {
        'automotivo': [{
          'isReqCriSubject': true,
        }],
        'def_aero': [{
          'isReqCriSubject': true,
        }],
        'agri_prec': [{
          'isReqCriSubject': false,
        }],
        'auto_ind': [{
          'isReqCriSubject': false,
        }],
        'auto_banc': [{
          'isReqCriSubject': false,
        }],
        'consumer': [{
          'isReqCriSubject': false,
        }],
        'control_seg': [{
          'isReqCriSubject': false,
        }],
        'elet_embarc': [{
          'isReqCriSubject': false,
        }],
        'energia': [{
          'isReqCriSubject': false,
        }],
        'instrumentacao': [{
          'isReqCriSubject': false,
        }],
        'med_hospi': [{
          'isReqCriSubject': false,
        }],
        'mobile': [{
          'isReqCriSubject': false,
        }],
        'power': [{
          'isReqCriSubject': false,
        }],
        'telecom': [{
          'isReqCriSubject': false,
        }],
        'ti': [{
          'isReqCriSubject': false,
        }],
        'auto_comer': [{
          'isReqCriSubject': false,
        }],
        'iot': [{
          'isReqCriSubject': false,
        }],

      },
      
      tipo_venda: {
        'nacional': [
            {'isNacionalSubject': false}
        ],
        'exportacao': [
            {'isNacionalSubject': true}
        ]
      }
}
    
   

      

      
     
