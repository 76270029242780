<form  [formGroup]="autenticatorForm"  #formDir="ngForm" class="form-horizontal" name="form" >
    <po-widget class="container po-xl-12 po-lg-12 po-md-12 po-sm-12">

        <div class="imgcontainer">
            <img 
                src="https://hi-mix.com.br/wp-content/uploads/2021/03/Logo-Blue.svg" 
                alt="logo Hi-Mix"  
                class="logo"
            >
        </div>
    
        <div>
            <po-input
                p-label="Código para autenticar"
                formControlName="codAutenticator"
                [p-no-autocomplete]="true"
                [p-upper-case]="true"
                name="codAutenticator" 
                (p-blur)="validCode()"
                (p-enter)="validCode()"
                p-placeholder="Digite o Código para autenticar"
                >
            </po-input>
            
     
            
            <div style="display: flex;align-items: center;justify-content: space-between;">
                <po-button 
                    p-icon="po-icon po-icon-arrow-left"
                    p-type="primary"   
                    class="large-button" 
                    p-label="Voltar"
                    routerLink="/login"
                >
                </po-button>
                <po-button 
                    po-icon=""
                    p-type="primary"   
                    class="large-button" 
                    p-label="Enviar"
                    (p-click)="sendSubmit()"
                    [p-disabled]="!isDisabled"
                >
                </po-button>
            </div>
        </div>
        
       
    
       
    </po-widget>
</form>
