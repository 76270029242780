import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import {PoNavbarIconAction} from '@po-ui/ng-components'
import { PoNavbarLiterals } from '@po-ui/ng-components/lib/components/po-navbar/interfaces/po-navbar-literals.interface';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  title!: string;
  profile: any;
  profileActions: any;

  constructor(
    private router: Router,
    public auth: AuthService,
  ) { 
  
  }

  ngOnInit(): void {
    this.title = `Hi-Mix | ${this.auth.getUserData('NOME_EMPRESA')} | ${this.auth.getUserData('NOME').toLowerCase()}`;

    this.profile = {
      subtitle: this.auth.getUserData('EMAIL').toLowerCase(),
      title: this.auth.getUserData('NOME').toLowerCase()
    };

    this.profileActions = [
      { 
        icon: 'po-icon-exit', 
        label: 'Sair', 
        type: 'danger', 
        separator: true ,
        action: this.logoutHeader.bind(this),
      }
    ];
  }

  reedirect(path: string) {
    this.router.navigate([path])
  }

  logoutHeader(){
    this.auth.logout()
    this.router.navigate(['/login'])
  }
}
