import { Component, OnInit } from '@angular/core';
import { PoMenuItem } from '@po-ui/ng-components';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  menus = [

    { label: 'Homepage', link: '/', icon: 'po-icon po-icon-home', shortLabel: 'Homepage' },
    {
      label: 'Propostas Comerciais', icon: 'cofco-graos', shortLabel: 'Prop. Com', subItems: [
        { label: 'Iniciar Solicitação', link: '/novasolicitacao', shortLabel: 'Ini. Sol.' },
        { label: 'Consultar Solicitações', link: '/consultar', shortLabel: 'Cons. Sol.' }
      ]
    },
    
  ];

  constructor() { }

  ngOnInit(): void {
  }

  

}
