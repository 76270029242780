import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../../environments/environment.prod'
import {AuthenticatorService} from '../../../services/authenticator.service'

@Injectable({
  providedIn: 'root'
})
export class ConsultarService {
  
  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) { }

  
  apiUrl: any;
           

  async gridData(id: number , signal:string | undefined,sizePage:number,filters:any) {
  debugger

    this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
  

    if(sizePage){
      filters.push(
        {
          _field: "limit", 
          _initialValue: sizePage, 
          _finalValue: sizePage, 
          _type: 1
        }
      
      )
    }
    if(id){
      filters.push(
        {
          _field: "id", 
          _initialValue: id, 
          _finalValue: id, 
          _type: 1
        }
      
      )
    }
    if(signal){
      filters.push(
       {
          _field: "signal", 
          _initialValue: signal, 
          _finalValue: signal, 
          _type: 1
        }
      
      )
    }
  

    const requestData = {
      url: this.apiUrl,
      method: 'POST'
    };

    const body = {
      name: "DTS_SOLICITACOES",
      constraints: filters
    };
    
   
    const httpOptions = this.authenticator.connect(requestData)
    
    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: response.content.values,
            totalHits: response.content.values.length
          });
        }
      );
    });
  }


  getFilterOptions(filters:any) {

    debugger
    this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

    const requestData = {
      url: this.apiUrl,
      method: 'POST'
    };

    
    const body = {
      name: "DTS_SOLICITACOES",
      constraints: filters
    };

    const httpOptions = this.authenticator.connect(requestData)
    
    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: response.content.values , 
            totalHits: response.content.values.length , 
            idLastPage: response.content.values.length > 5 ? response.content.values[response.content.values.length-5]['ID'] : ''
          });
        }
      );
    });
  }
}