
<div class="po-row">
    <po-button 
        class="po-md-4" 
        p-icon="po-icon po-icon-close" 
        [p-danger]="true" 
        p-label="Reprovar"
        (p-click)="handleApprovedOrReproved('reproved')"
       > 
    </po-button>
    <po-button 
        class="po-md-4" 
        p-icon="po-icon po-icon-ok" 
        p-label="Aprovar"
        (p-click)="handleApprovedOrReproved('approved')"
    > 
    </po-button>
</div>

<div class="po-xl-12 po-lg-12 po-md-12 po-sm-12" *ngIf="!isReproved">
    <po-textarea
        class="po-md-12"     
        [p-required]="true"
        [p-show-required]="true" 
        [(ngModel)]="campoJustificativa"
      p-label="Justificar Reprovação"
    >
    </po-textarea>
  </div>

    <po-button 
        class="po-md-4" 
        p-icon="po-icon po-icon-ok" 
        p-label="Salvar"  
        (p-click)="handleSave()"
    > 
    </po-button>
