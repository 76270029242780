import { Component, ViewChildren,QueryList,AfterViewInit, ViewChild    } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { NovaSolicitacaoService } from './nova-solicitacao.service';
import { FormBuilder,FormArray, NgForm } from '@angular/forms';
import { delay, finalize, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import {  
          inputsOpenProcess,
          MontagemPropostaContent,
          ComboOptions,
          ClienteContent,
          DdiPaisContent,
          EstadosContent,
          PaisContent,
          ComboOption,
          IMyData,
          tpVenda,
          optionsRevisaoModal,
          yesOrNot,
          optionsRevisao,
          optionsMotivoRevisao,
          segmento,
          idioma,
          moeda,
          tipoNegocio,
          periodContrato,
          incotermoptions,
          tratativaImport,
          seguroExportacao,
          prazoPgto,
          condiEntrega
        } from './nova-solicitacao.interface';
import { 
    PoNotificationService,
    PoToasterOrientation,
    PoStepperOrientation,
    PoComboFilterMode,
    PoModalComponent,
    PoStepperItem, 
    PoStepperStatus,
    PoDialogService,
  } from '@po-ui/ng-components';
import { FormGroup,FormControl,Validators } from '@angular/forms'
import {PropostaComponent} from '../../../Components/Filhos/proposta/proposta.component'
import {NegociacaoComponent} from '../../../Components/Filhos/negociacao/negociacao.component'
import {DocumentosComponent} from '../../../Components/Filhos/documentos/documentos.component'
import { UtilsService } from '../../../services/utils.service';
import Rules from '../../../services/rulesInputs';
import {SharedService} from '../../../shared/shared.service'
import {AuthService} from '../../../services/auth.service'
import * as moment from 'moment';


@Component({
  selector: 'app-nova-solicitacao',
  templateUrl: './nova-solicitacao.component.html',
  styleUrls: ['./nova-solicitacao.component.css']
})
export class NovaSolicitacaoComponent implements AfterViewInit  {
  @ViewChild(PoModalComponent, { static: true }) poModal!: PoModalComponent;
  @ViewChildren(NegociacaoComponent) viewdataList!: QueryList<NegociacaoComponent>;
  element: any;
  
  constructor(
    private service: NovaSolicitacaoService,
    public notification: PoNotificationService,
    private router: Router,
    private sharedService : SharedService,
    private utilsService: UtilsService,
    private auth: AuthService,
    private activatedRoute : ActivatedRoute,
    private poAlert: PoDialogService,
    private fb: FormBuilder
  ) { }

    programEntrega = [
      { value:"forecast",label:"Forecast"},
      { value:"pedido_firme",label:"Pedido Firme"},
    ]

    periodoConsumo = [
      { value:"anual",label:"Anual"},
      { value:"mensal",label:"Mensal"},
      { value:"trimestral",label:"Trimestral"},
      { value:"spot",label:"Spot"}
    ]
  
    tipoProducao1 = [
      { value:"produc_lote_unic",label:"Lote único"},
      { value:"produc_npi",label:"NPI"},
      { value:"produc_serie",label:"Produção em Série"},
      { value:"prototipo",label:"Protótipo"}
    ]
    tipoProducao2 = [
      { value:"produc_lote_unic",label:"Lote único"},
      { value:"produc_npi",label:"NPI"},
      { value:"produc_serie",label:"Produção em Série"},
      { value:"prototipo",label:"Protótipo"}
    ]
    tipoProducao3 = [
      { value:"produc_lote_unic",label:"Lote único"},
      { value:"produc_npi",label:"NPI"},
      { value:"produc_serie",label:"Produção em Série"},
      { value:"prototipo",label:"Protótipo"}
    ]
    tipoProducao4 = [
      { value:"produc_lote_unic",label:"Lote único"},
      { value:"produc_npi",label:"NPI"},
      { value:"produc_serie",label:"Produção em Série"},
      { value:"prototipo",label:"Protótipo"}
    ]

    finalidadeProd = [
      { value:"consu_final",label:"Consumo Final"},
      { value:"industria",label:"Indústria (Matéria Prima ou Consumo)"},
      { value:"revenda",label:"Revenda"},
    ]

    isencaoImpost = [
      { value:"nao_possui",label:"Não Possui Isenção"},
      { value:"possui_ipi",label:"Isenção IPI"},
      { value:"posuui_pis_cofins",label:"Isenção PIS/COFINS"},
      { value:"possui_ipi_piscofins",label:"Isenção do IPI e PIS/COFINS"},
    ]

    especSolda = [
      { value:"lead_free",label:"Lead-free"},
      { value:"tin_lead",label:"Tin-lead"},
      { value:"rohs",label:"RoHS"}
    ]

    ipcaOptions = [
      { value:"class1",label:"Classe 1 - Produtos Eletrônicos de Consumo Geral"},
      { value:"class2",label:"Classe 2 - Produtos Eletrônicos de Serviço Dedicado"},
      { value:"class3",label:"Classe 3 - Produtos Eletrônicos de Alto Desempenho"}
    ]
  
    cobraNre = [
      { value:"reemb_desp",label:"Reembolso de despesas (serviços)"},
      { value:"venda_merc",label:"Venda de Mercadorias"},
      { value:"uso_cons",label:"Uso/Consumo"},
      { value:"amortizar",label:"Amortizar no Preço do Produto"}
    ]

    

    //Options
    tpVenda = tpVenda
    optionsRevisaoModal = optionsRevisaoModal
    yesOrNot = yesOrNot
    optionsRevisao = optionsRevisao
    optionsMotivoRevisao = optionsMotivoRevisao
    segmento = segmento
    idioma = idioma
    moeda = moeda
    tipoNegocio = tipoNegocio
    periodContrato = periodContrato
    incotermoptions = incotermoptions
    tratativaImport = tratativaImport
    seguroExportacao = seguroExportacao
    prazoPgto = prazoPgto
    condiEntrega = condiEntrega
    

    //Options
    properties: any;
    newProcessForm!: FormGroup
    maskDDISoli!:string;
    maskDDITecnico!:string;
    maskDDITeleTecnico!:string;
    maskDDISolicTecnico!:string;
    textoRfqRevisao!:string;
    selectedOptionRevisao!:string;
    selectedOptionNomeProjeto!:string;
    cnpj!: string 
    emailRepresentante!: string 
    numProposta!: any 
    rfq_revisao!: string 
    idDocument: string = ""
    codCliente!: string 
    codFuncao!:number
    base64Output!: string;
    
    //Variaveis de Controle de Exibição
    isBrasil!:boolean;
    isStates!:boolean;
    isNegociacaoPre:boolean = false;
    isProdCorrent:boolean = true;
    isCodProdCorrent:boolean = false;
    isProgramEntrega1:boolean = false;
    isProgramEntrega2:boolean = false;
    isProgramEntrega3:boolean = false;
    isProgramEntrega4:boolean = false;
    isCenario2:boolean = false;
    isCenario3:boolean = false;
    isCenario4:boolean = false;
    isindicProd:boolean = false;
    isPpb:boolean = true;
    isSumaziProdQual:boolean = false;
    isIsencaoImpost:boolean = true;
    isReqCri:boolean = true;
    isObsIsencaoImpost:boolean = false;
    isEstados:boolean = false;
    isLoading:boolean = false;
    revisaoRFQ:boolean = false;
    revisaoRFQCliente:boolean = false;
    isPeriodoContrato:boolean = false;
    isProjeto:boolean = false;
    isOutroPrazo:boolean = false;
    isQual:boolean = false;
    isCkdSkd:boolean = false;
    isLoad:boolean = false;
    isLoadingInput:boolean = false;
    isViewInput:any = true;
    isNacional:boolean = true;
    isCodSap:boolean = true;
    isDisabledBTmodal:boolean = true;
    isRepresentante:boolean = false;
    isDisabledBoxRevisao:boolean = false;
    isBoxRevisao:boolean = false;
    abaDadosCliente:boolean = true;
    abaObjetoProposta:boolean = false;
    abaCenarioNegocio:boolean = false;
    abaDadosAdicionais:boolean = false;
    abaDocumentos:boolean = false;

    orientation: PoStepperOrientation = <PoStepperOrientation>'horizontal';
    placeSteps: PoStepperItem[] = [{label:'Objeto da Proposta1', status:PoStepperStatus.Active}];
    modeFilter: PoComboFilterMode = PoComboFilterMode.contains;
    inputsJson:any = new inputsOpenProcess();
    camposNegociacao: any[] = [];
    camposDocumentos: any[] = [];
    campos: any[] = [];
    optionsClients: any[] = [];
    optionsPaises: any[] = [];
    
    optionsPaisesExportacao: any[] = [];
    optionsDDi: any[] = [];
    optionsCliente: any[] = [];
    optionsCombo: any[] = [];
    clientesModal: any[] = [];
    opstionsClientesModal: any[] = [];
    estados: any[] = [];
    estadosDestino: any[] = [];
    optionsMaterias: any[] = [];
    datasets: string[] = []
    rules: any = Rules;
    comboOptions = new ComboOptions();
    projects:ComboOption[] = []
    dadosFiles: { base64: string; name: string; }[] = [];
    materiais: any[] = [];
    doc_obri_cb1!: any
    options_doc_obri_cb1 = [{ 
      value: 'doc_obri_cb1', 
      label: 'BOM (Com indicação de part numer e AVL**). Para revisões, quando não fornecida a BOM, a Hi-Mix utilizará a versão disponível em seus arquivos.' 
    }]

    doc_obri_cb2!: any
    options_doc_obri_cb2 = [{ 
      value: 'doc_obri_cb2', 
      label: 'Arquivo Gerber.' 
    }]

    doc_obri_cb3!: any
    options_doc_obri_cb13 = [{ 
      value: 'doc_obri_cb3', 
      label: 'Informações do MDA/ICT. Lista de cobertura, especificações, e programa de testes para placas e/ou produto acabado.' 
    }]

    doc_obri_cb4!: any
    options_doc_obri_cb4 = [{ 
      value: 'doc_obri_cb4', 
      label: 'Relação completa dos itens que não devem ser orçados e/ou montados, quando aplicável.' 
    }]

    doc_obri_cb5!: any
    options_doc_obri_cb5 = [{ 
      value: 'doc_obri_cb5', 
      label: 'Relação completa dos itens que serão fornecidos pelo cliente, quando aplicável.' 
    }]

    doc_obri_cb6!: any
    options_doc_obri_cb6 = [{ 
      value: 'doc_obri_cb6', 
      label: 'Relação de itens negociados diretamente entre cliente e fornecedor, quando aplicável.(Regra do Box campo fabricante)' 
    }]

    doc_obri_cb7!: any
    options_doc_obri_cb7 = [{ 
      value: 'doc_obri_cb7', 
      label: 'Desenhos técnicos (cabos, trafos, itens mecânicos,etc), quando aplicável.' 
    }]

    doc_op_cb1!: any
    options_doc_op_cb1 = [{ 
      value: 'doc_op_cb1', 
      label: 'Relação de itens com características especiais e como são identificados.' 
  }]

    doc_op_cb2!: any
    options_doc_op_cb2 = [{ 
      value: 'doc_op_cb2', 
      label: 'Foto do painel ou placa montada. Indicar se há placas-filhas.' 
  }]

    doc_op_cb3!: any
    options_doc_op_cb3 = [{ 
      value: 'doc_op_cb3', 
      label: 'Placas-golden suficientes para debug de engenharia e cotação.' 
  }]

    doc_op_cb4!: any
    options_doc_op_cb4 = [{ 
      value: 'doc_op_cb4', 
      label: 'Fornecer a instrução de montagem (SOP) ou informar os tempos de montagem a serem considerados no orçamento.' 
  }]

    doc_op_cb5!: any
    options_doc_op_cb5 = [{ 
      value: 'doc_op_cb5', 
      label: 'Informações sobre as jigas para teste funcional (layout com foto e descrição). Tempo estimado de teste por painel. Requisitos de teste run-in e burn-in.' 
  }]

    doc_op_cb6!: any
    options_doc_op_cb6 = [{ 
      value: 'doc_op_cb6', 
      label: 'Requisitos de rastreabilidade - chão-de-fábrica (finished good, RMA).' 
  }]

    doc_op_cb7!: any
    options_doc_op_cb7 = [{ 
      value: 'doc_op_cb7', 
      label: 'Regras de etiquetagem (para placa e produto acabado).' 
  }]

    doc_op_cb8!: any
    options_doc_op_cb8 = [{ 
      value: 'doc_op_cb8', 
      label: 'Critérios cosméticos e de abertura entre peças plásticas e materiais correlatos.' 
  }]

    doc_op_cb9!: any
    options_doc_op_cb9 = [{ 
      value: 'doc_op_cb9', 
      label: 'Se há algum tipo de equipamento de teste/tooling que deverá ser desenvolvido ou fornecido pela Hi-Mix (FCT/ICT/outros), enviar arquivos com a descrição completa desses equipamentos de teste e tooling, como gabaritos, pallets, etc, estimando o tempo de vida útil dos mesmos.' 
  }]

    doc_op_cb10!: any
    options_doc_op_cb10 = [{ 
      value: 'doc_op_cb10', 
      label: 'Especificação para embalagem individual, embalagem coletiva, paletes, manuais e guias disponíveis para cotação.' 
  }]

    doc_op_cb11!: any
    options_doc_op_cb11 = [{ 
      value: 'doc_op_cb11', 
      label: 'Se necessário algum tipo de treinamento ao pessoal Hi-Mix enviará um arquivo detalhado sobre o treinamento.' 
  }]

    doc_op_cb12!: any
    options_doc_op_cb12 = [{ 
      value: 'doc_op_cb12', 
      label: 'Se necessário o desenvolvimento de algum RMA plan enviar arquivo detalhado.' 
  }]

    doc_op_cb13!: any
    options_doc_op_cb13 = [{ 
      value: 'doc_op_cb13', 
      label: 'Se necessário o desenvolvimento de algum quality plan (Yield / DPPM / NCM / RR / DOA / etc) enviar arquivo detalhado.' 
  }]

    doc_op_cb14!: any
    options_doc_op_cb14 = [{ 
      value: 'doc_op_cb14', 
      label: 'Se necessário gerenciar o PPAP de Fornecedor enviar arquivos detalhados.' 
  }]

    doc_op_cb15!: any
    options_doc_op_cb15 = [{ 
      value: 'doc_op_cb15', 
      label: 'No caso de itens críticos, enviar documento com método de controle e período de retenção.' 
  }]

    doc_op_cb16!: any
    options_doc_op_cb16 = [{ 
      value: 'doc_op_cb16', 
      label: 'Medição dos pontos mecânicos a serem realizados no relatório PPAP (Processo de Aprovação de Peça de Produção). Informar pontos, quotas a considerar, local de medição (interna ou externa), quantidade de amostras para medição, etc.' 
    }]
    

    selectedOptions = ['modal_rev_quantidade']
 

    nextLabelWidget: string = 'Avançar';
    previousLabelWidget: string = 'Voltar';
    saveLabelWidget: string = 'Enviar';
    isLoadingPayment: boolean = false;
    
    async ngOnInit(): Promise<void> {
      this.activatedRoute.paramMap.subscribe(params => {
        this.idDocument = params.get('id')!;
        if(this.idDocument) this.sharedService.view(true)
     });
      
      this.cnpj = this.auth.getUserData('CNPJ')
      this.codFuncao = this.auth.getUserData('CODFUNCAO')
      this.isRepresentante = this.auth.isRepresentante()
      this.emailRepresentante = this.isRepresentante ? this.auth.getUserData('EMAIL') : ''
      
      
      this.service.formMontagemProposta(this.emailRepresentante).then(({ response }: any) => {
        if(response.length > 0){
            this.comboOptions.clientesModal = response
            this.opstionsClientesModal = response.map((val: MontagemPropostaContent) => {
              const codCliente = val.cod_sap ? val.cod_sap  + ' - ' + val.nome_empresa:   val.nome_empresa
              return { 
                value:codCliente,
                label:codCliente
              }
            })
          }
      })
      
      this.isLoading = true
      this.isViewInput = false

      this.sharedService.isCodSap$.subscribe((value) : any => {
        this.isCodSap = value
      })
      
      
      let dtsPaises = this.service.paises()
      let ddiPaises = this.service.ddiPaises()

      forkJoin([dtsPaises,ddiPaises]).subscribe(([dadosDtsPaises,dadosDdiPaises] : any) => {
        if(dadosDtsPaises.response.length > 0){
            this.comboOptions.paises = dadosDtsPaises.response
            this.optionsPaises = dadosDtsPaises.response.map((val: PaisContent) => {
              return { 
                value:val.codPais,
                label:val.pesquisa 
              }
            })

            this.optionsPaisesExportacao = dadosDtsPaises.response
              .filter((val: any) => val.descricao !== 'Brasil')
              .map((val: any) => ({
                value: val.codPais,
                label: val.pesquisa
              }));
        }

        if(dadosDdiPaises.response.length > 0){
                this.comboOptions.ddi = dadosDdiPaises.response
                this.optionsDDi = dadosDdiPaises.response.map((val: DdiPaisContent) => {
                  return { 
                    value:val.DDI,
                    label:val.PESQUISA 
                  }
                })
        }
      })

      if(!this.idDocument){
          this.isViewInput = false
          this.isLoading = false
          this.isLoadingInput = false
          this.openModal()
      
        }else{
          const { response }:any = await this.service.getDataFormulario(this.idDocument);
          this.popularCamposFormulario(this.idDocument)  
          this.isLoading = false
      }

      this.newProcessForm = new FormGroup({
        cliente_corrente: new FormControl('', [Validators.required]),
        cod_sap: new FormControl(''),
        cnpj: new FormControl('', Validators.required),
        nome_empresa: new FormControl('', Validators.required),
        pais: new FormControl('', Validators.required),
        cep: new FormControl('', Validators.required),
        estado: new FormControl('', Validators.required),
        cidade: new FormControl('', Validators.required),
        segmento: new FormControl('', Validators.required),
        nome_solic: new FormControl('', Validators.required),
        email_solic: new FormControl('', Validators.required),
        ddi_solic: new FormControl(''),
        tel_solic: new FormControl(''),
        cel_solic: new FormControl(''),
        nome_tec: new FormControl(''),
        ddi_tec: new FormControl(''),
        tel_tec: new FormControl(''),
        cel_tec: new FormControl(''),
        email_tec: new FormControl(''),
        nome_projeto: new FormControl('', Validators.required),
        tipo_venda: new FormControl('', Validators.required),
        idioma_proposta: new FormControl('', Validators.required),
        moeda: new FormControl('', Validators.required),
        neg_prev_forn: new FormControl('', Validators.required),
        tipo_negocio: new FormControl('', Validators.required),
        pais_fatura: new FormControl('', Validators.required),
        uf_fatura_br: new FormControl(''),
        uf_fatura: new FormControl(''),
        autoriza_divulg: new FormControl(''),
        period_contrato: new FormControl('', Validators.required),
        outro_periodo: new FormControl(''),
        prazo_pgto: new FormControl('a_vista', Validators.required),
        outro_prazo_pgto: new FormControl('',),
        condi_entrega: new FormControl(''),
        obs_cenario: new FormControl(''),
        obs_neg_prev_fornc: new FormControl(''),
        prazo_pgto_fornec: new FormControl('', Validators.required),
        moeda_invo_bom: new FormControl('', Validators.required),
        valor_invo_bom: new FormControl('', Validators.required),
        incoterm: new FormControl('', Validators.required),
        pais_origem_fornec: new FormControl('', Validators.required),
        qtde_envio: new FormControl(''),
        peso_envio: new FormControl(''),
        cubagem_envio: new FormControl(''),
        tratativa_import: new FormControl('', Validators.required),
        qual: new FormControl(''),
        obs_consolid_fornec_indicado: new FormControl(''),
        pais_destino: new FormControl('', Validators.required),
        estado_destino: new FormControl('', Validators.required),
        incoterm_exportacao: new FormControl('', Validators.required),
        certif_origem: new FormControl('', Validators.required),
        seguro_exportacao: new FormControl('', Validators.required),
        obs_exportacao: new FormControl(''),
        linkAnexos: new FormControl(''),
        obsDocumentos: new FormControl(''),
        nomeProjeto: new FormControl(''),
        proposta: new FormArray([]),
        negocicao: new FormArray([]),
        documentos: new FormArray([])
      }); 

     
    }
    seachClient(value:string){
      const hasClient = this.optionsClients.some(val => val.label.includes(value.toUpperCase()));

      if(!hasClient){
        this.service.searchClientes(value,this.codFuncao,this.emailRepresentante).then(({ response }: any) => {
          if(response.length > 0){
            //this.comboOptions.clientes = response
            this.optionsClients = response.map((val: ClienteContent) => {
                return { 
                  value:val.CODIGOCLIENTE,
                  label:val.PESQUISA 
                }
              })
            }
        })
      } 
    }
    async salvarModal(){


      const listClientes: any = await this.service.clienteSAP(this.codFuncao,this.emailRepresentante)
      const { response } = listClientes
      this.comboOptions.clientes = response
      response.forEach((val: ClienteContent) =>{
          this.optionsClients.push({ 
            value:val.CODIGOCLIENTE,
            label:val.PESQUISA 
          })

      })

      this.numRfq();
      this.poModal.close();
      if(!this.isRepresentante) this.possuiCadastro()  
    }


    ngAfterViewInit() {
    
    }
  

  async process(jsonFile:[{}]){
    this.isLoading = true;
    const params = this.prepareJSON()
    params['jsonPastas'] = JSON.stringify(jsonFile)

    const { response }:any = await this.service.startProcess(params);

    if(response[0].NUM_PROCESSO != 'FALHA'){
      this.notification.success({ 
        message: `Realizado a Abertura da Gestão da Proposta Comercial ${response[0].NUM_PROCESSO}`, 
        duration: 3000,
        orientation: PoToasterOrientation.Top
    });

    this.router.navigate([''])
    }else{
      this.notification.error({ 
        message: 'Erro ao realizar a abertura da solicitação', 
        duration: 3000,
        orientation: PoToasterOrientation.Top 
      });
    }
    
    this.isLoading = false;
  }

  private prepareJSON() {
    
    const preparedFilters: any = {};

    preparedFilters['emailSolicitante'] = this.auth.getUserData('EMAIL')
    preparedFilters['nome'] = this.auth.getUserData('NOME').toLowerCase()

    preparedFilters['numProposta'] = this.numProposta
    preparedFilters['rfq_revisao'] = this.rfq_revisao
    preparedFilters['data_abertura'] = moment(new Date()).format('DD/MM/YYYY')

  
    Object.keys(this.newProcessForm.value).forEach(campos =>{
      const value = this.newProcessForm.value[campos]
      let count:number = 0
      if (value && value.length > 0) {
        if((campos == 'proposta' || campos == 'negocicao' || campos == 'documentos') ){
          const inputsChildren = Object.keys(value[0])
          value.forEach((children:any) =>{
            count++
            inputsChildren.forEach(ids=>{
              const valueId = children[ids] ? children[ids] : ''
              if(ids == "item_prod_proposta"){
                preparedFilters[ids+'___'+count] = count
              }else if(ids.includes('prazo_entreg_consumo')){
                preparedFilters[ids+'___'+count] = moment(valueId,"YYYY-MM-DD").format('DD/MM/YYYY')
              }else{
                preparedFilters[ids+'___'+count] = typeof valueId == 'object' ? String(valueId) : valueId
              }
            })  
          })
        }else{
          preparedFilters[campos] = value;
        }
      }
  })

    return preparedFilters
  }

  async onSubmit() {
    const jsonFile :any = []

    if(this.dadosFiles.length > 0){
      const { response }:any = await this.service.createrFolder(this.textoRfqRevisao);

      for (let dado of this.dadosFiles) {
        const { codFile }:any = await this.service.attachFiles(response[0], dado);
        debugger
        jsonFile.push({
          "parentId": response[0].documentId,
          "idFolder": codFile,
          "nameFile": dado.name
        });
      }
    }

    await this.process(jsonFile)
    debugger
  }


  validarcnpj(cnpj:string) {
    debugger
     cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

     if(cnpj == '') return false

     if (cnpj.length !== 14 ) {
       

        return false;
    }

    // Verifica se todos os dígitos são iguais (ex: 00000000000000)
    if (/^(\d)\1+$/.test(cnpj)) {
        this.notification.warning({ 
          message: 'CNPJ inválido. Não são permitidos CNPJs com todos os dígitos iguais', 
          duration: 3000,
          orientation: PoToasterOrientation.Top 
        });
        

        return false;
    }

    // Calcula o primeiro dígito verificador
    let soma = 0;
    let peso = 2;
    for (let i = 11; i >= 0; i--) {
        soma += parseInt(cnpj.charAt(i)) * peso;
        peso = (peso === 9) ? 2 : peso + 1;
    }
    


    let digitoVerificador = (soma % 11 < 2) ? 0 : 11 - (soma % 11);
    if (parseInt(cnpj.charAt(12)) !== digitoVerificador) {
        this.notification.warning({ 
          message: 'CNPJ inválido. Digito verificador 1 não corresponde.', 
          duration: 3000,
          orientation: PoToasterOrientation.Top 
        });
        
        return false;
    }

    // Calcula o segundo dígito verificador
    soma = 0;
    peso = 2;
    for (let i = 12; i >= 0; i--) {
        soma += parseInt(cnpj.charAt(i)) * peso;
        peso = (peso === 9) ? 2 : peso + 1;
    }

    digitoVerificador = (soma % 11 < 2) ? 0 : 11 - (soma % 11);
    if (parseInt(cnpj.charAt(13)) !== digitoVerificador) {
        this.notification.warning({ 
          message: 'CNPJ inválido. Digito verificador 2 não corresponde.', 
          duration: 3000,
          orientation: PoToasterOrientation.Top 
        });
        return false;
    }
    

    this.utilsService.consultarCNPJ(cnpj).then(({ response }: any)=> {
      const { nome, municipio, uf, cep } = response;

      this.inputsJson.nome_empresa = nome
      this.inputsJson.cidade = municipio
      this.inputsJson.estado = uf
      this.newProcessForm.get('pais')?.setValue('BR')
      this.inputsJson.cep = cep.replace('.','')
    })
   
    return true;  
  }

  async findCep(cep:string){
    await this.service.consultaCEP(cep).then(({ response }: any)=> {
      const {localidade,uf} = response
      
      this.inputsJson.cidade = localidade
      this.inputsJson.estado = uf
  
    })
  }

  async adicionarCampo(table: string,{content,campos}:any) : Promise<boolean> {
    const projeto = this.inputsJson.nome_projeto
    const linesDocumentValue = this.sharedService.linesDocumentValue;

   
      if(table == 'proposta' ){

        
        this.sharedService.lineProd()
        this.camposNegociacao.push({ componentType: PropostaComponent });

      }else if(table == 'documentos' ){
        if(this.camposNegociacao.length == 0){
          this.notification.warning({ 
            message: 'Para abrir os documentos, deve-se adicionar, pelo menos, um produto na aba "objeto da proposta"!', 
            duration: 3000,
            orientation: PoToasterOrientation.Top 
          });
          return false;
        }

        if(this.camposNegociacao.length == this.camposDocumentos.length && !this.isViewInput){
          this.notification.warning({ 
            message: 'Para abrir mais documentos, deve-se adicionar mais produtos na aba "Objeto da Proposta"!', 
            duration: 3000,
            orientation: PoToasterOrientation.Top 
          })

          return false;
          }

          if(linesDocumentValue == 0){
            this.notification.warning({ 
              message: 'Para adicionar documentos, deve ser selecionado os Produtos na aba "Objeto da Proposta"', 
              duration: 3000,
              orientation: PoToasterOrientation.Top 
            })
    
            return false;
          }

         
            
            this.camposDocumentos.push({ componentType: DocumentosComponent });
         

        
      }else{
        // for (const iterator of content) {
          this.sharedService.negociacao(content,campos)
          this.campos.push({ componentType: Object.assign(NegociacaoComponent,{}) });
        //  }
          
      }
    
    return true;
  }

  preencherInfosProduto(value: string){
    const {CODIGOMATERIAL,NCM,DESCRICAO} = this.materiais.find(val => val.CODIGOMATERIAL == value)
    
    this.inputsJson.cod_produto = CODIGOMATERIAL
    this.inputsJson.ncm = NCM;
    this.inputsJson.mod_pn_prod = DESCRICAO
    this.sharedService.insertDocuments(DESCRICAO);

    this.addProductDocuments(DESCRICAO)

  } 

  addProductDocuments(value:any){
    this.addDocumentos(value)
  }


  habilitInput(campo: string){    

    if(campo == 'nao'){
      this.inputsJson.cnpj = ''
      this.inputsJson.nome_empresa = '';
      this.inputsJson.pais = '';
      this.inputsJson.cep = 0;
      this.inputsJson.estado = '';
      this.inputsJson.cidade = '';
    }
  }

  

  async enableOrDisabled(campo: any){   
    const { name,selectedValue } = campo

    if(name == 'produto_corrente' && selectedValue == 'sim'){
      this.isCodProdCorrent = true
    }else if(name == 'produto_corrente' && selectedValue == 'nao'){
      this.isCodProdCorrent = false
    }

    if(name == 'tipo_venda'){
      const showImpost = selectedValue == 'nacional' ? false : true
      this.isIsencaoImpost = showImpost
    }

    const regras = this.rules[`${name}`][`${selectedValue}`];
    this.sharedService.tipoVendaSubject.next(selectedValue);
    
    for (let regra of regras) {
      let inputKeys: any[] = Object.keys(regra);
      (this as any).sharedService[`${inputKeys[0]}`].next(regra[inputKeys[0]]);
    }

    if(name == "cliente_corrente"){
      this.newProcessForm.get('cod_sap')?.setValue('')
      this.inputsJson.cnpj = ''
      this.inputsJson.nome_empresa = '';
      this.newProcessForm.get('pais')?.setValue('')
      this.inputsJson.cep = '';
      this.inputsJson.estado = '';
      this.inputsJson.cidade = '';

      if(selectedValue == 'sim'){
        this.isProdCorrent = true
      }else{
        this.isProdCorrent = false
      }
    }
    
  }

  enableOrDisabledlocal(campo: any){   
    const { name,selectedValue }= campo
    let nameVar = ''

    if(name == 'periodo_consumo1' || name == 'periodo_consumo2' || name == 'periodo_consumo3' || name == 'periodo_consumo4'){
     
      //  this.propostaForm.get(`tipo_producao${name[name.length - 1]}`)?.setValue('');
       
       nameVar = `isProgramEntrega${[`${name[name.length - 1]}`]}` ;
       (this as any)[`tipoProducao${name[name.length - 1]}`] = [];
       (this as any)[`tipoProducao${name[name.length - 1]}`] = [
         { value:"produc_lote_unic",label:"Lote único"},
         { value:"produc_npi",label:"NPI"},
         { value:"produc_serie",label:"Produção em Série"},
         { value:"prototipo",label:"Protótipo"}
       ];
       if(selectedValue == 'spot'){
         (this as any)[`${[nameVar]}`] = false;
         (this as any)[`tipoProducao${name[name.length - 1]}`] = (this as any)[`tipoProducao${name[name.length - 1]}`].filter((item: { value: string; }):any => item.value !== "produc_serie");
 
       }else{
         (this as any)[`${[nameVar]}`] = true;
         (this as any)[`tipoProducao${name[name.length - 1]}`] = (this as any)[`tipoProducao${name[name.length - 1]}`].filter((item: { value: string; }):any => item.value == "produc_serie");
       }
     }
     
     if(name == 'qtde_consumo2' || name == 'qtde_consumo3' || name == 'qtde_consumo4'){
         nameVar = `isCenario${name[name.length - 1]}`;  
 
         (this as any)[`${[nameVar]}`] = !(this as any)[`${[nameVar]}`]
         
     } 

     if(name == 'isencao_impost'){

      if(selectedValue == 'nao_possui'){
        this.isObsIsencaoImpost = false
      }else{
        this.isObsIsencaoImpost = true
      }
  } 
  
  if(name == 'sumazi_prod'){

      if(selectedValue == 'sim'){
        this.isSumaziProdQual = true
      }else{
        this.isSumaziProdQual = false
      }
  } 


   if(name == 'period_contrato' && selectedValue == 'outros'){
    this.isPeriodoContrato = true
   }

   if(name == 'period_contrato' && selectedValue != 'outros'){
    this.isPeriodoContrato = false
   }

   if(name == 'prazo_pgto' && selectedValue == 'outros'){
    this.isOutroPrazo = true
   }

   
   if(name == 'prazo_pgto' && selectedValue != 'outros'){
    this.isOutroPrazo = false
   }

   if(name == 'tratativa_import' && selectedValue == 'outra'){
    this.isQual = true
   }

   
   if(name == 'tratativa_import' && selectedValue != 'outra'){
    this.isQual = false
   }
   
   if(name == 'neg_prev_forn' && selectedValue == 'sim'){
     this.isNegociacaoPre = true
    }
    
    if(name == 'neg_prev_forn' && selectedValue != 'sim'){
      this.isNegociacaoPre = false
    }

    if(name == 'simi_prod' ){
      this.isindicProd = selectedValue == 'sim' ? true : false
    }

    if(name == 'tipo_negocio'){
      const valueToRemove = "ptp_business";
      const indexToRemove = this.prazoPgto.findIndex(item => item.value === valueToRemove);
      
      if(selectedValue == 'skd' || selectedValue == 'ckd'){
        this.isCkdSkd = true
        if (indexToRemove !== -1) {
          this.prazoPgto.splice(indexToRemove, 1);
        }
      }else{
        this.isCkdSkd = false
        if (indexToRemove == -1) {
          this.prazoPgto.push( { value: "ptp_business", label: "Pay to Pay Business Model" })
        }
      }
      
      
    }
    

  }

  openModal() {
    this.poModal.open();
  }


  async preencherClients(value: string){
    this.inputsJson.cidade = ''
    this.inputsJson.estado = ''
    
    const {CNPJ,NOMEEMPRESA,PAIS,CEP,CODIGOCLIENTE}:any = this.comboOptions.clientes.find((val:any) => val.CODIGOCLIENTE == value)
    
    this.inputsJson.cnpj = CNPJ != null ? this.utilsService.formatarCnpj(CNPJ) : '0'
    this.inputsJson.nome_empresa = NOMEEMPRESA;
    this.newProcessForm.get('pais')?.setValue(PAIS)
    this.inputsJson.cep = CEP;
    await this.findCep(CEP)

    this.service.getMateriasOptions(CODIGOCLIENTE).then(({ response }: any)=> {

      for (let data of response) {
        this.materiais.push(data)
      }

      this.optionsMaterias = response.map((val: { CODIGOMATERIAL: string; PESQUISA: string; }) => {
        return { value:val.CODIGOMATERIAL,label:val.PESQUISA }
      })
    })
  }

  isMaskSolicitante(value: string){
    if(value != '55'){
      this.maskDDISoli = ''
      this.maskDDISolicTecnico = ''
    }else{
      this.maskDDISoli = "(99) 99999-9999"
      this.maskDDISolicTecnico = "(99) 9999-9999"
    } 
  }

  isMaskTecnico(value: string){
    if(value != '55'){
      this.maskDDITecnico = ''
      this.maskDDITeleTecnico = ''
    }else{
      this.maskDDITecnico = "(99) 99999-9999"
      this.maskDDITeleTecnico = "(99) 9999-9999"
    } 
  }

  async howPais(value: string, name?: string){
    const { descricao } : any  = this.comboOptions.paises.find((item:any) => item.codPais == value )
    this.service.getEstados(descricao).then(({ response }: any)=> {

      
      if(response.length > 1){

         const estados = response.map((val: EstadosContent) => {
          return { 
            value:val.codRegiao,
            label:val.pesquisa 
          }
       })

       if(name == 'pais_fatura'){
        this.estados = estados

        this.isBrasil = true      
        this.isStates = false
       }else{
        this.estadosDestino = estados
        this.isEstados = true
       }
          
      }else{
          this.isEstados = false
          this.isStates = true      
          this.isBrasil = false

      }
  })

   
  }

  choiseModalRfq({selectedValue} : any){
    this.selectedOptionRevisao = selectedValue;

    if(selectedValue == 'nova'){
      this.isDisabledBTmodal = false
      this.revisaoRFQ = false
      this.isProjeto = false
      this.revisaoRFQCliente = false
    }else{
      this.revisaoRFQ = true
      this.isDisabledBTmodal = true
    }
    
  }
  


    selectClienteRfq(values : any){
      const {name,selectedOption} = values
      const clientesModal = this.comboOptions.clientesModal as any[];
      this.projects = []

      if(name == "motivoRevisao"){
        this.revisaoRFQCliente = true
        if(!this.isRepresentante){
          this.isProjeto = true

          let values = this.removerDuplicatas(clientesModal)
              values = values.filter(val => val.nome_empresa == this.auth.getUserData('NOME_EMPRESA'))
          for (const clienteModal of values) {
              this.projects.push({
                value: `${clienteModal.documentid}-${clienteModal.rfq_revisao}-${clienteModal.numProposta}`,
                label: `RFQ: ${clienteModal.numProposta} - REV: ${clienteModal.rfq_revisao}`
              })
          }   
        }
      }else if(name == "modalCliente"){
        this.newProcessForm.get('nomeProjeto')?.setValue('');
        this.isProjeto = true
        const [ codCliente,nomeCliente ]= selectedOption.label.split(' - ')
        const values = this.removerDuplicatas(clientesModal).filter(val => val.nome_empresa == nomeCliente)
        
        for (const clienteModal of values) {
            this.projects.push({
              value: `${clienteModal.documentid}-${clienteModal.rfq_revisao}-${clienteModal.numProposta}`,
              label: `RFQ: ${clienteModal.numProposta} - REV: ${clienteModal.rfq_revisao}`
            })
           
      }

      }else if(name == "nomeProjeto"){
        this.isDisabledBTmodal = false
        // this.isDisabledBoxRevisao = true
        this.selectedOptionNomeProjeto = selectedOption.value
      }
     
  }

  removerDuplicatas(dados: any) {
    const result: IMyData[] = [];
    const map: { [key: string]: IMyData } = {};
  
    dados.forEach(function (item: IMyData) {
      let key: string = item.nome_projeto + item.numProposta + item.rfq_revisao;
  
      if (!(key in map)) {
        map[key] = item;
      }
    });
  
    for (var key in map) {
      result.push(map[key]);
    }
  
    return result;
  }
  

  async numRfq(){
    const value:string = this.selectedOptionRevisao

    if(value == 'nova'){
      const retornoReservaRfq:any = await this.service.dtsNumRFQ('GET')
      let newNumProcesso = 0
      const totalNumProcess = retornoReservaRfq.response.filter((obj: { num_rev: string; }) => obj.num_rev === "000")
      const numRFQ = parseInt(totalNumProcess.map((obj: { num_rfq: string; }) => obj.num_rfq)[0]);
      const rev = totalNumProcess[0].num_rev
      const reserva = totalNumProcess.map((obj: { bool_reserva: string; }) => obj.bool_reserva)[0];
      const using = totalNumProcess.map((obj: { bool_usado: string; }) => obj.bool_usado)[0];

      newNumProcesso = reserva == 'X' || using == 'X' ? numRFQ + 1 : numRFQ

      const result = await this.service.dtsNumRFQ('PUT', newNumProcesso, rev, 'X','')
      
      this.numProposta = newNumProcesso
      this.rfq_revisao = rev
      this.textoRfqRevisao = `RFQ: ${newNumProcesso}  - Revisão: ${rev}`
  }else{
      this.isBoxRevisao = true
      const [idDocument,nRevisao,numRFQ] = this.selectedOptionNomeProjeto.split('-')
      const result = (parseFloat(nRevisao) + 1).toFixed(3); 
      const nRFQ = result.split('.')[0].padStart(3, '0')

      this.textoRfqRevisao = `RFQ: ${numRFQ}  - Revisão: ${nRFQ}`
      this.numProposta = numRFQ
      this.rfq_revisao = nRFQ
      await this.popularCamposFormulario(idDocument)
    }
  }

  async popularCamposFormulario(idSolicitacao:string){
    const params:ComboOption = this.prepareFilter('documentId',idSolicitacao)
    const clientesModal = this.comboOptions.clientesModal as any[];
    const valueFormSolici = clientesModal.find(item => item.documentid == idSolicitacao)

    
    Object.entries(valueFormSolici).forEach(async ([key, value]:any) => {
      
      if(key == 'nome_projeto'){
        this.inputsJson.nome_projeto = value
      }else if(key == "cod_sap" && value){
        this.isCodSap = true;
      }else if(key == "neg_prev_forn" && value && value == 'sim'){
        this.isNegociacaoPre = true
      }else if(key == "tipo_negocio" && value && (value == 'skd' || value == 'ckd') ){
        this.isCkdSkd = true
      }else if(key == "pais_fatura" ){
        await this.howPais(value)
      }else if(key == 'tipo_venda'){
        const showImpost = value == 'nacional' ? false : true
        this.isIsencaoImpost = showImpost
      }


        this.newProcessForm.get(key)?.setValue(value)
    });    
  
    
    this.datasets = []
    this.datasets.push('DTS_PAIXFILHO_PRODUTO_PROPOSTA','DTS_PAIXFILHO_NEGOCIACAO','DTS_PAIXFILHO_DOCUMENTOS')

    this.service.getDataApi(this.datasets,params)
    .then(async results => {
      for (let data of results){
        const {dataset,content,campos}:any = data;

        if(content.length != 0){

          if(dataset == 'DTS_PAIXFILHO_PRODUTO_PROPOSTA'){
            content.forEach((item:any) => {
              const group = this.fb.group({});
              campos.forEach((campo:any) => {
                let value = item[campo];
                if(campo.includes("program_entrega") && value){
                  (this as any)[`isProgramEntrega${campo[campo.length - 1]}`] = true
                }else if(campo.includes("periodo_consumo") && value){
                  (this as any)[`isCenario${campo[campo.length - 1]}`] = true
                }else if(campo == "produto_corrente" && value){
                  this.isProdCorrent = true
                }else if(campo.includes("qtde_consumo") && value){
                  (this as any)[`isCenario${campo[campo.length - 1]}`] = true
                }else if(campo.includes("prazo_entreg_consumo") && value && value != "Invalid date"){
                  value = moment(value, 'DD/MM/YYYY').toDate(); 
                }


                group.addControl(campo, new FormControl(value));
                
              })
              this.proposta.push(group);
          })
          }else if (dataset == 'DTS_PAIXFILHO_DOCUMENTOS') {
            content.forEach((item: any) => {
              const group = this.fb.group({});
              campos.forEach((campo: any) => {
                group.addControl(campo, new FormControl(item[campo]));
              });
              this.documentos.push(group);
            });
          }else if (dataset == 'DTS_PAIXFILHO_NEGOCIACAO') {
            content.forEach((item: any) => {
              const group = this.fb.group({});
              campos.forEach((campo: any) => {
                group.addControl(campo, new FormControl(item[campo]));
              });
              this.negocicao.push(group);
            });
          }
        }

      }
    })
  }

  removeComponent(campo: any) {
    if(campo.componentType.name == 'PropostaComponent'){
      const index = this.camposNegociacao.indexOf(campo);
      if (index !== -1) {
        this.camposNegociacao.splice(index, 1);
      }
    }else if(campo.componentType.name == 'NegociacaoComponent'){
      const index = this.campos.indexOf(campo);
      if (index !== -1) {
        this.campos.splice(index, 1);
      }
    }else{
      const index = this.camposDocumentos.indexOf(campo);
      if (index !== -1) {
        this.camposDocumentos.splice(index, 1);
      }
    }
    
  }

  prepareFilter(label:string,value:string){
    return {
      label,
      value
    }
  }

  save(){
    alert('Salvo')
  }

  closeModal() {
    this.poModal.close();
    this.router.navigate(["/"])
  }

  possuiCadastro(){
    
    const value:any = this.comboOptions.clientes.find((val:any) => val.CNPJ ==  this.cnpj)

    if(value){
      const {CNPJ,NOMEEMPRESA,CEP,ESTADO,CIDADE,PAIS} = value
      this.inputsJson.cnpj = CNPJ
      this.inputsJson.nome_empresa = NOMEEMPRESA;
      this.newProcessForm.get('pais')?.setValue(PAIS)
      this.newProcessForm.get('cliente_corrente')?.setValue('sim')
      this.inputsJson.cep = CEP;
      this.inputsJson.estado = ESTADO;
      this.inputsJson.cidade = CIDADE;
    }else{
      this.newProcessForm.get('cliente_corrente')?.setValue('nao')

    }
  
  }

  formatarValor(dados: any, decimais:number){
    const valor = dados.target ? dados.target.value : dados.inputEl.nativeElement.value

    if(valor != ''){
      let valorNumerico = parseInt(valor.replace(/\D/g, ''), 10);
      if(decimais == 2) valorNumerico = valorNumerico / 100; 
      if(decimais == 4) valorNumerico = valorNumerico / 1000; 

      const valueFinal = valorNumerico.toLocaleString('pt-BR', { minimumFractionDigits: decimais, maximumFractionDigits: decimais }); 
      
      if(dados.target){
        dados.target.value = valueFinal
      }else{
        dados.inputEl.nativeElement.value = valueFinal
      }
    }
    
  }

  hideOrShow(abaAtual:string){
    const inputsValidate = {
      "dadosCliente":[
        {
            id:'cliente_corrente',
            label:'Cliente Corrente'
        },{
            id:'cnpj',
            label:'Cnpj'
        },{
            id:'nome_empresa',
            label:'Nome da Empresa'
        },{
            id:'pais',
            label:'País'
        },{
            id:'cep',
            label:'CEP'
        },{
            id:'estado',
            label:'Estado'
        },{
            id:'cidade',
            label:'Cidade'
        },{
            id:'segmento',
            label:'Segmento'
        },{
            id:'nome_solic',
            label:'Nome Completo do Solicitante'
        },{
            id:'email_solic',
            label:'E-mail Solicitante'
        }
  ],
    "objetoProposta":[{
      id:'nome_projeto',
      label:"Nome do Projeto"
    },{
      id:'tipo_venda',
      label:"Tipo de Venda"
    },{
      id:'idioma_proposta',
      label:"Idioma da Proposta"
    },{
      id:'moeda',
      label:"Moeda da Proposta"
    },{
      id:'neg_prev_forn',
      label:"Componentes previamente negociados"
    },{
      id:'tipo_negocio',
      label:"Tipo de Negócio"
    }],
    "cenarioNegocio":[{
      id:'pais_fatura',
      label:"País para Faturamento"
    },{
      id:'autoriza_divulg',
      label:"Autoriza divulgação para fornecedores?" 
    },{
      id:'period_contrato',
      label:"Período de Contrato"
    },{
      id:'prazo_pgto',
      label:"Prazo de Pagamento"
    }],
    "CKDSKD":[{
      id:'prazo_pgto_fornec',
      label:"Prazo Pagamento Fornecedor"
    },{
      id:'moeda_invo_bom',
      label:"Moeda da invoice ou da BOM" 
    },{
      id:'valor_invo_bom',
      label:"Valor da Invoice ou da BOM"
    },{
      id:'incoterm',
      label:"Incoterm"
    },{
      id:'pais_origem_fornec',
      label:"País de Origem"
    }],
    "exportacao":[{
      id:'pais_destino',
      label:"País Destino"
    },{
      id:'estado_destino',
      label:"Estado Destino" 
    },{
      id:'incoterm_exportacao',
      label:"Incoterm"
    },{
      id:'certif_origem',
      label:"Certificado de Origem"
    },{
      id:'seguro_exportacao',
      label:"Seguro"
    }]
  }
    if(abaAtual == 'dadosCliente'){
      this.isCodSap ? inputsValidate.dadosCliente.push({
        id:'cod_sap',
        label:"Código SAP"
      }) : ''
      
      
      const isValid = this.validateInputs(inputsValidate[abaAtual])
      if(!isValid) return 
      this.abaDadosCliente = false
      this.abaObjetoProposta = true
    }else if(abaAtual == 'objetoProposta'){
      const isValid = this.validateInputs(inputsValidate[abaAtual])
      if(!isValid) return 
      this.abaObjetoProposta = false
      this.abaCenarioNegocio = true
    }else if(abaAtual == 'objetoPropostaVolta'){
      this.abaDadosCliente = true
      this.abaObjetoProposta = false
    }else if(abaAtual == 'cenarioNegocio'){
      const isValid = this.validateInputs(inputsValidate[abaAtual])
      if(!isValid) return 
      this.abaCenarioNegocio = false

      if(this.isIsencaoImpost || this.isCkdSkd || this.isNegociacaoPre){
        this.abaDadosAdicionais = true
      }else{
        this.abaDocumentos = true
      }
    }else if(abaAtual == 'cenarioNegocioVolta'){
      this.abaObjetoProposta = true
      this.abaCenarioNegocio = false
    }else if(abaAtual == 'dadosAdicionaisVolta'){
      this.abaCenarioNegocio = true
      this.abaDadosAdicionais = false
    }else if(abaAtual == 'dadosAdicionais'){
      if(this.isIsencaoImpost) {
        const isValid = this.validateInputs(inputsValidate['exportacao'])
        if(!isValid) return 
      }
      
      if(this.isCkdSkd){
        const isValid = this.validateInputs(inputsValidate['CKDSKD'])
        if(!isValid) return 
      }
      
      
      
        
      this.abaDocumentos = true
      this.abaDadosAdicionais = false
    }else if(abaAtual == 'documentosVolta'){
      if(this.isIsencaoImpost || this.isCkdSkd || this.isNegociacaoPre){
        this.abaDadosAdicionais = true
      }else{
        this.abaCenarioNegocio = true
      }
      this.abaDocumentos = false
    }

    
  }

  validateInputs(inputs:any){
    const campos = []
    for (let input of inputs) {
      if (this.newProcessForm.controls[input.id].invalid) {
        this.newProcessForm.controls[input.id].markAllAsTouched()
        campos.push(input.label)
      }else{
        this.newProcessForm.controls[input.id].markAsUntouched()
      }
    }

    if(campos.length > 0 ){
      this.notification.error({ 
        message: `Os campos são obrigatorios: ${campos}`, 
        duration: 3000,
        orientation: PoToasterOrientation.Top
      });
    }
   

    return campos.length > 0 ? false : true
  }

  deleteProposta(i:number){
    this.proposta.removeAt(i)
  }

  deleteNegocicacao(i:number){
    this.negocicao.removeAt(i)
  }

  get proposta(){
    return this.newProcessForm.get('proposta') as any
  }

  get negocicao(){
    return this.newProcessForm.get('negocicao') as any
  }

  get documentos(){
    return this.newProcessForm.get('documentos') as any
  }


  async addProposta(value?:any){
    if(value == ""){
      this.notification.warning({ 
        message: 'Para adicionar produtos, deve-se dar nome ao projeto!', 
        duration: 3000,
        orientation: PoToasterOrientation.Top 
      });
      return ;
    }


    if(this.proposta.value.length > 0){
      this.copiarRegistros()
    }else{
      this.createInputs()
    } 
  }

  addNegocicao(){
    this.negocicao.push(
      new FormGroup({
        pn_negciado: new FormControl(''),
        pn_fabricante: new FormControl(''),
        descr_pn_negociado: new FormControl(''),
        moeda_pn_negociado: new FormControl(''),
        custo_pn_negociado: new FormControl('')
      })
    )
  }

  addDocumentos(produto:string){
    this.documentos.push(
      this.fb.group({
        produto_documentos: [produto],
        doc_obri_cb1: [''],
        doc_obri_cb2: [''],
        doc_obri_cb3: [''],
        doc_obri_cb4: [''],
        doc_obri_cb5: [''],
        doc_obri_cb6: [''],
        doc_obri_cb7: [''],
        doc_op_cb1: [''],
        doc_op_cb2: [''],
        doc_op_cb3: [''],
        doc_op_cb4: [''],
        doc_op_cb5: [''],
        doc_op_cb6: [''],
        doc_op_cb7: [''],
        doc_op_cb8: [''],
        doc_op_cb9: [''],
        doc_op_cb10: [''],
        doc_op_cb11: [''],
        doc_op_cb12: [''],
        doc_op_cb13: [''],
        doc_op_cb14: [''],
        doc_op_cb15: [''],
        doc_op_cb16: ['']
      })
    )
  }

  async copiarRegistros() {
    this.poAlert.confirm({
      literals: { "cancel": "Não", "confirm": "Sim" },
      title: 'Cópia de Registros',
      message: 'Gostaria de copiar os "Requisitos técnicos e especiais" para o próximo produto?',
      confirm: () => this.copyInputs(),
      cancel: () => this.createInputs(),
      close: () => {}
    });
  }

  copyInputs(){

    const proposta = this.newProcessForm.get('proposta') as FormArray;

    proposta.push(
      this.fb.group({
        produto_corrente: [''],
        cod_produto: [''],
        ncm: [''],
        mod_pn_prod: [''],
        apli_prod: [''],
        func_prod: [''],
        moeda_target_price: [''],
        target_price: [''],
        qtde_consumo1: [''],
        periodo_consumo1: [''],
        tipo_producao1: [''],
        program_entrega1: [''],
        prazo_entreg_consumo1: [''],
        qtde_consumo2: [''],
        periodo_consumo2: [''],
        tipo_producao2: [''],
        program_entrega2: [''],
        prazo_entreg_consumo2: [''],
        qtde_consumo3: [''],
        periodo_consumo3: [''],
        tipo_producao3: [''],
        program_entrega3: [''],
        prazo_entreg_consumo3: [''],
        qtde_consumo4: [''],
        periodo_consumo4: [''],
        tipo_producao4: [''],
        program_entrega4: [''],
        prazo_entreg_consumo4: [''],
        simi_prod: [''],
        indic_prod: [''],
        aceit_altern: [''],
        finalidade_prod: [''],
        possui_ppb: [''],
        isencao_impost: [''],
        req_cri_espec: [''],
        sumazi_prod: [''],
        sumazi_prod_qual: [''],
        obs_isencao_impost: [''],
        espec_solda: [this.proposta.value[this.proposta.value.length -1].espec_solda],
        ipca: [this.proposta.value[this.proposta.value.length -1].ipca],
        certif_exter: [this.proposta.value[this.proposta.value.length -1].certif_exter],
        verniz_resi: [this.proposta.value[this.proposta.value.length -1].verniz_resi],
        equi_fw_teste: [this.proposta.value[this.proposta.value.length -1].equi_fw_teste],
        cobra_nre: [this.proposta.value[this.proposta.value.length -1].cobra_nre],
        jigas_cliente: [this.proposta.value[this.proposta.value.length -1].jigas_cliente],
        obs_obj_proposta: [this.proposta.value[this.proposta.value.length -1].obs_obj_proposta],
      })
    )
  }

  createInputs(){
    this.proposta.push(
      this.fb.group({
        produto_corrente: [''],
        cod_produto: [''],
        ncm: [''],
        mod_pn_prod: [''],
        apli_prod: [''],
        func_prod: [''],
        moeda_target_price: [''],
        target_price: [''],
        qtde_consumo1: [''],
        periodo_consumo1: [''],
        tipo_producao1: [''],
        program_entrega1: [''],
        prazo_entreg_consumo1: [''],
        qtde_consumo2: [''],
        periodo_consumo2: [''],
        tipo_producao2: [''],
        program_entrega2: [''],
        prazo_entreg_consumo2: [''],
        qtde_consumo3: [''],
        periodo_consumo3: [''],
        tipo_producao3: [''],
        program_entrega3: [''],
        prazo_entreg_consumo3: [''],
        qtde_consumo4: [''],
        periodo_consumo4: [''],
        tipo_producao4: [''],
        program_entrega4: [''],
        prazo_entreg_consumo4: [''],
        simi_prod: [''],
        indic_prod: [''],
        aceit_altern: [''],
        finalidade_prod: [''],
        possui_ppb: [''],
        isencao_impost: [''],
        req_cri_espec: [''],
        sumazi_prod: [''],
        sumazi_prod_qual: [''],
        obs_isencao_impost: [''],
        espec_solda: [''],
        ipca: [''],
        certif_exter: [''],
        verniz_resi: [''],
        equi_fw_teste: [''],
        cobra_nre: [''],
        jigas_cliente: [''],
        obs_obj_proposta: [''],
      })
    )
  }

  onFileSelected(event :any) {
    debugger
    this.utilsService.convertFile(event.file.rawFile).subscribe(base64 => {

      this.dadosFiles.push({
        base64,
        name:event.file.name
      })
    });
  }

}


