import { Injectable } from '@angular/core';
import { AuthenticatorService } from '../../../services/authenticator.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) { }

  apiUrl: any;

  updateUser(password_user: String,document:String) {
    
    const requestData = {
      url: `${environment.url}/api/public/ecm/dataset/datasets`,
      method: 'POST'
    };
    
  
    // primeiroAcesso
    const body = {
      name: "DTS_UPDATE_USERS",
      constraints: [{
        _field: 'password_user', 
        _initialValue: password_user, 
        _finalValue: password_user, 
        _type: 1
      },{
        _field: 'documentId', 
        _initialValue: document, 
        _finalValue: document, 
        _type: 1
      }]
    };

    const httpOptions = this.authenticator.connect(requestData);
  
    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: response.content.values[0]['STATUS'] == 'ok'
          });
        }
      );
    });
  }
}
