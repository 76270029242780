import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PoTableColumn } from '@po-ui/ng-components';
import { ConsultaDocumentosService } from './consulta-documentos.service'

@Component({
  selector: 'app-consulta-documentos',
  templateUrl: './consulta-documentos.component.html',
  styleUrls: ['./consulta-documentos.component.css']
})
export class ConsultaDocumentosComponent {
  gridItems: Array<any> = []
  gridColumns:Array<PoTableColumn> = [ 
    { label: 'Nº Solicitação', property: 'PROCESS_ID'},
    { label: 'Data de Abertura', property: 'DATA'},
    { label: 'Prazo Aprovação', property: 'DATA_APROVA'},
    { label: 'DOCUMENT_ID', property: 'DOCUMENT_ID',visible:false},
    { 
      label: 'Validação Documentos', 
      property: 'status',
      type:'icon',
      icons: [
        { 
          value: 'onApproval', 
          icon: 'po-icon-eye', 
          tooltip: 'Aprovar Solicitação',  
          action: this.extras.bind(this),
        },{ 
          value: 'disabled', 
          icon: 'po-icon-eye', 
          tooltip: 'Visualizar Solicitação',  
          action: this.extras.bind(this),
        }
    ]
    },
    { 
      label: 'Status',
      property: 'NUM_ATIV' ,
      width: "30%",
      type:"label",
      labels: [
        { value: 77, color: 'color-01', label: 'Validar Declaração de Produto' },
        { value: 98, color: 'color-11', label: 'Aprovar Draft' },
        { value: 66, color: 'color-10', label: 'Solicitação Finalizada' },
        { value: 51, color: 'color-08', label: 'Em Andamento' },
        { value: 0,  color: 'color-12', label: 'Em Aprovação por Comex' }
      ]}
  ]

  constructor(
    private service: ConsultaDocumentosService,
    private route: Router
  ){}

  
  async ngOnInit() {
    const {response}:any = await this.service.getDatas()
    this.gridItems = response
    console.log(response)
  }

  extras(row: any) {
    const decision = (row?.status == 'onApproval' || row?.status == 'toCorrect' )  ? true : false;
      this.route.navigate(
        ["/documents",row?.DOCUMENT_ID  ],
        { 
          queryParams: { 
            edit: decision,
            activity:row?.NUM_ATIV
          } 
        })
    
  }

}
