import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators } from '@angular/forms'
import { UtilsService } from '../../../services/utils.service';
import { PoNotificationService, PoToasterOrientation } from '@po-ui/ng-components';
import {ChangePasswordService} from './change-password.service'
import { ActivatedRoute, Router,  } from '@angular/router';
import { PoPageChangePasswordRequirement } from '@po-ui/ng-templates';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  documentID: any;
  
  constructor(
    public notification: PoNotificationService,
    private utilsService: UtilsService,
    private service : ChangePasswordService,
    private activatedRoute : ActivatedRoute,
    private routherService: Router
  ) { }

  changeForm!: FormGroup
  
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.documentID = params.get('id');
    });

    this.changeForm = new FormGroup({
      password_user: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required])
    });



  }

  changePassword(event: any){
    const password_user = event.newPassword;
    
    this.service.updateUser(password_user,this.documentID)

    
  this.service.updateUser(password_user,this.documentID).then(({ response }: any)=> {
      if(response){
        this.notification.success({ 
          message: `Alteração realizada..`, 
          duration: 3000,
          orientation: PoToasterOrientation.Top
        });

        this.routherService.navigate(['/login'])
      }else{
        this.notification.error({ 
          message: `Erro ao realizar alteração de senha.`, 
          duration: 3000,
          orientation: PoToasterOrientation.Top
        });
      }
    })
  }
  

}
