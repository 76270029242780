import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NaoAutorizadoGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private routherService: Router
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!this.auth.isLoggedIn()) {
        return true;
      } else {
        this.routherService.navigate(['login']);
        return false;
      }
  }
}