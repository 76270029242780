import { Injectable } from '@angular/core';
import { AuthenticatorService } from '../../../services/authenticator.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AprovacaoService {

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) { }

  apiUrl: any;
  tipoProducao:any = {
    '001' : 'Produção Lote Único',
    '002' : 'Produção NPI',
    '003' : 'Produção em Série',
    '004' : 'Protótipo',
  }

  descricaoNre:any = {
    '001': 'DESPESAS INICIAIS DE PCB',
    '002': 'PAINEL COM COMP. SMT BOT',
    '003': 'PAINEL COM COMP. SMT BOT + TOP',
    '004': 'PAINEL COM COMP. SMT BOT + TOP + PTH',
    '005': 'DESPESAS COM NPI  (TERMOPAR)',
    '006': 'STENCIL AÇO INOX',
    '007': 'STENCIL AÇO FINE GRAIN',
    '008': 'STENCIL AÇO FINE GRAIN + NANOCOATING',
    '009': 'STENCIL COMPLETO FG + NANO + STEP',
    '010': 'BASE DE PRINTER (300MMX300MM)',
    '011': 'BASE DE PRINTER (250MMX200MM)',
    '012': 'BASE DE ROUTER PADRÃO',
    '013': 'PLATE DE ROUTER (SEM TOP CATCH)',
    '014': 'PLATE DE ROUTER (COM TOP CATCH)',
    '015': 'PALLET SELETIVO (350X350) ATÉ 20 PISADORES',
    '016': 'PALLET SELETIVO (350X550) ATÉ 20 PISADORES',
    '017': 'PALLET SELETIVO (350X350) ATÉ 50 PISADORES',
    '018': 'PALLET SELETIVO (350X550) ATÉ 50 PISADORES',
    '019': 'PALLET SELETIVO (350X350) ATÉ 100 PISADORES',
    '020': 'PALLET SELETIVO (350X550) ATÉ 100 PISADORES',
    '021': 'TAMPA GIRATÓRIA DEDICADA AO PRODUTO',
    '022': 'PALLET DE SOLDA SMT',
    '023': 'A DEFINIR',
    '024': 'FIXTURE ICT/HP',
    '025': 'FIXTURE MDA/TRI',
    '026': 'JIGA DE GRAVAÇÃO',
    '027': 'TESTE FUNCIONAL',
    '028': 'PLACA GOLDEN PARA VALIDAÇÃO DE TESTES',
    '029': 'GABARITO DE SOLDA S/ TC (150X150MM)',
    '030': 'GABARITO DE SOLDA S/ TC (250X250MM)',
    '031': 'GABARITO DE SOLDA C/ TC (150X150MM)',
    '032': 'GABARITO DE SOLDA C/ TC (250X250MM)',
    '033': 'GABARITO DE SOLDA GIRATÓRIO (150X150MM)',
    '034': 'GABARITO DE SOLDA GIRATÓRIO (1250X250MM)',
    '035': 'PALLET DE COATING MANUAL',
    '036': 'PALLET DE COATING MÁQUINA',
    '037': 'BERÇO PARA APLICAÇÃO DE RESINA',
    '038': 'DEFINIR',
    '039': 'ELABORAÇÃO DE PFMEA E PPAP',
    '040': 'ELABORAÇÃO DE DFM'
}


  async cabecalhoProposta(numProposta: string , revisao:string,atividade:string) {
  
      this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

      const requestData = {
        url: this.apiUrl,
        method: 'POST'
      };
  
      const body = {
        name: "DTS_CABECALHO_PROPOSTA",
        constraints:  [{
          _field: 'NUMPROPOSTA', 
          _initialValue: numProposta, 
          _finalValue: numProposta, 
          _type: 1
        },{
          _field: 'REVISAO', 
          _initialValue: revisao, 
          _finalValue: revisao, 
          _type: 1
        }]
      };

      if(atividade == '1'){
        body.constraints.push({
          _field: 'ATIVIDADE', 
          _initialValue: atividade, 
          _finalValue: atividade, 
          _type: 1
        })
      }
     
      
     
      const httpOptions = this.authenticator.connect(requestData)
      
      return new Promise((resolvePromise) => {
        this.http.post(requestData.url, body, httpOptions).subscribe(
          (response: any) => {
            resolvePromise({
              response: response.content.values,
              totalHits: response.content.values.length
            });
          }
        );
      });
    }

  async listaTecnica(numProposta: string , revisao:string) {
  
      this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

      const requestData = {
        url: this.apiUrl,
        method: 'POST'
      };
  
      const body = {
        name: "DTS_LISTA_TECNICA",
        constraints:  [{
          _field: 'NUMPROPOSTA', 
          _initialValue: numProposta, 
          _finalValue: numProposta, 
          _type: 1
        },{
          _field: 'REVISAO', 
          _initialValue: revisao, 
          _finalValue: revisao, 
          _type: 1
        }]
      };
      
     
      const httpOptions = this.authenticator.connect(requestData)
      
      return new Promise((resolvePromise) => {
        this.http.post(requestData.url, body, httpOptions).subscribe(
          (response: any) => {
            resolvePromise({
              response: response.content.values,
              totalHits: response.content.values.length
            });
          }
        );
      });
    }

    async listaAlternativos(numProposta: string , revisao:string) {
    
        this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
  
        const requestData = {
          url: this.apiUrl,
          method: 'POST'
        };
    
        const body = {
          name: "DTS_LISTA_ALTERNATIVOS",
          constraints:  [{
            _field: 'NUMPROPOSTA', 
            _initialValue: numProposta, 
            _finalValue: numProposta, 
            _type: 1
          },{
            _field: 'REVISAO', 
            _initialValue: revisao, 
            _finalValue: revisao, 
            _type: 1
          }]
        };
        
       
        const httpOptions = this.authenticator.connect(requestData)
        
        return new Promise((resolvePromise) => {
          this.http.post(requestData.url, body, httpOptions).subscribe(
            (response: any) => {
              resolvePromise({
                response: this.preparaAlternativoGrid(response.content.values),
                totalHits: response.content.values.length
              });
            }
          );
        });
      }

      async propostaComercial(numProposta: string , revisao:string) {
      
          this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
    
          const requestData = {
            url: this.apiUrl,
            method: 'POST'
          };
      
          const body = {
            name: "DTS_FRM_MONTAGEM_PROPOSTA_COMERCIAL",
            constraints:  [{
              _field: 'numProposta', 
              _initialValue: numProposta, 
              _finalValue: numProposta, 
              _type: 1
            },{
              _field: 'rfq_revisao', 
              _initialValue: revisao, 
              _finalValue: revisao, 
              _type: 1
            }]
          };
          
         
          const httpOptions = this.authenticator.connect(requestData)
          
          return new Promise((resolvePromise) => {
            this.http.post(requestData.url, body, httpOptions).subscribe(
              (response: any) => {
                resolvePromise({
                  response: response.content.values[1],
                  totalHits: response.content.values.length
                });
              }
            );
          });
        }

  async itensProposta(item: string , cenario:string,proposta: string , revisao:string,idioma:string) {
  
      this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

      const requestData = {
        url: this.apiUrl,
        method: 'POST'
      };
  
      const body = {
        name: "DTS_ITEM_PROPOSTA",
        constraints:  [{
          _field: 'NUMPROPOSTA', 
          _initialValue: proposta, 
          _finalValue: proposta, 
          _type: 1
        },{
          _field: 'REVISAO', 
          _initialValue: revisao, 
          _finalValue: revisao, 
          _type: 1
        },{
          _field: 'ITEM', 
          _initialValue: item, 
          _finalValue: item, 
          _type: 1
        },{
          _field: 'CENARIO', 
          _initialValue: cenario, 
          _finalValue: cenario, 
          _type: 1
        }]
      };
      
     
      const httpOptions = this.authenticator.connect(requestData)
      
      return new Promise((resolvePromise) => {
        this.http.post(requestData.url, body, httpOptions).subscribe(
          (response: any) => {
            resolvePromise({
              response: this.preparaGrid(response.content.values,idioma),
              totalHits: response.content.values.length
            });
          }
        );
      });
    }

    async nreItensProposta(item: string , cenario:string,proposta:string,revisao:string) {
    
        this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
  
        const requestData = {
          url: this.apiUrl,
          method: 'POST'
        };
    
        const body = {
          name: "DTS_NRE_ITEM_PROPOSTA",
          constraints:  [{
            _field: 'NUMPROPOSTA', 
            _initialValue: proposta, 
            _finalValue: proposta, 
            _type: 1
          },{
            _field: 'REVISAO', 
            _initialValue: revisao, 
            _finalValue: revisao, 
            _type: 1
          },{
            _field: 'ITEM', 
            _initialValue: item, 
            _finalValue: item, 
            _type: 1
          },{
            _field: 'CENARIO', 
            _initialValue: cenario, 
            _finalValue: cenario, 
            _type: 1
          }]
        };
        
       
        const httpOptions = this.authenticator.connect(requestData)
        
        return new Promise((resolvePromise) => {
          this.http.post(requestData.url, body, httpOptions).subscribe(
            (response: any) => {
              resolvePromise({
                response: this.preparaGridNres(response.content.values),
                totalHits: response.content.values.length,
                visible:response.content.values.some((item:any) => parseInt(item.NRE_ITEM) > 0)
              });
            }
          );
        });
      }

      async dadosFormulario(proposta: string , revisao:string) {
      
          this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
    
          const requestData = {
            url: this.apiUrl,
            method: 'POST'
          };
      
          const body = {
            name: "DTS_FRM_MONTAGEM_PROPOSTA_COMERCIAL",
            constraints:  [{
              _field: 'numProposta', 
              _initialValue: proposta, 
              _finalValue: proposta, 
              _type: 1
            },{
              _field: 'rfq_revisao', 
              _initialValue: revisao, 
              _finalValue: revisao, 
              _type: 1
            }]
          };
          
         
          const httpOptions = this.authenticator.connect(requestData)
          
          return new Promise((resolvePromise) => {
            this.http.post(requestData.url, body, httpOptions).subscribe(
              (response: any) => {
                resolvePromise({
                  response: response.content.values,
                  totalHits: response.content.values.length
                });
              }
            );
          });
        }

        async aprovaSolicitacao(json: string,documentid:number,email:string,aprovado:string) {
          let aprovOrReprovado = ''
          if(aprovado == 'A'){
            aprovOrReprovado = 'aprovado'
          }else if(aprovado == 'RE'){
            aprovOrReprovado = 'revisao'
          }else{
            aprovOrReprovado = 'reprovado'
          }
          
          this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
    
          const requestData = {
            url: this.apiUrl,
            method: 'POST'
          };
      
          const body = {
            name: "DTS_APROVACAO_SOLIC_SAP",
            constraints:  [{
              _field: 'JSON', 
              _initialValue: JSON.stringify(json), 
              _finalValue: JSON.stringify(json), 
              _type: 1
            }]
          };
          
         
          const httpOptions = this.authenticator.connect(requestData)
          await this.moveRequest(documentid,aprovOrReprovado)

          return new Promise((resolvePromise) => {  
            this.http.post(requestData.url, body, httpOptions).subscribe(
              (response: any) => {
                resolvePromise({
                  response: response.content.values[0],
                  totalHits: response.content.values.length
                });
              }
            );
          });

          
        }

        async moveRequest(documentId:number,aprovOrReprov:string) {
          this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
      
          const requestData = {
            url: this.apiUrl,
            method: 'POST'
          };
      
          
          const body = {
            name: "DTS_SAVE_DATA_IN_THE_FIELD",
            constraints: [ {
              _field: 'documentId', 
              _initialValue: documentId, 
              _finalValue: documentId, 
              _type: 1
            },{
              _field: 'status_aprovacao', 
              _initialValue: aprovOrReprov, 
              _finalValue: aprovOrReprov, 
              _type: 1
            }
          ]
          };
      
          const httpOptions = this.authenticator.connect(requestData)
          
          return new Promise((resolvePromise) => {
            this.http.post(requestData.url, body, httpOptions).subscribe(
              (response: any) => {
                resolvePromise({
                  response: response.content.values , 
                  totalHits: response.content.values.length 
                });
              }
            );
          });
        }
        
        private preparaAlternativoGrid(valores:[]){

          valores.map((item:any) => {
            item['STATUS'] = 'onApproval'
          })
          return  valores
        }

        private preparaGrid(valores:[],idioma:string){

          const values =  valores.map((item:any, index) => {
            let novoItem = {
              ITEM: item.ITEM,
              STEUC: item.STEUC,
              MATNRRFQ: item.MATNRRFQ,
              QUANT: item.QUANT,
              ORVALUE: item.PRTTL_BRL,
              PRUNIT: idioma == 'pt' ? item.PRUNIT_BRL : item.PRUNIT_USD,
              IGPMP: idioma == 'pt' ? `${item.IGPMP_BRL}%` : `${item.IGPMP_USD}%`,
              IGPMVLR: idioma == 'pt' ? item.IGPMVLR_BRL : item.IGPMVLR_USD,
              CAMBP: idioma == 'pt' ? `${item.CAMBP_BRL}%` : `${item.CAMBP_USD}%`,
              CAMBVLR: idioma == 'pt' ? item.CAMBVLR_BRL : item.CAMBVLR_USD,
              PRTTL: idioma == 'pt' ? item.PRTTL_BRL : item.PRTTL_USD,
              ICMSP: idioma == 'pt' ? `${item.ICMSP_BRL}%` : `${item.ICMSP_USD}%`,
              IPIP: idioma == 'pt' ? `${item.IPIP_BRL}%` : `${item.IPIP_USD}%`,
              PRZENT: item.PRZENT,
              PRNET: idioma == 'pt' ? item.PRNET_BRL : item.PRNET_USD,
              TPPRD: this.tipoProducao[item.TPPRD],
              OBSER: item.OBSER,
              STATUS: 'onApproval'
            };
      
      
            return novoItem
          })
          return  values
        }

        private preparaGridNres(valores:[]){
          
          const values =  valores.map((item:any) => {
            let novoItem = {}
            if(item.NRE_ITEM != "000"){
              novoItem = {
                ITEM: item.ITEM,
                NRE_ITEM: this.descricaoNre[item.NRE_ITEM],
                QUANT: item.QUANT,
                PRUNI: item.PRUNI,
                PRTTL: item.PRTTL,
                OBS: item.OBS
              };
            }

            return novoItem
            
          })
          return  values
        }
}
