<form    [formGroup]="registerForm"  #formDir="ngForm" class="form-horizontal" name="form">
    <po-widget class="container po-xl-12 po-lg-12 po-md-12 po-sm-12">

        <div class="imgcontainer">
            <img 
                src="https://hi-mix.com.br/wp-content/uploads/2021/03/Logo-Blue.svg" 
                alt="logo Hi-Mix"  
                class="logo"
            >
        </div>
    
        <div>
            <po-input
                p-label="Nome"
                p-placeholder="Digite o nome do usuário"
                formControlName="nome"
            ></po-input>
            <po-input
                p-label="Sobrenome"
                p-placeholder="Sobrenome"
                formControlName="sobrenome"
            ></po-input>
            <po-email
                p-label="E-mail"
                p-placeholder="E-mail"
                formControlName="email_user"
                (p-change)="validUser($event)"
            ></po-email>
            

            <po-input
                *ngIf="isCNPJ"
                p-label="CNPJ"
                p-placeholder="CNPJ"
                formControlName="cnpj"
                (p-change)="findCnpj()"
                p-mask="99.999.999/9999-99"
                
            ></po-input>
            <po-input
                *ngIf="isCNPJ"
                p-label="Nome Empresa"
                p-placeholder="Nome Empresa"
                formControlName="nomeEmpresa"
                p-readonly="true"
            ></po-input>
            
            <div style="display:flex;align-items: center;justify-content: space-between;">
                <po-button 
                    p-icon="po-icon po-icon-arrow-left"
                    p-type="primary"   
                    class="large-button" 
                    p-label="Voltar"
                    routerLink="/login"
                >
                </po-button>
                <po-button 
                    p-type="primary"   
                    class="large-button" 
                    p-label="Cadastrar"
                    (p-click)="register()"
                    [p-disabled]="!registered"
                >
                </po-button>
            </div>
        </div>
        
    </po-widget>


