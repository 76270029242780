<form [formGroup]="negociacaoForm"  #formDir="ngForm"  name="form">
    <div class="po-row">
        <div class="form-group" style="margin-right: -13;top: 10;right:20">
            <span class="fs-display-block fs-md-space">
                <i 
                    class="fluigicon fluigicon-trash fluigicon-md" 
                    onclick="fnCustomDelete(this)" 
                    style="cursor:pointer"
                    ></i>
            </span>
        </div>
        <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
            <po-input 
                name="nome_fornec"
                formControlName="nome_fornec"  
                onblur="validarPN()" 
                [p-maxlength]="40"
                p-label="Nome Fornecedor/Distribuidor"
                [p-disabled]="isViewInput"
            ></po-input>
        </div>
        <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
            <label for="pn_negciado"></label>
            <po-input 
                name="pn_negciado" 
                formControlName="pn_negciado"  
                onblur="validarPN()" 
                [p-maxlength]="40"
                p-label="Part Number"
                [p-disabled]="isViewInput"
            ></po-input>
        </div>
        <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
            <po-input 
                name="pn_fabricante" 
                formControlName="pn_fabricante"  
                [p-maxlength]="15"
                p-label="Fabricante"
                [p-disabled]="isViewInput"
            ></po-input>
        </div>

        <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
            <po-input 
                name="descr_pn_negociado" 
                formControlName="descr_pn_negociado"  
                [p-maxlength]="40"
                p-label="Descrição do Componente"
                [p-disabled]="isViewInput"
            ></po-input>
        </div>
        
        <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
            <po-multiselect 
                name="moeda_pn_negociado" 
                formControlName="moeda_pn_negociado" 
                p-label="Moeda do item negociado"
                [p-options]="moeda"
                [p-disabled]="isViewInput"
            >
            </po-multiselect>
        </div>
        <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
            <po-input 
                name="custo_pn_negociado" 
                formControlName="custo_pn_negociado"  
                [p-maxlength]="11"
                p-label="Custo Item"
                (ngModelChange)="formatarValor($event)"
                [p-disabled]="isViewInput"
            ></po-input>
        </div>
    </div>
</form>