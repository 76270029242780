import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl } from '@angular/forms'
import { SharedService } from '../../../shared/shared.service'
import {  inputsNegociacao } from './negociacao.interface';

@Component({
  selector: 'app-negociacao',
  templateUrl: './negociacao.component.html',
  styleUrls: ['./negociacao.component.css']
})
export class NegociacaoComponent implements OnInit {

  negociacaoForm!: FormGroup
  inNegociacao = new inputsNegociacao();
  itensNegociacao: any[] = [];
  itensCamposNegociacao!: any;
  isViewInput:any = false;

  constructor(
    private sharedService : SharedService,
  ) { }



  

  ngOnInit(): void {
    this.negociacaoForm = new FormGroup({
      item_negoc_prev_fornc: new FormControl(''),
      nome_fornec: new FormControl(''),
      pn_negciado: new FormControl(''),
      pn_fabricante: new FormControl(''),
      descr_pn_negociado: new FormControl(''),
      moeda_pn_negociado: new FormControl(''),
      custo_pn_negociado: new FormControl(''),
    })

    this.sharedService.dadosNegociacao$.subscribe((value: any)=> {
      this.itensNegociacao.push(value[0])
      this.itensCamposNegociacao = value[1]
    })


    this.itensNegociacao[0].forEach((val: any) => {
      this.itensCamposNegociacao.forEach((coluna: any) => {
        let value = val[coluna]
        if (value) {
          this.negociacaoForm.get(coluna)?.setValue(value)
        }
      });
    });

    this.sharedService.updateDadosFormNegociacao(this.negociacaoForm)
    this.isViewInput = this.sharedService.getIsView
    
    // this.inNegociacao.item_negoc_prev_fornc = this.sharedService.getLinesNegociacao
  }

  moeda = [
    {value:"BRL",label:"BRL (R$)"},
    {value:"USD",label:"USD ($)"}
  ]

  formatarValor(valor: string) {
    let valorNumerico = parseFloat(valor.replace(/\D/g, '')) / 100; 
    // this.negociacaoForm.custo_pn_negociado = valorNumerico.toLocaleString('pt-BR', { minimumFractionDigits: 2 }); 
    
  }

}
