import { Component } from '@angular/core';
import { PoTableColumn, PoTagType } from '@po-ui/ng-components';
import { Router } from '@angular/router';
import { ListDocumentsService } from './list-documents.service'

@Component({
  selector: 'app-list-documents',
  templateUrl: './list-documents.component.html',
  styleUrls: ['./list-documents.component.css']
})
export class ListDocumentsComponent {
  columns: Array<PoTableColumn> = [
    { property: 'PROCESS_ID',label:'N° Solicitação' ,type: 'number',width:'15%',},
    { property: 'DATA_ABERTURA',label:'Data de Abertura' ,type:'date'},
  ];
  columns2: Array<PoTableColumn> = [
    { 
      label: 'Visualizar Anexo', 
      property: 'iS_URL',
      type: 'icon', 
      icons: [
        { 
          value: 'true', 
          icon: 'po-icon-eye', 
          tooltip: 'Visualizar Anexo',  
          action: this.opeAnexos.bind(this),
        }
      ]
    },
    { property: 'NOME_ARQUIVO',label:'Nome'},
  ];
  items: Array<any> = [];

  
  constructor(
    private service: ListDocumentsService,
    private route: Router
  ){}

  async ngOnInit() {
    const {response}:any = await this.service.getDatas()
    this.items = response
  }

  opeAnexos(row: any) {
    window.open(row?.URL, '_blank');
      this.route.navigate
  }
}
