import { Component, ViewChild } from '@angular/core';
import { AuthService } from './services/auth.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  constructor(
    public authService: AuthService,
    private route: Router
    ) { }
  
  title = 'Portal do Cliente - Hi-Mix';

  logoutHeader(){
    this.authService.logout()
    this.route.navigate(['/login'])
  }

  menus = [

    { 
      label: 'Homepage', 
      link: '/', 
      icon: 'po-icon po-icon-home', 
      shortLabel: 'Homepage' 
    },{
      label: 'Propostas Comerciais', icon: 'po-icon po-icon-edit', shortLabel: 'Prop. Com', subItems: [
        { label: 'Iniciar Solicitação', link: '/novasolicitacao', shortLabel: 'Ini. Sol.' },
        { label: 'Consultar Solicitações', link: '/consultar', shortLabel: 'Cons. Sol.' }
      ]
    },{
      label: 'Documentos', icon: 'po-icon po-icon-document-filled', shortLabel: 'Prop. Com', subItems: [
        { label: 'Aprovar Documentos', link: '/consultardocumentos', shortLabel: 'Ini. Sol.' },
        { label: 'Verificar Documentos', link: '/listdocumentos', shortLabel: 'Cons. Sol.' }
      ] 
    },{
      label: 'Comex', icon: 'po-icon po-icon-handshake', shortLabel: 'Prop. Com', subItems: [
        { label: 'Certificado de Origem', link: '/consultardocumentos', shortLabel: 'Ini. Sol.' },
        { label: 'Invoice e Packing List', link: '/consultainvoice', shortLabel: 'Cons. Sol.' }
      ] 
    }
    
  ];
}
