<po-toolbar 
p-actions-icon="po-icon-cart"
[p-profile]="profile"
[p-profile-actions]="profileActions"
[p-title]="title"
></po-toolbar>
<po-divider></po-divider>
  <!-- <po-navbar  
    p-logo="https://hi-mix.com.br/wp-content/uploads/2021/03/Logo-Blue.svg"
    p-logo-alt="Logo-Hi-Mix"
    [p-icon-actions]="itens"
    [p-shadow]="true" 
    [p-literals]="customLiterals"
    >
  
  </po-navbar> -->




